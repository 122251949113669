//@ts-nocheck
import React, { useEffect, useContext, useState } from "react";
import Loader from "../../Components/Loader";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import NavContext from "../../NavContext";
import keyringController from "../../utils/init";
import { AuthController } from "@tria-sdk/core";
import Nav from "../../Components/SignUp/Nav";
import Footer from "../../Components/Footer";
import { socket } from "../../../src/utils/init";

import mixpanel from "mixpanel-browser";

export default function VerificationPage() {
  const walletType = {
    embedded: true,
  };

  const { storedPassword, dappName } = useContext(NavContext);

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const [available, setAvailable] = useState();
  const [loader, setLoader] = useState(false);

  const [email, setEmail] = useState("");
  const [hash, setHash] = useState("");
  const [password, setPassword] = useState("");

  const { setToken } = useContext(NavContext);
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const call = async () => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    const token = searchParams.get("token");
    socket.emit("loginV2", {
      userId: email,
    });
    socket.emit("messageV2", {
      userId: email,
      message: {
        token: token,
      },
    });
    setOpen(true);
  };

  const call2 = async () => {
    const fromClientId = localStorage.getItem("clientId") || "";
    if (name.length > 3) {
      setLoader(true);
      const searchParams = new URLSearchParams(location.search);
      const origin = searchParams.get("origin");
      const res = await keyringController.generateAccountByOTPOrLINK({
        triaName: name + "@tria",
        input: email,
        hash: hash,
        password: password,
        type: "link",
        origin: origin,
        fromClientId: fromClientId,
      });
      console.log("res", res);
      if (res.success === true) {
        const created_wallet_store = localStorage.getItem("tria.wallet.store");
        socket.emit("messageV2", {
          userId: email,
          message: JSON.parse(created_wallet_store),
        });
        setTimeout(() => {
          window.close();
        }, 2000);
      }
      //window.open(`${origin}?verified=true`, "_self")
    }
  };

  useEffect(() => {
    //Mixpanel track page view - Verification Completion
    mixpanel.track_pageview({ page: "Verify Complete Page" });

    call();
  }, []);

  return (
    <>
      <div
        style={{ zoom: "0.7" }}
        className="fixed lg:right-[40%] shadow-lg text-white lg:top-[15%] right-0 bottom-0"
      >
        <div className="w-[448px] h-[840px] p-4 bg-white dark:bg-fontLightColor rounded-2xl flex-col justify-between items-center inline-flex">
          <div className="absolute top-0 left-0 ">
            {" "}
            <img className="" src="/icons/back.svg" />
          </div>
          <div className="flex-col gap-2 flex">
            <div className=" flex-col justify-center items-center gap-2 inline-flex mt-20">
              <img className="" src="/icons/done.svg" />
            </div>
            <div className=" py-3 flex-col justify-center items-start gap-4 inline-flex">
              <div className="self-stretch justify-center items-center gap-2 inline-flex">
                <div className="text-center text-stone-950 text-opacity-80 text-2xl font-medium font-['Montserrat'] leading-snug dark:text-text">
                  Connected successfully!
                </div>
              </div>
            </div>
            <div className="self-stretch  justify-center text-xs items-center gap-2 flex font-medium font-['Montserrat'] leading-snug dark:text-text ">
              <p className="px-1 text-center text-lg text-black dark:text-white text-opacity-60">
                You can close this tab and continue journey on the sign up page!
              </p>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
