//@ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../Components/Navbar";
import { FeeController, WalletController } from "@tria-sdk/web";
import { Send } from "@tria-sdk/web";
import { useParams } from "react-router-dom";
import { useTriaUser } from "../../contexts/tria-user-provider";
import Loader from "../../Components/Send/Loader";
import { shortenWalletAddress } from "../../utils";
import { AssetDetails, fee, AvatarItem, sendParam, postMessageResponse } from "../../types";
import { walletType, baseUrl, walletUrl } from "../../utils/constants";
import Loader1 from "../../Components/Loader";
import DappDetails from "../../Components/DappDetails";
import NetworkFee from "../../Components/NetworkFee";
import FooterButtons from "../../Components/FooterButtons";
import UsersCard from "../../Components/UsersCard";
import ErrorMessage from "../../Components/Popup/ErrorMessage";

const initialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)",
};

const recieverInitialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)",
};

export default function SendAsset(props: any) {
  const { getAssetDetails, getUserByAddress, getAvatarsByTriaNames } =
    useTriaUser();

  const encodedParams = btoa(
    JSON.stringify({
      fromTriaName: "ravi@tria",
      amount: 0.00001,
      recepientAddress: "admin@tria",
      chainName: "MANTA",
      tokenAddress: "",
      dappDomain: "https://facebook.com",
    })
  );
// console.log("encodedParams",encodedParams);
  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState<sendParam>();
  const [tokenDetails, setTokenDetails] = useState<AssetDetails>();
  const [gasFees, setGasFees] = useState<fee>();
  const [recieverTriaName, setRecieverTriaName] = useState();
  const [amountInUSD, setAmountInUSD] = useState<number>();
  const [totalAmountInUSD, setTotalAmountInUSD] = useState<number>();
  const [totalAmountIncrypto, setTotalAmountIncrypto] = useState<number>();
  const [feeLoading, setFeeLoading] = useState<boolean>(true);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [senderAvatar, setSenderAvatar] = useState<AvatarItem>(initialAvatar);
  const [recipientAvatar, setRecipientAvatar] = useState<AvatarItem>(
    recieverInitialAvatar
  );
  const [approveButtonDisable, setApproveButtonDisable] =
    useState<boolean>(true);
  const [transactionSuccessful, setTransactionSuccessful] =
    useState<boolean>(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [triaName, setTriaName] = useState<string>("");
  const [counter, setCounter] = useState(30);
  const param = useParams();

  const sendMessageToParent = (data: postMessageResponse) => {
    // Post a message to the parent window
    console.log("calling from send");
    window.parent.postMessage(
      { type: "closeIframe", callFrom: "send", data: data },
      "*"
    );
  };
//success, message
  const handleRejectClick = () => {
    if (params?.fromWallet) {
      window.open(`${walletUrl}/home/sendCrypto/cryptoSendPage`, "_self");
    } else {
      sendMessageToParent({success:false,message:"User rejected the request."});
    }
  };

  const sendToken = async () => {
    try {
      setApproveLoading(true);
      console.log("sending token..!!");
      const wallet = new WalletController({
        baseUrl,
        walletType,
      });
      const payload: Send = {
        fromTriaName: triaName,
        recipientTriaName: params?.recepientAddress,
        amount: params?.amount,
        tokenAddress: params?.tokenAddress,
      };
      await wallet.init();
      const txn = await wallet.send(payload, params?.chainName);
      console.log("txawait---------------->", txn);
      console.time("myTimer");
      
      const { wait, ...txnDataWithoutWait } = txn.data || {};
      const modifiedTxnResponse = {
        ...txn,
        data: txnDataWithoutWait,
    };
    console.log("modifiedTxnResponse",modifiedTxnResponse);
      if(modifiedTxnResponse?.success){
      setTransactionSuccessful(true);
      if (params?.fromWallet) {
      setTimeout(() => {
      const walletParams = encodeURIComponent(JSON.stringify({ txnId: modifiedTxnResponse?.data?.txnId, viewInExplorer: modifiedTxnResponse?.data?.viewInExplorer, assetType: "TOKEN", params, tokenDetails }));
      console.log("redirecting to---->", `walletUrl/activities/${walletParams}`);
      // window.location.href = `${walletUrl}/activities/${walletParams}`;
      window.open(`${walletUrl}/activities/${walletParams}`, "_self");
      }, 2000);
      }
      else {
      setTimeout(() => {
      sendMessageToParent(modifiedTxnResponse);
      }, 2000);
      }
    }
    else{
      setError(modifiedTxnResponse?.message ||"");
      setApproveLoading(false);
    }
    } catch (err) {
      console.log(err);
      setApproveLoading(false);
      setError(err?.message);
    }
  };

  const getTriaName = async (address: any, chainName: any) => {
    try {
      const triaName = await getUserByAddress(address, chainName);
      return triaName;
    } catch (err) {
      console.log("error", err);
      return null;
    }
  };

  const getSendFee = async (feeCallData) => {
    setApproveButtonDisable(true);
    setError("");
    try {
      const fee = new FeeController({
        baseUrl,
        walletType,
      });

      const payload = {
        fromTriaName: triaName,
        recipientTriaName: feeCallData?.recepientAddress,
        amount: feeCallData?.amount,
        tokenAddress: feeCallData?.tokenAddress,
      };
      const chainNames = feeCallData?.chainName;
      console.log("chain------------------>", payload, chainNames);
      console.time("calculate gas fee");
      const res = await fee.getSendFee(chainNames, payload);
      console.timeEnd("calculate gas fee");

      setCounter(30);
      if (res.success === true) {
        setApproveButtonDisable(false);
        setGasFees(res.fee);
        setTotalAmountInUSD(
          parseFloat(res.fee?.usd || "0") + (amountInUSD || 0)
        );
        setTotalAmountIncrypto(
          parseFloat(res?.fee?.eth || "0") + (params?.amount || 0)
        );
        setFeeLoading(false);
      } else {
        setError(res?.message || "");
      }

      console.log({ res });
    } catch (error: any) {
      setError(error?.message || "");
      console.error(error);
    }
  };

  const setStateParams = async () => {
    console.log("setting state params")
    try {
      //@ts-ignore
      const encodedParams = param?.param;
      if (encodedParams) {
        // Decode the string
        const jsonString = atob(encodedParams);
        const jsonData = JSON.parse(jsonString);
        const triaName = JSON.parse(
          localStorage.getItem("tria.wallet.store") || "{}"
        )?.triaName;
        setTriaName(triaName);
        setParams(jsonData);
        getAsset(jsonData, triaName);

        if (triaName) {
          const resp = await getAvatarsByTriaNames([triaName]);
          if (Object.keys(resp).length > 0) {
            const avatar_details = resp[triaName]?.[0];
            setSenderAvatar({ avatar: avatar_details?.avatar, background: avatar_details?.background });
          }
        }
        let recieverTriaName = jsonData?.recepientAddress;
        if (!recieverTriaName?.includes("@")) {
          const reciever = await getTriaName(
            jsonData?.recepientAddress,
            jsonData?.chainName
          );
          if (reciever) {
            recieverTriaName = reciever;
          }
        }
        if (recieverTriaName?.includes("@")) {
          setRecieverTriaName(recieverTriaName);
          const resp = await getAvatarsByTriaNames([recieverTriaName]);
          if (Object.keys(resp).length > 0) {
            const recipient_avatar_details = resp[recieverTriaName]?.[0];
            setRecipientAvatar({ avatar: recipient_avatar_details?.avatar, background: recipient_avatar_details?.background });
          }
        } else {
          const recieverAddressShort =
            shortenWalletAddress(jsonData?.recepientAddress) || "";
          setRecieverTriaName(recieverAddressShort);
        }

        //@ts-ignore

        console.log("jsonData", jsonData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAsset = async (asset: any, triaName: sting) => {
    try {
      console.log("start------------------------->");
      const response = await getAssetDetails(
        asset?.chainName,
        asset?.tokenAddress,
        triaName
      );
      setLoading(false);
      setTokenDetails(response);
      if (asset?.amount) {
        const total = asset?.amount * response.quoteRate;
        console.log("total--------------->", total);
        setAmountInUSD(total);
      }

      console.log("asset----------------------->", response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (gasFees && amountInUSD) {
      setTotalAmountInUSD(
        parseFloat(gasFees?.usd || "0") + (amountInUSD || 0)
      );
    }
  }, [gasFees, amountInUSD]);

  const fetchSendFee = async () => {
    try {
      await getSendFee(params);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (params) {
      setFeeLoading(true);
      fetchSendFee();
    }
  }, [params]);

  useEffect(() => {
    if (counter === 0 && !feeLoading) {
      setFeeLoading(true);
      fetchSendFee();
    }
  }, [counter]);

  useEffect(() => {
    if (params && !!gasFees?.eth && !approveLoading) {

      const intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter > 0) {
            return prevCounter - 1;
          } else {
            return 0;
          }
        });
      }, 1000);
      setTimerStarted(true);
      return () => clearInterval(intervalId);
    }
  }, [counter, params, gasFees,approveLoading]);

  useEffect(() => {
    setStateParams();
  }, []);

  return (
    <div className="w-full h-screen p-3 flex-col bg-white dark:bg-fontLightColor rounded-2xl justify-between items-center inline-flex">
      {/* {loading ? <div className="w-[448px] h-[840px] p-4 flex-col justify-center items-center inline-flex"><Loader1 /></div> : <> */}
      {approveLoading ? (
        <div>
          <div>
            <Loader heading={"Sending..."} dappLogo={params?.dappLogo} transactionSuccessful={transactionSuccessful} />
          </div>
        </div>) : (
        <div className=" px-0 justify-center items-center w-full h-screen">
          <div className="absolute top-0 left-0 ">
            {' '}
            <img className="w-3/4" src="/icons/back.svg" />
          </div>
          {/* <div className="w-full justify-end items-start inline-flex">
            <div className="p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-end gap-2 inline-flex" />
          </div> */}
          <div className="self-stretch flex-col justify-center items-center flex">
            <div className="self-stretch pt-0 border-opacity-10 justify-center items-center gap-0 inline-flex">
              <div className="">
                <Navbar loading={loading} senderAvatar={senderAvatar} chainName={params?.chainName || ""} tokenDetails={tokenDetails} triaName={triaName} />
              </div>{" "}
            </div>
            <DappDetails params={params} />
            <div className="self-stretch justify-center  items-center gap-2 inline-flex">
              <div className="text-center text-stone-950 text-opacity-90 text-sm font-semibold font-montserrat leading-normal dark:text-text">
                Send Transaction
              </div>
            </div>
          </div>
          <div className="py-2 flex-col justify-center items-center gap-2 flex w-full ">
            <UsersCard loading={loading} amountInUSD={amountInUSD} params={params} tokenDetails={tokenDetails} senderAvatar={senderAvatar} recipientAvatar={recipientAvatar} recieverTriaName={recieverTriaName} />
            <NetworkFee counter={counter} feeLoading={feeLoading} gasFees={gasFees} tokenDetails={tokenDetails} totalAmountInUSD={totalAmountInUSD} totalAmountIncrypto={totalAmountIncrypto} chainName={params?.chainName || ""}/>
          </div>
          <div className="-mt-10">
            {error && <ErrorMessage error={error} />}
          </div>
          <FooterButtons handleReject={handleRejectClick} approveButtonDisable={approveButtonDisable} callFunction={sendToken} />
        </div>
      )}
      {/* </>} */}
    </div>
  );
}
