import axios from "axios"
import { baseUrl } from "./constants"
import keyringController from "./init"
import { eventTypes } from "@tria-sdk/connect"

interface DappDetails {
  dappLogo: string
  dappDomain: string
  dappName: string
}

interface details {
  dappLogo: string
  dappDomain: string
  dappName: string
  triaName: any
}

const checkDidAvailability = async (name) => {
  try {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const { data } = await axios.post(`${baseUrl}/api/v1/did/check`, {
      did: name?.toLowerCase() + "@tria",
    })
    console.log("did --->", data?.response?.availabilityStatus)
    return data?.response?.availabilityStatus
  } catch (err) {
    console.log(err)
  }
}

const getDidRecommendations = async (name) => {
  try {
    const baseUrl = process.env.REACT_APP_BASE_URL
    const { data } = await axios.get(
      `${baseUrl}/api/v2/get-name-recommendation?name=${name?.toLowerCase()}`
    )
    return data?.data[0]
  } catch (err) {
    console.log(err)
  }
}

const getDataFromLocalStorage = async () => {
  try {
    const dappData: DappDetails = JSON.parse(
      localStorage.getItem("dapp.details") || "{}"
    ) || { dappLogo: "", dappDomain: "", dappName: "" }
    const triaName = JSON.parse(
      localStorage.getItem("tria.wallet.store") || "{}"
    )?.triaName
    // const localDetails = { triaName };

    const localDetails: details = {
      dappLogo: dappData.dappLogo,
      dappDomain: dappData.dappDomain,
      dappName: dappData?.dappName,
      triaName,
    }

    return localDetails
  } catch (error) {
    // Handle the error, log it, or return a default value
    console.error("Error retrieving local details:", error)
    return { dappLogo: "", dappDomain: "", dappName: "", triaName: "" } // Default values or handle it based on your requirements
  }
}

function shortenWalletAddress(
  walletAddress: any,
  prefixLength = 6,
  suffixLength = 4
) {
  // if (typeof walletAddress !== 'string' || walletAddress.length < 42) {
  //     console.error('Invalid wallet address');
  //     return null;
  // }

  const prefix = walletAddress?.slice(0, prefixLength)
  const suffix = walletAddress?.slice(-suffixLength)

  return `${prefix}...${suffix}`
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}
// const verifyAndLogUserIn = async (redirectUrlData: string) => {
//     const searchParams = new URLSearchParams(redirectUrlData)
//     const code = searchParams.get("code")
//     const scope = searchParams.get("scope")
//     const state = searchParams.get("state")
//        let isFirefox = false
//        var sBrowser,
//          sUsrAg = window.navigator.userAgent
//        if (sUsrAg.indexOf("Firefox") > -1) {
//          sBrowser = "Mozilla Firefox"
//          console.log("Browser: Firefox ")
//          isFirefox = true
//        }
//        let fromClientId = ""
//        let dappOrigin = ""
//        if (param.param === "twitter") {
//          //@ts-ignore
//          localStorage.setItem("origin", JSON.parse(atob(state)).origin)
//          //@ts-ignore
//          fromClientId = JSON.parse(atob(state))?.fromClientId
//          //@ts-ignore
//          dappOrigin = JSON.parse(atob(state))?.dappOrigin
//        } else {
//          //@ts-ignore
//          localStorage.setItem("origin", JSON.parse(state)?.origin)
//          //@ts-ignore
//          fromClientId = JSON.parse(state)?.fromClientId
//          //@ts-ignore
//          dappOrigin = JSON.parse(state)?.dappOrigin
//        }
//        if (code && scope && param.param === "google") {
//          const {
//            data: {
//              userId,
//              isAccountExist,
//              password,
//              isPasswordRequired,
//              accessToken,
//              username,
//            },
//          } = await axios.get(
//            `${baseUrl}/api/v1/auth/google/callback?code=${code}&scope=${scope}&fromClientId=${fromClientId}`
//          )
//          console.log("res", userId, isAccountExist, password)
//          if (isAccountExist === true) {
//            //@ts-ignore
//            console.log("origin", JSON.parse(state)?.origin)
//            console.log("password", password)
//            console.log("userId", userId)
//            console.log("accessToken", accessToken)
//            localStorage.setItem("accessToken", accessToken)
//            keyringController.postMessage(
//              {
//                type: eventTypes.passMessage,
//                message: {
//                  accountExists: isAccountExist,
//                  userId: userId,
//                  token: accessToken,
//                  social: "google",
//                  username: username,
//                  init: localStorage.getItem("init"),
//                  tempVault: localStorage.getItem("tempVault"),
//                },
//              },
//              true
//            )
//            localStorage.setItem("accessToken", accessToken)
//            //@ts-ignore
//            const vault = await keyringController.getVault({
//              password: password,
//              userId: userId,
//              socialName: "google",
//              //@ts-ignore
//              origin: JSON.parse(state)?.origin,
//              accessToken: accessToken,
//            })
//            console.log("vault", vault)
//            const publicVault = vault?.publicVault
//            if (vault?.exists === true) {
//              window.close()
//              keyringController.postMessage({
//                type: "Log in",
//                success: true,
//                data: publicVault,
//              })
//            }
//          } else {
//            //if Google account does not exist

//            console.log("log isFirefox-->", isFirefox)
//            if (isFirefox === false) {
//              keyringController.postMessage({
//                type: eventTypes.passMessage,
//                message: {
//                  accountExists: isAccountExist,
//                  userId: userId,
//                  token: accessToken,
//                  username: username,
//                  social: "google",
//                },
//              })
//            }

//            localStorage.setItem("accessToken", accessToken)
//            // window.close()
//            if (
//              localStorage.getItem("socketId") !== null ||
//              isFirefox === true
//            ) {
//              navigate(
//                `/unitySignUp/?userId=${userId}&accessToken=${accessToken}&username=${username}&platform=google&fromClientId=${fromClientId}&origin=${dappOrigin}`
//              )
//            } else {
//              window.close()
//            }
//            // navigate(`/signUpUserName/google/${userId}`)
//          }
//          // setActiveSocialMedia('google');
//          // setId(userId);
//          // setPassword(password);
//          // setIsPasswordRequired(isPasswordRequired);
//          // setIsExist(isAccountExist);
//          // setFlag(false);
//          // navigate('/');
//          //@ts-ignore
//        } else if (code && param.param === "instagram") {
//          const { data } = await axios.get(
//            `${baseUrl}/api/v1/auth/instagram/callback?code=${code}&fromClientId=${fromClientId}`
//          )
//          if (data.isAccountExist === true) {
//            console.log("account exists")
//            console.log("password", data.password)
//            console.log("userId", data.userId)

//            //@ts-ignore
//            await keyringController.getVault({
//              password: data.password,
//              userId: data.userId,
//              socialName: "instagram",
//              //@ts-ignore
//              origin: JSON.parse(state)?.origin,
//              accessToken: data?.accessToken,
//            })
//            setTimeout(() => {
//              window.close()
//            }, 2000)
//          } else {
//            navigate(`/signUpUserName/instagram/${data.userId}`)
//          }
//          // setId(id);
//          // setActiveSocialMedia('instagram');
//          // setPassword(password);
//          // setIsPasswordRequired(isPasswordRequired);
//          // setIsExist(isAccountExist);
//          // setFlag(false);
//          // navigate('/');
//          //@ts-ignore
//        } else if (code && param.param === "discord") {
//          const { data } = await axios.get(
//            `${baseUrl}/api/v1/auth/discord/callback?code=${code}&fromClientId=${fromClientId}`
//          )
//          // console.log(data);
//          if (data.isAccountExist === true) {
//            keyringController.postMessage(
//              {
//                type: eventTypes.passMessage,
//                message: {
//                  accountExists: data.isAccountExist,
//                  userId: data.userId,
//                  token: data.accessToken,
//                },
//              },
//              true
//            )
//            console.log("data", data)
//            console.log("account exists")
//            console.log("password", data.password)
//            console.log("userId", data.userId)
//            console.log("access_token", data.accessToken)
//            localStorage.setItem("accessToken", data.accessToken)
//            //@ts-ignore
//            const vault = await keyringController.getVault({
//              password: data.password,
//              userId: data.userId,
//              socialName: "discord",
//              //@ts-ignore
//              origin: JSON.parse(state)?.origin,
//              accessToken: data.accessToken,
//            })
//            const publicVault = vault?.publicVault
//            if (vault?.exists === true) {
//              window.close()
//              keyringController.postMessage({
//                type: "Log in",
//                success: true,
//                data: publicVault,
//              })
//            }
//            // setTimeout(() => {
//            //   window.close()
//            // }, 2000)
//          } else {
//            localStorage.setItem("accessToken", data.accessToken)
//            //if Discord account does not exist

//            if (isFirefox === false) {
//              keyringController.postMessage({
//                type: eventTypes.passMessage,
//                message: {
//                  accountExists: data.isAccountExist,
//                  userId: data.userId,
//                  token: data.accessToken,
//                  username: data.username,
//                },
//              })
//            }
//            localStorage.setItem("accessToken", data.accessToken)
//            if (
//              localStorage.getItem("socketId") !== null ||
//              isFirefox === true
//            ) {
//              navigate(
//                `/unitySignUp/?userId=${data.userId}&accessToken=${data.accessToken}&username=${data.username}&platform=discord&fromClientId=${fromClientId}&origin=${dappOrigin}`
//              )
//            } else {
//              window.close()
//            }
//          }
//          // setFlag(false);
//        } else if (param.param === "twitter") {
//          const oauth_token = searchParams.get("oauth_token")
//          const oauth_verifier = searchParams.get("oauth_verifier")
//          const { data } = await axios.get(
//            `${baseUrl}/api/v1/auth/twitter/callback?state=${state}&oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}&fromClientId=${fromClientId}`
//          )
//          if (data.isAccountExist === true) {
//            keyringController.postMessage(
//              {
//                type: eventTypes.passMessage,
//                message: {
//                  accountExists: data.isAccountExist,
//                  userId: data.userId,
//                  token: data.accessToken,
//                },
//              },
//              true
//            )
//            localStorage.setItem("accessToken", data.accessToken)
//            console.log("account exists")
//            console.log("password", data.password)
//            console.log("userId", data.userId)
//            //@ts-ignore
//            const vault = await keyringController.getVault({
//              password: data.password,
//              userId: data.userId,
//              socialName: "twitter",
//              //@ts-ignore
//              origin: JSON.parse(atob(state)).origin,
//              accessToken: data?.accessToken,
//            })
//            const publicVault = vault?.publicVault
//            if (vault?.exists === true) {
//              window.close()
//              keyringController.postMessage({
//                type: "Log in",
//                success: true,
//                data: publicVault,
//              })
//            }
//            // setTimeout(() => {
//            //   window.close()
//            // }, 2000)
//          } else {
//            localStorage.setItem("accessToken", data.accessToken)
//            //if Twitter account does not exist

//            localStorage.setItem("accessToken", data.accessToken)
//            if (isFirefox === false) {
//              keyringController.postMessage({
//                type: eventTypes.passMessage,
//                message: {
//                  accountExists: data.isAccountExist,
//                  userId: data.userId,
//                  token: data.accessToken,
//                  username: data.username,
//                },
//              })
//            }
//            localStorage.setItem("accessToken", data.accessToken)
//            if (
//              localStorage.getItem("socketId") !== null ||
//              isFirefox === true
//            ) {
//              navigate(
//                `/unitySignUp/?userId=${data.userId}&accessToken=${data.accessToken}&username=${data.username}&platform=twitter&fromClientId=${fromClientId}&origin=${dappOrigin}`
//              )
//            } else {
//              window.close()
//            }
//          }
//        }
// }
function getAllLocalStorageEncoded(
  additionalKey?: string,
  additionalValue?: string
): string {
  const getAllLocalStorage = (): Record<string, string> => {
    const items: Record<string, string> = {}
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i)
      if (key) {
        items[key] = localStorage.getItem(key) || ""
      }
    }
    return items
  }

  const allItems = getAllLocalStorage()

  // Include the additional key-value pair if provided
  if (additionalKey !== undefined && additionalValue !== undefined) {
    allItems[additionalKey] = additionalValue
  }

  return btoa(JSON.stringify(allItems))
}

function setAllLocalStorageFromEncoded(encodedString: string): void {
  try {
    const decodedData = JSON.parse(atob(encodedString)) as Record<
      string,
      unknown
    >

    // Clear existing localStorage
    localStorage.clear()

    // Set new items
    for (const [key, value] of Object.entries(decodedData)) {
      if (typeof value === "string") {
        localStorage.setItem(key, value)
      } else {
        localStorage.setItem(key, JSON.stringify(value))
      }
    }

    console.log("localStorage successfully updated")
  } catch (error) {
    console.error("Error decoding or setting localStorage:", error)
  }
}
export {
  checkDidAvailability,
  getDidRecommendations,
  getDataFromLocalStorage,
  shortenWalletAddress,
  validateEmail,
  getAllLocalStorageEncoded,
  setAllLocalStorageFromEncoded,
  //   verifyAndLogUserIn,
}
