export const DotsLoader: React.FC = () => {
    return (
        <div className="flex space-x-1 justify-center items-center ">
        <div
          style={{ backgroundColor: "gray" }}
          className="h-[6px] w-[6px] bg-white rounded-full animate-bounce [animation-delay:-0.3s]"
        ></div>
        <div
          style={{ backgroundColor: "gray" }}
          className="h-[6px] w-[6px] bg-white rounded-full animate-bounce [animation-delay:-0.15s]"
        ></div>
        <div
          style={{ backgroundColor: "gray" }}
          className="h-[6px] w-[6px] bg-white rounded-full animate-bounce"
        ></div>
      </div>
    )
}