import React, { useEffect, useContext, useState } from "react"
import Loader from "../../Components/Loader"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import axios from "axios"
import NavContext from "../../NavContext"
import { KeyringController } from "@tria-sdk/web"
import { eventTypes } from "@tria-sdk/connect"
import { socket } from "../../../src/utils/init"
import keyringController from "../../utils/init"
import { getAllLocalStorageEncoded } from "../../utils"

export default function LoaderPage() {
  const walletType = {
    embedded: true,
  }
  const [isFirefox, setIsFireFox] = useState<boolean>(false)
  const [customMessage, setCustomMessage] = useState<string>()
  const [connectionSuccess, setConnectionSuccess] = useState<boolean>()
  var sBrowser,
    sUsrAg = window.navigator.userAgent

  const navigate = useNavigate()
  const param = useParams()
  const { setToken, domain, dappLogo } = useContext(NavContext)
  const location = useLocation()
  const baseUrl = process.env.REACT_APP_BASE_URL

  // useEffect(() => {
  //   socket.emit("loginV2", {
  //     userId: localStorage.getItem("socketId"),
  //   })
  //   const interval = setInterval(() => {
  //     // console.log("store", localStorage.getItem('tria.wallet.store'))
  //     if (localStorage.getItem("tria.wallet.store") !== null) {
  //       socket.emit("messageV2", {
  //         userId: localStorage.getItem("socketId"),
  //         //@ts-ignore
  //         message: JSON.parse(localStorage.getItem("tria.wallet.store")),
  //       })
  //       clearInterval(interval)
  //     }
  //   }, 1000)
  // }, [])

  useEffect(() => {
    console.log("useEffect called")
    let isFirefox = false
    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox"
      console.log("Browser: Firefox ")
      isFirefox = true
    }
    const searchParams = new URLSearchParams(location.search)
    async function submitData() {
      const code = searchParams.get("code")
      const scope = searchParams.get("scope")
      const state = searchParams.get("state")

      const socketId =
        param.param === "twitter"
          ? // @ts-ignore
            JSON.parse(atob(state))?.socketId
          : // @ts-ignore
            JSON.parse(state)?.socketId
      // const fromClientId = localStorage.getItem("clientId") ||""
      // console.log("fromClientId");
      console.log("search_params", searchParams)
      console.log("params", param.param)
      console.log("state without parse", state)
      let fromClientId = ""
      let dappOrigin = ""
      if (param.param === "twitter") {
        //@ts-ignore
        localStorage.setItem("origin", JSON.parse(atob(state)).origin)
        //@ts-ignore
        fromClientId = JSON.parse(atob(state))?.fromClientId
        //@ts-ignore
        dappOrigin = JSON.parse(atob(state))?.dappOrigin
      } else {
        //@ts-ignore
        localStorage.setItem("origin", JSON.parse(state)?.origin)
        //@ts-ignore
        fromClientId = JSON.parse(state)?.fromClientId
        //@ts-ignore
        dappOrigin = JSON.parse(state)?.dappOrigin
      }
      console.log("fromClientId in loader", fromClientId, dappOrigin)
      //@ts-ignore
      if (code && scope && param.param === "google") {
        const {
          data: {
            userId,
            isAccountExist,
            password,
            isPasswordRequired,
            accessToken,
            username,
          },
        } = await axios.get(
          `${baseUrl}/api/v1/auth/google/callback?code=${code}&scope=${scope}&fromClientId=${fromClientId}`
        )
        console.log("res", userId, isAccountExist, password)
        if (isAccountExist === true) {
          //@ts-ignore
          console.log("origin", JSON.parse(state)?.origin)
          console.log("password", password)
          console.log("userId", userId)
          // console.log("accessToken", accessToken)
          localStorage.setItem("accessToken", accessToken)
          keyringController.postMessage(
            {
              type: eventTypes.passMessage,
              message: {
                accountExists: isAccountExist,
                userId: userId,
                token: accessToken,
                social: "google",
                username: username,
                init: localStorage.getItem("init"),
                tempVault: localStorage.getItem("tempVault"),
              },
            },
            true
          )
          localStorage.setItem("accessToken", accessToken)
          //@ts-ignore
          console.log("get vault params", {
            password: password,
            userId: userId,
            socialName: "google",
            //@ts-ignore
            origin: JSON.parse(state)?.origin,
            accessToken: accessToken,
          })
          const vault = await keyringController.getVault({
            password: password,
            userId: userId,
            socialName: "google",
            //@ts-ignore
            origin: JSON.parse(state)?.origin,
            accessToken: accessToken,
          })
          console.log("vault", vault)
          const publicVault = vault?.publicVault
          if (vault?.exists === true) {
            console.log("reached here")
            const unitySocketId = localStorage.getItem("unitySocketId")
            if (unitySocketId) {
              const encodedData = getAllLocalStorageEncoded(
                "userInfo",
                btoa(
                  JSON.stringify({
                    method: "login",
                    accountExists: isAccountExist,
                    userId: userId,
                    token: accessToken,
                    username: username,
                    social: "google",
                  })
                )
              )
              socket.emit(
                "loginV2",
                {
                  userId: socketId,
                },
                () => {
                  console.log("established socket event", socketId)
                  socket.emit("messageV2", {
                    userId: socketId,
                    message: encodedData,
                  })
                  setTimeout(() => {
                    console.log("emitted socket event", "messageV2", {
                      userId: socketId,
                      message: encodedData,
                    })
                    setCustomMessage("You can close this tab now!!")
                    if (window.location.href?.includes("access_denied")) {
                      setConnectionSuccess(false)
                    } else {
                      setConnectionSuccess(true)
                    }
                    socket.emit("loginV2", {
                      userId: unitySocketId,
                    })
                    setTimeout(() => {
                      socket.emit("messageV2", {
                        userId: unitySocketId,
                        message: publicVault,
                      })
                    }, 1000)
                    setTimeout(() => {
                      const walletData = publicVault
                      const deepLink = `com.Empire-of-Sight.EOS://login?username=${walletData?.triaName}`
                      window.location.replace(deepLink)
                    }, 2500)
                    // window.close()
                  }, 2000)
                }
              )
            } else {
              const encodedData = getAllLocalStorageEncoded(
                "userInfo",
                btoa(
                  JSON.stringify({
                    method: "login",
                    accountExists: isAccountExist,
                    userId: userId,
                    token: accessToken,
                    username: username,
                    social: "google",
                  })
                )
              )
              socket.emit(
                "loginV2",
                {
                  userId: socketId,
                },
                () => {
                  console.log("established socket event", socketId)
                  socket.emit(
                    "messageV2",
                    {
                      userId: socketId,
                      message: encodedData,
                    }
                    // (error: any, result: any) => {
                    //   console.log("error socket event", error, result)
                    //   console.log("emitted socket event", "messageV2", {
                    //     userId: socketId,
                    //     message: encodedData,
                    //   })
                    //   setCustomMessage("You can close this tab now!!")
                    //   if (window.location.href?.includes("access_denied")) {
                    //     setConnectionSuccess(false)
                    //   } else {
                    //     setConnectionSuccess(true)
                    //   }

                    //   setIsFireFox(
                    //     navigator.userAgent.toLowerCase().includes("firefox")
                    //   )
                    //   window.close()
                    // }
                  )
                  setTimeout(() => {
                    console.log("emitted socket event", "messageV2", {
                      userId: socketId,
                      message: encodedData,
                    })
                    setCustomMessage("You can close this tab now!!")
                    if (window.location.href?.includes("access_denied")) {
                      setConnectionSuccess(false)
                    } else {
                      setConnectionSuccess(true)
                    }
                    window.close()
                  }, 2000)
                }
              )
            }
          }
        } else {
          //if Google account does not exist

          console.log("log isFirefox-->", isFirefox)
          // if (isFirefox === false) {
          const encodedData = getAllLocalStorageEncoded(
            "userInfo",
            btoa(
              JSON.stringify({
                method: "signup",
                accountExists: isAccountExist,
                userId: userId,
                token: accessToken,
                username: username,
                social: "google",
              })
            )
          )
          socket.emit(
            "loginV2",
            {
              userId: socketId,
            },
            () => {
              console.log("established socket event", socketId)
              socket.emit("messageV2", {
                userId: socketId,
                message: encodedData,
              })
              // localStorage.setItem("accessToken", accessToken)
              setTimeout(() => {
                console.log("emitted socket event", "messageV2", {
                  userId: socketId,
                  message: encodedData,
                })
                setCustomMessage("You can close this tab now!!")
                if (window.location.href?.includes("access_denied")) {
                  setConnectionSuccess(false)
                } else {
                  setConnectionSuccess(true)
                }
                window.close()
              }, 2000)
            }
          )
          console.log("not posting this event")

          // keyringController.postMessage({
          //   type: eventTypes.passMessage,
          //   message: {
          //     accountExists: isAccountExist,
          //     userId: userId,
          //     token: accessToken,
          //     username: username,
          //     social: "google",
          //   },
          // })
          // }

          // window.close()
          // if (localStorage.getItem("socketId") !== null || isFirefox === true) {
          //   navigate(
          //     `/unitySignUp/?userId=${userId}&accessToken=${accessToken}&username=${username}&platform=google&fromClientId=${fromClientId}&origin=${dappOrigin}`
          //   )
          // } else {
          //   console.log("reached signup")
          //   // window.close()
          // }
          // navigate(`/signUpUserName/google/${userId}`)
        }
        // setActiveSocialMedia('google');
        // setId(userId);
        // setPassword(password);
        // setIsPasswordRequired(isPasswordRequired);
        // setIsExist(isAccountExist);
        // setFlag(false);
        // navigate('/');
        //@ts-ignore
      } else if (code && param.param === "instagram") {
        const { data } = await axios.get(
          `${baseUrl}/api/v1/auth/instagram/callback?code=${code}&fromClientId=${fromClientId}`
        )
        if (data.isAccountExist === true) {
          console.log("account exists")
          console.log("password", data.password)
          console.log("userId", data.userId)

          //@ts-ignore
          await keyringController.getVault({
            password: data.password,
            userId: data.userId,
            socialName: "instagram",
            //@ts-ignore
            origin: JSON.parse(state)?.origin,
            accessToken: data?.accessToken,
          })
          setTimeout(() => {
            window.close()
          }, 2000)
        } else {
          navigate(`/signUpUserName/instagram/${data.userId}`)
        }
        // setId(id);
        // setActiveSocialMedia('instagram');
        // setPassword(password);
        // setIsPasswordRequired(isPasswordRequired);
        // setIsExist(isAccountExist);
        // setFlag(false);
        // navigate('/');
        //@ts-ignore
      } else if (code && param.param === "discord") {
        const { data } = await axios.get(
          `${baseUrl}/api/v1/auth/discord/callback?code=${code}&fromClientId=${fromClientId}`
        )
        // console.log(data);
        if (data.isAccountExist === true) {
          // keyringController.postMessage(
          //   {
          //     type: eventTypes.passMessage,
          //     message: {
          //       accountExists: data.isAccountExist,
          //       userId: data.userId,
          //       token: data.accessToken,
          //     },
          //   },
          //   true
          // )
          console.log("data", data)
          console.log("account exists")
          console.log("password", data.password)
          console.log("userId", data.userId)
          // console.log("access_token", data.accessToken)
          localStorage.setItem("accessToken", data.accessToken)
          //@ts-ignore
          const vault = await keyringController.getVault({
            password: data.password,
            userId: data.userId,
            socialName: "discord",
            //@ts-ignore
            origin: JSON.parse(state)?.origin,
            accessToken: data.accessToken,
          })
          const publicVault = vault?.publicVault
          if (vault?.exists === true) {
            const encodedData = getAllLocalStorageEncoded(
              "userInfo",
              btoa(
                JSON.stringify({
                  method: "login",
                  accountExists: data.isAccountExist,
                  userId: data.userId,
                  token: data.accessToken,
                  username: data.username,
                  social: "discord",
                })
              )
            )
            socket.emit(
              "loginV2",
              {
                userId: socketId,
              },
              () => {
                console.log("established socket event", socketId)
                socket.emit("messageV2", {
                  userId: socketId,
                  message: encodedData,
                })
                setTimeout(() => {
                  console.log("emitted socket event", "messageV2", {
                    userId: socketId,
                    message: encodedData,
                  })
                  setCustomMessage("You can close this tab now!!")
                  if (window.location.href?.includes("access_denied")) {
                    setConnectionSuccess(false)
                  } else {
                    setConnectionSuccess(true)
                  }
                  window.close()
                }, 2000)
              }
            )
            // window.close()
            // keyringController.postMessage({
            //   type: "Log in",
            //   success: true,
            //   data: publicVault,
            // })
          }
          // setTimeout(() => {
          //   window.close()
          // }, 2000)
        } else {
          localStorage.setItem("accessToken", data.accessToken)
          //if Discord account does not exist

          // if (isFirefox === false) {
          localStorage.setItem("accessToken", data.accessToken)
          // keyringController.postMessage({
          //   type: eventTypes.passMessage,
          //   message: {
          //     accountExists: data.isAccountExist,
          //     userId: data.userId,
          //     token: data.accessToken,
          //     username: data.username,
          //   },
          // })
          // }
          const encodedData = getAllLocalStorageEncoded(
            "userInfo",
            btoa(
              JSON.stringify({
                method: "signup",
                accountExists: data.isAccountExist,
                userId: data.userId,
                token: data.accessToken,
                username: data.username,
                social: "discord",
              })
            )
          )
          socket.emit(
            "loginV2",
            {
              userId: socketId,
            },
            () => {
              console.log("established socket event", socketId)
              socket.emit("messageV2", {
                userId: socketId,
                message: encodedData,
              })
              // localStorage.setItem("accessToken", accessToken)
              setTimeout(() => {
                console.log("emitted socket event", "messageV2", {
                  userId: socketId,
                  message: encodedData,
                })
                setCustomMessage("You can close this tab now!!")
                if (window.location.href?.includes("access_denied")) {
                  setConnectionSuccess(false)
                } else {
                  setConnectionSuccess(true)
                }
                window.close()
              }, 2000)
            }
          )

          // if (localStorage.getItem("socketId") !== null || isFirefox === true) {
          //   navigate(
          //     `/unitySignUp/?userId=${data.userId}&accessToken=${data.accessToken}&username=${data.username}&platform=discord&fromClientId=${fromClientId}&origin=${dappOrigin}`
          //   )
          // } else {
          //   window.close()
          // }
        }
        // setFlag(false);
      } else if (param.param === "twitter") {
        const oauth_token = searchParams.get("oauth_token")
        const oauth_verifier = searchParams.get("oauth_verifier")
        const { data } = await axios.get(
          `${baseUrl}/api/v1/auth/twitter/callback?state=${state}&oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}&fromClientId=${fromClientId}`
        )
        if (data.isAccountExist === true) {
          keyringController.postMessage(
            {
              type: eventTypes.passMessage,
              message: {
                accountExists: data.isAccountExist,
                userId: data.userId,
                token: data.accessToken,
              },
            },
            true
          )
          localStorage.setItem("accessToken", data.accessToken)
          console.log("account exists")
          console.log("password", data.password)
          console.log("userId", data.userId)
          //@ts-ignore
          const vault = await keyringController.getVault({
            password: data.password,
            userId: data.userId,
            socialName: "twitter",
            //@ts-ignore
            origin: JSON.parse(atob(state)).origin,
            accessToken: data?.accessToken,
          })
          const publicVault = vault?.publicVault
          if (vault?.exists === true) {
            // window.close()
            // keyringController.postMessage({
            //   type: "Log in",
            //   success: true,
            //   data: publicVault,
            // })
            const encodedData = getAllLocalStorageEncoded(
              "userInfo",
              btoa(
                JSON.stringify({
                  method: "login",
                  accountExists: data.isAccountExist,
                  userId: data.userId,
                  token: data.accessToken,
                  username: data.username,
                  social: "twitter",
                })
              )
            )
            socket.emit(
              "loginV2",
              {
                userId: socketId,
              },
              () => {
                console.log("established socket event", socketId)
                socket.emit("messageV2", {
                  userId: socketId,
                  message: encodedData,
                })
                setTimeout(() => {
                  console.log("emitted socket event", "messageV2", {
                    userId: socketId,
                    message: encodedData,
                  })
                  setCustomMessage("You can close this tab now!!")
                  if (window.location.href?.includes("access_denied")) {
                    setConnectionSuccess(false)
                  } else {
                    setConnectionSuccess(true)
                  }
                  window.close()
                }, 2000)
              }
            )
          }
          // setTimeout(() => {
          //   window.close()
          // }, 2000)
        } else {
          localStorage.setItem("accessToken", data.accessToken)
          //if Twitter account does not exist
          const encodedData = getAllLocalStorageEncoded(
            "userInfo",
            btoa(
              JSON.stringify({
                method: "signup",
                accountExists: data.isAccountExist,
                userId: data.userId,
                token: data.accessToken,
                username: data.username,
                social: "twitter",
              })
            )
          )
          socket.emit(
            "loginV2",
            {
              userId: socketId,
            },
            () => {
              console.log("established socket event", socketId)
              socket.emit("messageV2", {
                userId: socketId,
                message: encodedData,
              })
              // localStorage.setItem("accessToken", accessToken)
              setTimeout(() => {
                console.log("emitted socket event", "messageV2", {
                  userId: socketId,
                  message: encodedData,
                })
                setCustomMessage("You can close this tab now!!")
                if (window.location.href?.includes("access_denied")) {
                  setConnectionSuccess(false)
                } else {
                  setConnectionSuccess(true)
                }
                window.close()
              }, 2000)
            }
          )
          // if (isFirefox === false) {
          //   keyringController.postMessage({
          //     type: eventTypes.passMessage,
          //     message: {
          //       accountExists: data.isAccountExist,
          //       userId: data.userId,
          //       token: data.accessToken,
          //       username: data.username,
          //     },
          //   })
          // }
          // localStorage.setItem("accessToken", data.accessToken)
          // if (localStorage.getItem("socketId") !== null || isFirefox === true) {
          //   navigate(
          //     `/unitySignUp/?userId=${data.userId}&accessToken=${data.accessToken}&username=${data.username}&platform=twitter&fromClientId=${fromClientId}&origin=${dappOrigin}`
          //   )
          // } else {
          //   window.close()
          // }
        }
      }
    }
    try {
      submitData()
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <>
      {!isFirefox && !customMessage ? (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Loader />
        </div>
      ) : (
        <div className='flex flex-col items-center mt-[100px] justify-start h-screen gap-[36px]'>
          <div className='flex '>
            <div className='flex w-[65px] items-center justify-center h-[65px]   rounded-full overflow-hidden'>
              {dappLogo ? (
                <img src={dappLogo} className='w-[65px] h-[65px]' />
              ) : (
                <img src='/icons/trialogo.svg' />
              )}
            </div>
            <div className='relative flex items-center justify-center ml-[12px]'>
              <img src='/icons/connectedline.svg' className=' text-center' />
              <div className='absolute left-[24px] w-[36px] h-[36px] bg-white '>
                {connectionSuccess ? (
                  <img src='/icons/tick-circle.svg' />
                ) : (
                  <img src='/icons/close-circle.svg' />
                )}
              </div>
            </div>
            {param.param === "google" && <img src='/icons/googlelogo.svg' />}
            {param.param === "twitter" && <img src='/icons/twitterlogo.svg' />}
            {param.param === "discord" && <img src='/icons/discordlogo.svg' />}
            {param.param === "apple" && <img src='/icons/applelogo.svg' />}
          </div>
          <div className='text-[black] gap-[16px]'>
            <p className='text-[18px] font-[600]'>
              {connectionSuccess
                ? "Connected Successfully!"
                : "Permission Denied!"}
            </p>
            <p className='text-[14px] font-[400] text-center'>
              You can close this tab now!
            </p>
          </div>
        </div>
      )}
    </>
  )
}
