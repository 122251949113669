import React, { useContext } from 'react'
import NavContext from '../../NavContext'
import { Link } from 'react-router-dom'

export default function Nav() {

  const { dappLogo } = useContext(NavContext)

  return (
    <div>
      <div className="w-[416px] h-10 justify-between items-start inline-flex">
        <Link to="/">
          <div className="p-2 mix-blend-difference cursor-pointer rounded-[39px] flex-col justify-center items-center gap-2 inline-flex">
          {/* <div className="w-6 h-6 relative" /> */}
          <img src='/icons/Arrow_left.svg'></img>
        </div>
        </Link>
      </div>
      <div className="w-[376px] h-[111px] py-2 shadow justify-center items-center inline-flex">
        <div className="self-stretch py-[24px] ml-20 mt-auto justify-center items-center inline-flex">
          <div className="flex-col justify-center items-center gap-2 inline-flex">
            <img className="w-[95px] h-[95px] absolute" src="/icons/tetherene.svg" />
          </div>
          <div className=" flex-col justify-center items-center gap-2 inline-flex">
            <img className="w-[95px] h-[95px] " src={dappLogo} />
          </div>
        </div>
      </div>
    </div>
  )
}
