import React from "react";
import { HideTransferBalance } from "../Loaders/HideTransferBalance";

const UsersCard = ({
  loading,
  amountInUSD,
  params,
  tokenDetails,
  senderAvatar,
  recipientAvatar,
  recieverTriaName,
  ...props
}) => {
  return (
    <div className="w-full h-1/2 px-2 py-2 rounded-2xl border-2 border-violet-400 border-opacity-30 flex-col justify-center items-center flex">
     {loading?<HideTransferBalance/>: <div className=" py-3 flex-col justify-center items-start gap-2 flex">
        <div className="self-stretch justify-center items-center gap-2 inline-flex">
          <div className="text-center text-stone-950 text-opacity-90 text-sm font-semibold font-montserrat leading-[28.80px] dark:text-text">
            ${amountInUSD?.toFixed(6) || 0}
          </div>
        </div>
        <div className="self-stretch justify-center items-center gap-1 inline-flex">
          <div className="text-center text-stone-950 text-opacity-60 text-base font-medium font-montserrat leading-tight dark:text-text">
            {params?.amount || 0} {tokenDetails?.symbol}
          </div>
        </div>
      </div>}

      <div className="w-full justify-center items-start gap-4 inline-flex">
        <div className="grow shrink basis-0 py-0  flex-col justify-center items-center gap-3 inline-flex">
          <div
            className="h-[35px] w-[35px] rounded-[50%] flex justify-center items-center"
            style={{ backgroundImage: senderAvatar?.background }}
          >
           {senderAvatar?.avatar && <img
              className="w-[35px] h-[35px] rounded-[50px]"
              src={senderAvatar?.avatar}
            />} 
          </div>
          <div className="px-2 justify-start items-center inline-flex">
            <div className="text-center text-zinc-500 text-[10px] font-semibold font-montserrat leading-[16.80px] ">
              You
            </div>
          </div>
        </div>
        <div className="py-3 flex-col  justify-center items-center gap-3 inline-flex">
          <div className="w-6 h-6 relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M14.4297 6.26318L20.4997 12.3332L14.4297 18.4032"
                stroke="#808080"
                stroke-opacity="0.8"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M3.5 12.3333H20.33"
                stroke="#808080"
                stroke-opacity="0.8"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="w-6 h-6 left-0 top-0 absolute"></div>
          </div>
        </div>
        <div className="grow shrink basis-0 py-0 flex-col justify-center items-center gap-3 inline-flex">
          <div
            className="h-[35px] w-[35px] rounded-[50%] flex justify-center items-center"
            style={{ backgroundImage: recipientAvatar?.background }}
          >
            <div className="w-[35px] h-[35px] rounded-[50px]  flex justify-center items-center text-lg">
              {recipientAvatar.avatar ? (
                <img
                  className="w-[35px] h-[35px] rounded-[50px]"
                  src={recipientAvatar?.avatar}
                />
              ) : recieverTriaName?.includes("@") ? (
                <div className="font-extrabold font-montserrat text-2xl text-black">
                  {recieverTriaName?.charAt(0)}
                </div>
              ) : (
                <div className="font-extrabold font-montserrat text-2xl text-black">
                  {recieverTriaName?.charAt(recieverTriaName?.length - 1)}
                </div>
              )}
            </div>
          </div>
          <div className="px-2 justify-start items-center inline-flex">
            <div className="text-center text-zinc-500 text-[10px] font-semibold font-montserrat leading-[16.80px]">
              {recieverTriaName}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsersCard;
