import React from "react";
import { signMessageParams } from "../types";

interface props {
  params: any;
}

const DappDetails: React.FC<props> = ({ params }) => {
  return (
    <>
      {params?.dappDomain ? (
        <div className="self-stretch py-0 md:py-3 flex-col justify-center items-center gap-4 flex">
          <div className="w-full  px-4 py-2 rounded-[52px] border-2 border-zinc-500 border-opacity-10 justify-center items-center gap-3 inline-flex">
            {params?.dappLogo && (
              <img
                className="w-7 h-7 shadow"
                src={params.dappLogo}
                alt="Dapp Logo"
              />
            )}
            <div className="text-center text-neutral-500 text-xs font-normal font-montserrat leading-[16.80px]">
              {params?.dappDomain}
            </div>
          </div>
        </div>
      ):<div className="py-2"></div>}
    </>
  );
};

export default DappDetails;
