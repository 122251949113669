import React, { useContext, useEffect, useState } from 'react';

import Footer from '../../Components/Footer';
import PhoneAndSocial from '../../Components/PhoneAndSocial';
import { useParams } from "react-router-dom";
import NavContext from '../../NavContext';
import keyringController from '../../utils/init';
import { eventTypes } from '@tria-sdk/connect';
import HomeBackgroundVector from '../../Components/HomeBackgroundVector';
import Loader from '../../Components/Loader';

interface details {
  dappLogo: string;
  dappName: string;
}

const initialData: details = {
  dappLogo: "",
  dappName: "",
}

export default function PhoneEmailOtp() {
  const [dappDetails, setDappDetails] = useState<details>(initialData);
  const param = useParams();
  const { setDappName, setDappLogo, setIsDarkMode, setDefaultCountryCode, isDarkMode } = useContext(NavContext);
  const [successloading, setSuccessLoading] = useState(false)




  // const setStateParams = () => {
  //   try {
  //     const encodedParams = param?.param;
  //     console.log("setting params",encodedParams);
  //     if(encodedParams) {
  //       const jsonString = atob('encodedParams');
  //       const jsonData = JSON.parse(jsonString);
  //       console.log("jsonData----------->",jsonData);
  //       setDappDetails({
  //         dappLogo: jsonData?.logo || '',
  //         dappName: jsonData?.dappName || ''
  //       });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  useEffect(() => {
    console.log("calling setting params");
    const currentUrl = window.location.href;
    console.log("currentUrl", currentUrl);
    // Parse the query parameters
    const urlParams = new URLSearchParams(currentUrl);

    // Get the values of dappName and dappLogo
    const dappName = urlParams.get('dappName');
    const dappLogo = urlParams.get('dappLogo');
    const mode = urlParams.get('darkMode');
    const defaultCountryCode = urlParams.get('defaultCountryCode');
    // if (mode === "true") {
    // setIsDarkMode(true)
    // } else {
    // setIsDarkMode(false)
    // }
    setDappName(dappName);
    setDappLogo(dappLogo);
    console.log("defaultCountryCode...", defaultCountryCode);
    if (defaultCountryCode) {
      setDefaultCountryCode(defaultCountryCode);
    }
    console.log("dappName", dappName, dappLogo, defaultCountryCode);
    setDappDetails({
      dappLogo: dappLogo || '',
      dappName: dappName || ''
    });
  }, []);

  const goBack = () => {
    keyringController.postMessage({
      type: eventTypes.passMessage,
      message: {
        go_back: true
      },
    }, true)
  }


  return (
    <>
      {successloading === false ?
        <div className="w-full rounded-2xl drop dark:bg-fontLightColor bg-white h-screen p-3 flex-col justify-between inline-flex  font-['Montserrat']">
          <div className="absolute top-0 left-0 ">
            {' '}
            {isDarkMode ? <img className='w-3/4' src="/icons/back.svg" /> : <div className='-ml-8 w-3/4 '><HomeBackgroundVector /></div>}
          </div>
          <div className="w-full justify-between items-start inline-flex">
            <div className="p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-center gap-2 inline-flex">
              <div className=" relative">
                <button onClick={() => goBack()}>
                  {" "}
                  <img className='h-8 w-8' src="/icons/close.svg"></img>{" "}
                </button>
              </div>
            </div>
          </div>
          <div className="flex-col justify-start gap-2 flex">
            <div>
              <div className="w-[416px]  justify-between items-start inline-flex">
                <div className="p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-center gap-2 inline-flex">
                </div>
                <div className="p-3  rounded-[39px] flex-col justify-center items-center gap-2 inline-flex" >
                </div>
              </div>
              <div className=" px-5 py-6 flex-col justify-center  gap-4 flex">
                <div className="self-stretch py-2  justify-center  gap-2 inline-flex">
                  <div className=" flex-col justify-center  gap-2 inline-flex">
                    {dappDetails?.dappLogo && <img className="w-[75px] h-[75px]" src={dappDetails?.dappLogo} alt="logo" />}
                  </div>
                </div>
                <div className="self-stretch flex-col justify-center items-start gap-4 flex">
                  <div className="self-stretch justify-center  gap-2 inline-flex">
                    <div className="text-center text-stone-950 text-opacity-80 text-sm font-medium  font-['Montserrat'] leading-snug dark:text-text">Login to {dappDetails?.dappName || "Application"}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-auto'>
            <PhoneAndSocial setSuccessLoading={setSuccessLoading} />
          </div>
          <div className='' style={{ transform: "scale(0.7)", zIndex: -1 }} >
            <div className="w-full -mb-16 -mt-2 py-3 rounded-[44px] justify-start items-start inline-flex">
              <div className="grow shrink basis-0 justify-center items-center gap-2 flex">
                <div className="w-[19px] h-[19px] relative rounded-[5px]" />
                <img src="/icons/logo.svg" alt="logo" />
                <div className="text-center text-zinc-500 text-opacity-40 text-sm font-semibold font-['Montserrat'] leading-[16.80px]">Powered by Tria</div>
              </div>
            </div>
          </div>
        </div>
        : <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'><Loader /></div>
      }
    </>
  );
}