import React, { useContext, useEffect, useState } from "react"
import Nav from "../../Components/Nav"
import ContinueWithTria from "../../Components/ContinueWithTria"
import EmailAndSocial from "../../Components/EmailAndSocial"
import ConnectWallet from "../../Components/ConnectWallet"
import HomeBackgroundVector from "../../Components/HomeBackgroundVector"
import Footer from "../../Components/Footer"
import { useListenerSO } from "@tria-sdk/connect"
import { useNavigate, useLocation } from "react-router-dom"
import NavContext from "../../NavContext"
import { detectIncognito } from "detectincognitojs"
import { socket } from "../../../src/utils/init"
import Loader from "../../Components/Loader"

export default function UnityLogin2() {
  const [continueWithTria, setContinueWithTria] = useState(true)
  const [emailAndSocial, setEmailAndSocial] = useState(false)
  const [connectWallet, setConnectWallet] = useState(false)
  const [socketId, setSocketId] = useState("")
  const [incognito, setIncognito] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [checkLoader, setCheckLoader] = useState(true)
  const {
    setToken,
    setUsername,
    setDappLogo,
    setDappName,
    dappName,
    dappLogo,
    connectWithEmail,
    setConnectWithEmail,
    continueOnIncognito,
    setContinueOnIncognito,
  } = useContext(NavContext)

  // const { eventData }: any = useListenerSO()

  // useEffect(() => {
  //   if (dappName === "" && dappLogo === "") {
  //     const searchParams = new URLSearchParams(location.search)
  //     const email = searchParams.get("connectWithEmail")
  //     setConnectWithEmail(!Boolean(email))
  //     const name = searchParams.get("dappName")
  //     setDappName(name)
  //     const logo = searchParams.get("dappLogo")
  //     setDappLogo(logo)
  //     const domain = searchParams.get("dappDomain")
  //     const userId = searchParams.get("userId")
  //     setSocketId(userId || "")
  //     console.log("userid ---->", userId)
  //     const dapp_obj = {
  //       dappDomain: domain,
  //       dappLogo: logo,
  //       dappName: name,
  //     }
  //     socket.emit("loginV2", {
  //       userId: searchParams.get("userId"),
  //     })
  //     try {
  //       localStorage?.setItem("dapp.details", JSON.stringify(dapp_obj))
  //     } catch (err) {
  //       console.log(err)
  //     }
  //   }

  //   if (continueOnIncognito === false) {
  //     detectIncognito().then((result) => {
  //       console.log(result.browserName, result.isPrivate)
  //       if (result.isPrivate === true) {
  //         setIncognito(true)
  //       }
  //     })
  //   }
  // }, [])

  // useEffect(() => {
  //   if (eventData?.message?.accountExists === false) {
  //     console.log("message_event", eventData?.message)
  //     setUsername(eventData?.message?.username)
  //     setToken(eventData?.message?.token)
  //     navigate(
  //       `/signUpUserName/${localStorage?.getItem("socialNetwork")}/${
  //         eventData?.message?.userId
  //       }`
  //     )
  //   } else {
  //     setToken(eventData?.message?.token)
  //   }
  // }, [eventData])

  // const handleStorageChange = (event) => {
  //   if (event.key === "tria.wallet.store") {
  //     const searchParams = new URLSearchParams(location.search)
  //     console.log(
  //       "change on ls --->",
  //       JSON.parse(localStorage.getItem("tria.wallet.store"))
  //     )
  //     socket.emit("messageV2", {
  //       userId: searchParams.get("userId"),
  //       message: JSON.parse(localStorage.getItem("tria.wallet.store")),
  //     })
  //     setTimeout(() => {
  //       window.close()
  //     }, 1000)
  //   }
  // }

  const socketCall = async () => {
    const walletStore = localStorage.getItem("tria.wallet.store")
    console.log("triawalletstore", walletStore)
    const searchParams = new URLSearchParams(location.search)
    if (walletStore !== null) {
      socket.emit("loginV2", {
        userId: searchParams.get("userId"),
      })
      setTimeout(() => {
        socket.emit("messageV2", {
          userId: searchParams.get("userId"),
          message: JSON.parse(walletStore),
        })
      }, 1000)
      setTimeout(() => {
        const walletData = JSON.parse(walletStore)
        const deepLink = `com.Empire-of-Sight.EOS://login?username=${walletData.triaName}`
        window.location.replace(deepLink)
      }, 2500)
    } else {
      console.log("reached this block", searchParams.get("userId"))
      localStorage.setItem("unitySocketId", searchParams.get("userId") || "")
      window.location.replace(`https://www.empireofsight.com/sign-in`)
    }
  }

  useEffect(() => {
    console.log("calling useEffect for socketcall!")
    socketCall()
  }, [])

  return (
    <>
      {checkLoader === false ? (
        <div className='w-[448px] rounded-2xl drop dark:bg-fontLightColor h-[840px] p-4 flex-col justify-between inline-flex'>
          <div className='absolute top-0 left-0 '>
            {" "}
            <img className='w-3/4' src='/icons/back.svg' />
          </div>
          <div className='flex-col justify-start gap-2 flex'>
            <div>
              <div className='w-[416px]  justify-between items-start inline-flex'>
                <div className='p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-center gap-2 inline-flex'></div>
                <div className='p-3  rounded-[39px] flex-col justify-center items-center gap-2 inline-flex'></div>
              </div>
              <div className='h-[221px] px-5 py-6 flex-col justify-center  gap-4 flex'>
                <div className='self-stretch py-2  justify-center  gap-2 inline-flex'>
                  <div className=' flex-col justify-center  gap-2 inline-flex'>
                    <img
                      className='w-[95px] h-[95px]'
                      src='https://www.empireofsight.com/assets/images/logo-icon.svg'
                    />
                  </div>
                </div>
                <div className='self-stretch h-[46px] py-3 flex-col justify-center items-start gap-4 flex'>
                  <div className='self-stretch justify-center  gap-2 inline-flex'>
                    <div className='text-center text-stone-950 text-opacity-80 text-lg font-medium font-Montserrat leading-snug dark:text-text'>
                      Login to {dappName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-auto'>
            <EmailAndSocial />
          </div>
          <Footer />
        </div>
      ) : (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Loader />
        </div>
      )}
    </>
  )
}
