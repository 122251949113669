import React from "react";
import Footer from "../Footer";
import Loader1 from "../Loader";
import TransactionSuccess from "../UI/TransactionSuccess";

export default function Loader({ heading, dappLogo, transactionSuccessful }) {
  return (
    <div className="w-full h-screen p-3 flex-col justify-between items-center inline-flex">
      <div className="flex-col justify-start items-center gap-2 flex">
        <div className="w-full justify-between items-start inline-flex">
          <div className="p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-center gap-2 inline-flex">
            <div className="w-6 h-6 relative" />
          </div>
          <div className="p-3 mix-blend-difference rounded-[39px] flex-col justify-center items-center gap-2 inline-flex" />
        </div>
        {!transactionSuccessful && <div className="h-[205px] px-5 py-6 flex-col justify-center items-center gap-4 flex">
          {dappLogo && <img className="w-[93.29px] h-[95px]" src={dappLogo} />}
          <div className="self-stretch h-[46px] py-3 flex-col justify-center items-start gap-4 flex">
            <div className="self-stretch justify-center items-center gap-2 inline-flex">
              <div className="text-center  text-stone-950 dark:text-text text-opacity-80 md:text-lg text-sm font-semibold font-montserrat leading-snug">Transaction submitting...</div>
            </div>
          </div>
        </div>
        }
      </div>
      <div className="self-stretch flex-col justify-center items-center gap-2 flex">
        <div className="w-full h-[123px] flex justify-center ">
          {/* <div className="w-[124px] h-[123px] left-0 top-0 absolute bg-zinc-500 bg-opacity-10 rounded-full" />
      <div className="w-[124px] h-[123px] left-0 top-0 absolute bg-neutral-50 rounded-full" /> */}
          {transactionSuccessful ? <TransactionSuccess /> : <Loader1 />}
        </div>
        <div className="self-stretch py-3 flex-col justify-center items-start gap-4 flex">
          <div className="flex-col self-stretch justify-center items-center gap-2 inline-flex">
            <div className="text-center  text-stone-950 dark:text-text text-sm md:text-2xl font-normal font-montserrat leading-tight mt-2">{transactionSuccessful && <>Transaction Submitted</>}</div>
            <div className="text-center  text-stone-950 dark:text-text text-opacity-80 md:text-base text-xs font-normal font-montserrat leading-tight mt-2">Confirmation may take a few minutes...</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}