import React, { useState } from 'react';
import "./index.css";

export default function ErrorMessage({ error }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const trimmedError = error?.substring(0, 80); 

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  return (
    <div className={`error-message-container py-2  ${isExpanded ? 'expanded' : ''}`}>
      <div
        className="error-message-overlay w-full"
        onClick={handleMouseLeave}
        style={{ display: isExpanded ? 'block' : 'none' }}
      />
      <div
        className="error-message-content"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <div className="p-3 bg-[#221213] rounded-xl justify-start items-center gap-3 inline-flex w-full">
          <div className="w-4 h-4 justify-center items-center flex">
            <div className="w-4 h-4 relative">
              <img src="/icons/danger-red.svg" alt="danger" />
            </div>
          </div>
          <div className="grow shrink basis-0 justify-start items-center gap-2 flex text-center break-all font-Montserrat  overflow-y-scroll max-h-[300px]  scrollbar-none">
            <div className="grow shrink basis-0 text-white text-opacity-75 text-[10px] font-medium font-Montserrat ">
            {isExpanded ? error : trimmedError}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
