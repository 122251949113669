import React, { useState, useContext, useEffect, useRef } from "react";
import CountryData from "../utils/countrycode.json"
import ConfirmEmail from "../Pages/ConfirmEmail";
import Dropdown from "./Dropdown";
import { useNavigate, useLocation } from "react-router-dom";
import { useTriaAuth } from "../contexts/tria-auth-provider";
import OtpInput from "react-otp-input";
import Loader from "./Loader";
import NavContext from "../NavContext";
import keyringController from "../utils/init";
import { validateEmail } from "../utils"
import { baseUrl, walletType } from "../utils/constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// import "./phoneAndSocial.css";
import { DotsLoader } from "./Loaders/index";

export default function PhoneAndSocial({ setSuccessLoading }) {
  const [loading, setLoading] = useState(false);
  const [emailState, setEmailState] = useState(true);
  const [countryCode, setCountryCode] = useState("+91");

  console.log("countryCode---------=------>", countryCode);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [optResp, setOptResp] = useState(true);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [session, setSession] = useState("");
  const [type, setType] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const [showChangeButton, setShowChangeButton] = useState(false);
  const [showdropdown, setShowDropdown] = useState(false)
  const [filteredCountries, setFilteredCountries] = useState<any>(CountryData);
  const [searchCountry, setSearchCountry] = useState("");
  const dropdownRef = useRef(null);
  const { setUserEmail, setStoredPassword, setHashPass, setHash, setOtpLogin, isDarkMode } =
    useContext(NavContext);

  const navigate = useNavigate();
  const { initiateOTP, verifyOTP } = useTriaAuth();
  console.log("phone-------------------------", email, phone);

  console.log("-------------->", optResp);

  const handleClickOutside = (event: MouseEvent) => {
    //@ts-ignore
    if (dropdownRef.current && !dropdownRef.current?.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener('click', handleClickOutside);
    // Detach the event listener on unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  const handleSearch = (searchValue) => {
    setSearchCountry(searchValue);
    const lowerCaseSearch = searchValue?.toLowerCase();
    const filtered = CountryData.filter(
      (country) =>
        country.name.toLowerCase().includes(lowerCaseSearch) ||
        country.number.includes(searchValue) // Check for a match in the country code
    );
    setFilteredCountries(filtered);
  };


  const toggleState = async () => {
    setShowChangeButton(false);
    setIsEditable(true);
    // setEmailState(!emailState);
    setPhone("");
    setEmail("");
    setError("");
    setMessage("");
    setOtp("");
  };


  const handleOtpChange = (otpValue: string) => {
    if (/^\d*$/.test(otpValue)) {
      setOtp(otpValue);
      setError("");
      setMessage("");
      if (otpValue.length === 6 && (phone || email)) {
        verifyOtp(otpValue);
      }
    }
  };

  const verifyOtp = async (otp: string) => {
    try {
      setLoading(true);
      setIsEditable(true);
      const input = phone ? `${countryCode}${phone}` : email;

      console.log("sending session----------------------->", {
        input,
        session,
        otp,
      });
      const fromClientId = localStorage.getItem("clientId") || ""
      const verifyOtpResp = await verifyOTP({
        input,
        session,
        code: otp,
        isPasswordLess: true,
        verificationType: "otp",
        fromClientId
      });
      console.log("verify", verifyOtpResp);
  
      if (verifyOtpResp?.accessToken && verifyOtpResp?.hash !== undefined && verifyOtpResp?.success) {
        setMessage(verifyOtpResp?.message);
        localStorage.setItem("accessToken", verifyOtpResp?.accessToken);
        localStorage.setItem(
          "idToken",
          verifyOtpResp?.data?.AuthenticationResult?.IdToken
        );

        if (!verifyOtpResp?.isAccountExist) {
          setUserEmail(input);
          setHash(verifyOtpResp?.hash);
          setHashPass(verifyOtpResp?.password);
          setOtpLogin(true);
          navigate("/SignUp");
        } else {
          setSuccessLoading(true);
          const vault = await keyringController.getVault({
            input: input,
            link: false,
            hash: verifyOtpResp?.hash,
            password: verifyOtpResp?.password,
            origin: document.referrer,
            accessToken: verifyOtpResp?.accessToken,
            //@ts-ignore
            idToken: verifyOtpResp?.data?.AuthenticationResult?.IdToken,
          });
          console.log("vault ", vault);
          const publicVault = vault?.publicVault
          if (vault?.exists === true) {
            keyringController.postMessage({
              type: 'Log in',
              success: true,
              data: publicVault,
            })
          }
        }
      } else {
        if (verifyOtpResp?.invalidOtp) {
          console.log("invalid otp")
          setSession(verifyOtpResp?.session);
        }
        setError(verifyOtpResp?.message);
      }
      setLoading(false);
    } catch (err) {
      console.error(" Error in verifyOtp:", err);
      setError("An error occurred");
      setLoading(false);
    }
  };
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isEditable && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditable])

  const handleSubmit = async () => {
    try {
      setMessage("")
      setError("");

      if (emailState && !validateEmail(email)) {
        setError("Please enter a valid email");
        return;
      }

      setLoading(true);
      const input = phone ? `${countryCode}${phone}` : email;
      console.log("input", input);
      const fromClientId = localStorage.getItem("clientId") || ""
      const res = await initiateOTP({
        input,
        password: undefined,
        isPasswordLess: true,
        verificationType: "otp",
        origin: document.referrer,
        fromClientId
      });
      const type = emailState ? "email" : "phone";
      setType(type);
      setSession(res?.session);
      const param = { ...res, input, type };
      console.log("resp----->", res);
      if (res.success) {
        setOptResp(param);
        setIsEditable(false);
        setShowChangeButton(true);
        setMessage(res?.message);
      } else {
        setError(res?.message);
      }

      setLoading(false);
    } catch (err) {
      console.error("Error in sending otp:", err);
      setError("Error in sending otp");
      setLoading(false);
    }
  };



  return (
    <>
      <div className="text-center mb-2 text-red-500 text-[10px] font-medium font-['Montserrat'] leading-[16.80px] flex justify-center gap-1 items-center">
        {error}
      </div>
      <div className="text-center mb-2 text-green-500 text-[10px] font-medium  font-['Montserrat'] leading-[16.80px] flex justify-center gap-1 items-center">
        {message}
      </div>
      <div
        className="w-full px-3 py-3 rounded-2xl border border-violet-400 border-opacity-30 flex-col justify-center items-center gap-15 inline-flex"
        ref={dropdownRef}
      >
        <div className="self-stretch py-0 justify-center items-center gap-3 inline-flex mb-2">
          <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-1 flex  ">
            <div className="text-center dark:text-white text-black text-opacity-80 text-[13px] font-medium  font-['Montserrat'] leading-snug">
              {emailState ? "Continue with email" : "Continue with phone"}
            </div>
            <div className="h-[14px] px-2 py-1 bg-pink-500 bg-opacity-10 rounded-[22px] justify-center items-center gap-2 flex">
              <div className="text-center text-pink-500 text-opacity-90 text-[9px] font-medium  font-['Montserrat'] leading-[14.40px]">
                fast
              </div>
            </div>
          </div>
          <div className="justify-start items-start flex cursor-pointer bg-gray-600 rounded-[20px]  border-opacity-50  border-solid  shadow-md bg-opacity-30  backdrop-blur-lg">
            <div
              onClick={() => {
                setEmailState(true);
                toggleState();
              }}
              className={`px-3 py-1.5  rounded-tl-[20px] rounded-bl-[20px] justify-center items-center gap-2 flex ${emailState ? "bg-opacity-10" : "bg-opacity-5"
                }`}
            >
              <div className="w-[12px] h-[12px] justify-center items-center flex">
                <div className="w-[12px] h-[12px] relative">
                  <img
                    className="h-0 w-0 dark:h-[12px] dark:w-[12px]"
                    src={emailState ? "/icons/sms-light.svg" : "/icons/sms.svg"}
                    alt="sms"
                  />
                  <img
                    className="dark:h-0 dark:w-0"
                    src={emailState ? "/icons/sms.svg" : "/icons/sms-light.svg"}
                    alt="sms"
                  />
                </div>
              </div>
            </div>
            <div
              onClick={() => {
                setEmailState(false);
                toggleState();
              }}
              className={`px-3 py-1.5  rounded-tr-[20px] rounded-br-[20px] justify-center items-center gap-2 flex ${emailState ? "bg-opacity-5" : "bg-opacity-10"
                }`}
            >
              <div className="w-[12px] h-[12px] justify-center items-center flex">
                <div className="w-[12px] h-[12px] relative">
                  <img
                    className="h-0 w-0 dark:h-[12px] dark:w-[12px]"
                    src={
                      !emailState ? "/icons/call-light.svg" : "/icons/call.svg"
                    }
                    alt="phone"
                  />
                  <img
                    className="dark:h-0 dark:w-0"
                    src={
                      !emailState ? "/icons/call.svg" : "/icons/call-light.svg"
                    }
                    alt="phone"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {emailState ? (
          // email and social
          <div className="self-stretch py-1 justify-between gap-2 inline-flex items-center w-full ">
            <div className="w-full h-4 py-2 bg-white drop dark:bg-fontLightColor bg-opacity-5 rounded-[20px] justify-start items-center flex">
              <div className="w-[100%] justify-start items-center flex">
                <input
                  className="outline-none w-full px-3 py-1.5 text-stone-950 dark:text-text text-opacity-80 text-[10px] md:text-base font-medium font-['Montserrat'] bg-gray-500 bg-opacity-20 rounded-[20px] justify-start items-center flex"
                  type="email"
                  placeholder="your@email.com"
                  value={email}
                  // readOnly={!isEditable}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                    setMessage("");
                    setShowChangeButton(false);
                  }}
                  autoFocus={isEditable}
                />
              </div>
            </div>
            <RenderButtonContent
              loading={loading}
              showChangeButton={showChangeButton}
              setIsEditable={setIsEditable}
              setShowChangeButton={setShowChangeButton}
              handleSubmit={handleSubmit}
            />
          </div>
        ) : (
          // email and social
          <div className="self-stretch py-1 justify-between items-center gap-2 inline-flex relative">
            <div className=" -opacity-10 drop  rounded-[20px]  justify-start items-center flex">
              <div className=" w-[60vw] h-7 bg-black dark:bg-white bg-opacity-5 dark:bg-opacity-5 rounded-[20px] justify-start items-center gap-2 inline-flex">
                <div
                  className=" self-stretch p-3 bg-black dark:bg-white  rounded-l-[20px] bg-opacity-10 dark:bg-opacity-10  justify-between items-center flex cursor-pointer"
                  onClick={() => {
                    setShowDropdown(!showdropdown);
                    handleSearch("");
                  }}
                >
                  <div className=" text-center text-black  dark:text-white text-opacity-80 text-[10px] font-medium font-['Montserrat'] leading-tight">
                    {countryCode}
                  </div>
                  <div className="ml-2 w-2 h-2 justify-center items-center gap-2 flex">
                    {/* <div className="w-3 h-3 relative"> */}
                    {isDarkMode ? (
                      <img src="/icons/arrow-down-fill.svg" />
                    ) : (
                      <img src="/icons/arrow-down-fill-dark.svg" />
                    )}
                    {/* </div> */}
                  </div>
                </div>
                <div className="justify-start items-center flex">
                  <div className="text-center text-white text-[10px] font-medium font-['Montserrat'] leading-tight">
                    <input
                      className="w-full outline-none px-1 py-1.5 text-stone-950 dark:text-text font-medium font-['Montserrat'] bg-transparent rounded-[20px] justify-start items-center flex"
                      onChange={(e) =>
                        setPhone(e.target.value.replace(/\D/g, ""))
                      }
                      value={phone}
                      autoFocus={true}
                    />
                  </div>
                </div>
              </div>
              {showdropdown && (
                <div className="top-8 absolute w-full  p-1 bg-gray-100 dark:bg-zinc-800 rounded-lg flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch h-8 justify-start items-center gap-4 rounded-lg inline-flex bg-gray-300 dark:bg-zinc-900 ">
                    <div className="grow shrink basis-0 h-[34px] px-3 py-2  justify-start items-center gap-1 flex">
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                        <div className="self-stretch justify-between  items-center inline-flex">
                          <input
                            className="outline-none text-zinc-500 dark:text-white text-[9px] font-medium font-['Montserrat'] leading-[16.80px] bg-transparent"
                            placeholder="Search country code"
                            onChange={(e) => handleSearch(e.target.value)}
                            value={searchCountry}
                          />
                          <div className="w-[12px] h-[12px] justify-center items-center flex cursor-pointer" onClick={() => handleSearch("")}>
                            <div className="w-[12px] h-[12px] relative">
                              <img src="/icons/search-normal.svg" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="w-1.5 h-[62px] bg-zinc-900 rounded-[11px]" /> */}
                  <div className="self-stretch h-44 pr-2 flex-col justify-start  text-base items-center gap-2 flex overflow-y-scroll   scrollbar-thin scrollbar-thumb-gray-400  dark:scrollbar-thumb-gray-600   ">
                    {filteredCountries?.map((country) => {
                      return (
                        <div
                          className="p-1 self-stretch justify-between items-center inline-flex cursor-pointer hover:bg-gray-300 dark:hover:bg-gray-900 rounded-lg"
                          onClick={() => {
                            setCountryCode(country.number);
                            setShowDropdown(false);
                          }}
                        >
                          <div className="h-4 justify-center items-center gap-3 flex">
                            <div className="w-4 h-4 mt-1 relative item-center">
                              <img src={country.flag} alt="flag" />
                            </div>
                            <div className=" text-black dark:text-white text-opacity-80 text-[9px] font-medium font-['Montserrat'] leading-tight">
                              {country.name}
                            </div>
                          </div>
                          <div className="text-center text-black dark:text-white text-opacity-80 text-[9px] font-medium font-['Montserrat'] leading-tight">
                            {country.number}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            <RenderButtonContent
              loading={loading}
              showChangeButton={showChangeButton}
              setIsEditable={setIsEditable}
              setShowChangeButton={setShowChangeButton}
              handleSubmit={handleSubmit}
            />
          </div>
        )}
        {optResp && (
          <>
            {" "}
            <div className="w-full px-2 justify-start items-center inline-flex mt-2">
              <div className=" text-white text-opacity-80 md:text-sm text-[11px] font-normal  font-['Montserrat']">
                Enter OTP
              </div>
            </div>
            <div className="self-stretch flex-col justify-center items-center gap-2 flex ">
              <div className="self-stretch flex-col justify-between items-center flex mb-2">
                <div className="w-full flex text-white justify-center py-0 font-['Montserrat']">
                  <OtpInput
                    value={otp}
                    onChange={handleOtpChange}
                    numInputs={6}
                    renderSeparator={<span> </span>}
                    renderInput={(props) => <input {...props} />}
                    // shouldAutoFocus={true}
                    inputStyle={{
                      border: "none",
                      borderRadius: "6px",
                      margin: "8px",
                      width: "27px",
                      height: "27px",
                      fontSize: "15px",
                      color: isDarkMode ? "white" : "black",
                      fontWeight: "500",
                      backgroundColor: isDarkMode ? "black" : "#a5a0a0",
                    }}
                  />
                </div>
              </div>
              <div className="w-full px-2 -mt-2 justify-between items-center inline-flex">
                <div className="grow shrink basis-0  text-white text-opacity-50 md:text-sm text-[8px] font-normal  font-['Montserrat']">
                  Didn’t get the OTP?
                </div>
                <div
                  className=" text-white text-opacity-80 md:text-sm text-[8px] font-medium  font-['Montserrat'] cursor-pointer"
                  onClick={() => {
                    handleSubmit();
                    setShowChangeButton(false);
                  }}
                >
                  Resend code
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}


function RenderButtonContent({ loading, showChangeButton, setIsEditable, setShowChangeButton, handleSubmit }) {
  return (
    <div
      className="w-[99px] h-7 px-2 py-2 bg-gray-300 dark:bg-white rounded-[20px] justify-center items-center flex cursor-pointer transition-all duration-300 ease-in-out hover:bg-gray-400"
      onClick={() => {
        if (loading === false) {
          // if (showChangeButton) {
          //   setIsEditable(true);
          //   setShowChangeButton(false);
          // } else {
          handleSubmit();
          // }
        }
      }}
    >
      <div className="justify-center items-center flex">
        <div className="text-center text-stone-950 md:text-base text-[9px] font-semibold  font-['Montserrat'] leading-tight ">
          {loading === false ? (
            // showChangeButton ? (
            //   <span className=" text-gray-800 hover:text-black ">change</span>
            // ) : (
            <span className="  text-gray-800 hover:text-black whitespace-nowrap">
              Send OTP
            </span>
          ) : (
            // )
            <DotsLoader />
          )}
        </div>
      </div>
    </div>
  );
}