import React, { useEffect } from 'react'
import Nav from '../../Components/Nav'
import HomeBackgroundVector from '../../Components/HomeBackgroundVector'
import Footer from '../../Components/Footer'
import OtpInput from 'react-otp-input';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { useTriaAuth } from '../../contexts/tria-auth-provider';

const Index = () => {

  const [otp, setOtp] = useState('');
  const { initiateOTP, verifyOTP } = useTriaAuth();
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  const { param = '' } = useParams();
  const decodedParam = JSON.parse(decodeURIComponent(param));
  console.log(decodedParam);
  console.log(otp);

  const handleOtpChange = (otpValue: string) => {
    setOtp(otpValue);

    // Check if the OTP length is 6 and trigger your function
    if (otpValue.length === 6) {
      verifyOtp(otpValue);
    }
  };


  const verifyOtp = async (otpValue: string) => {
    console.log("triggered");
    try{
   const verifyOtpResp=await verifyOTP({input:decodedParam.input,session:session,code:otpValue,isPasswordLess:true,verificationType: "otp",fromClientId:"0632cad6-f84e-4dce-aaa4-d23e57715a36"});
   console.log("verify",verifyOtpResp);
   navigate("/SignUp");
    }
    catch (err) {
      console.log(err);
    }
  }

  // const verifyOtp=async(otpValue:string)=>{
  //   handleVerifyOtp({input:decodedParam.input,session:decodedParam.session,otp:otpValue});
  // }

  const resendOtp = async () => {
    const res = await initiateOTP(decodedParam?.input);
    setSession(res?.session);
    console.log("otp sent successfully", res);
  }

  useEffect(() => {
    if (decodedParam) {
      setSession(decodedParam.session);
    }

  }, [decodedParam])

  return (
    <div>
      <div className="w-[448px] h-[840px] bg-white dark:bg-fontLightColor rounded-2xl p-4 flex-col justify-between items-center inline-flex">
        <div style={{ marginLeft: '-150px' }} className="absolute top-0 ">
          {' '}
          <HomeBackgroundVector />
        </div>
        <div className="flex-col justify-start items-center gap-2 flex">
          <Nav />
        </div>
        <div className="h-[289px] flex-col  items-center gap-3 flex">
          <div className="h-[234px] flex-col justify-center items-center gap-3 flex">
            <div className="self-stretch h-[234px] px-5 py-4 rounded-2xl border border-violet-400 border-opacity-30 flex-col justify-center items-center gap-2 flex">
              <div className="self-stretch h-[46px] py-3 flex-col justify-center items-start gap-4 flex">
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                  <div className="mix-blend-difference text-center text-white text-opacity-80 text-lg font-medium font-['Montserrat'] leading-snug">Confirm your {decodedParam?.type}</div>
                </div>
              </div>
              <div className="w-[376px] px-2 justify-start items-center inline-flex">
                <div className="grow shrink basis-0 mix-blend-difference text-white text-opacity-50 text-sm font-normal font-['Montserrat']">This is a mandatory step of security that helps you onboard. You will need this to authorize the account integrity.</div>
              </div>

              {/* <div className="self-stretch py-3 justify-center items-center gap-4 inline-flex">
            <div className="grow shrink basis-0 h-10 px-5 py-3 bg-zinc-500 bg-opacity-10 rounded-[20px]" />
            <div className="grow shrink basis-0 h-10 px-5 py-3 bg-zinc-500 bg-opacity-10 rounded-[20px]" />
            <div className="grow shrink basis-0 h-10 px-5 py-3 bg-zinc-500 bg-opacity-10 rounded-[20px]" />
            <div className="grow shrink basis-0 h-10 px-5 py-3 bg-zinc-500 bg-opacity-10 rounded-[20px]" />
            <div className="grow shrink basis-0 h-10 px-5 py-3 bg-zinc-500 bg-opacity-10 rounded-[20px]" />
            <div className="grow shrink basis-0 h-10 px-5 py-3 bg-zinc-500 bg-opacity-10 rounded-[20px]" />
          </div> */}
              <div className='w-full flex justify-center py-2 font-Montserrat'>
                <OtpInput
                  value={otp}
                  onChange={handleOtpChange}
                  numInputs={6}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => <input {...props} />}
                  shouldAutoFocus={true}
                  inputStyle={{
                    border: "1px solid gray",
                    borderRadius: "8px",
                    marginRight: '5px',
                    width: "40px",
                    height: "40px",
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: "400",
                  }}
                />
              </div>

              <div className="w-[376px] px-2 justify-start items-center inline-flex">
                <div className="grow shrink basis-0 mix-blend-difference text-white text-opacity-50 text-sm font-normal font-['Montserrat']">Didn’t get an email?</div>
                <div className="mix-blend-difference cursor-pointer text-white text-opacity-80 text-sm font-medium font-['Montserrat']" onClick={() => resendOtp()}>Resend code</div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Index
