import "./App.css"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useEffect, useState } from "react"
import ConfirmEmail from "./Pages/ConfirmEmail"
import SignUpUserName from "./Pages/SignUp/SignUpUserName"
import PasswordPage from "./Pages/SignUp/SignUpPassword"
import SignInPassword from "./Pages/SignIn/SignInPassword"
import SignUpPassword from "./Pages/SignUp/SignUpPassword"
import Welcome from "./Pages/WelcomePage/Welcome"
import Onboarding from "./Pages/Onboarding"
import ConnectWallet from "./Pages/ConnectWallet"
import ConnectingAnimation from "./Pages/ConnectWallet/ConnectingAnimation"
import CreateWallet from "./Pages/CreateWallet"
import SignUpPasswordConfirm from "./Pages/SignUp/SignUpConfirmPassword"
import LoaderPage from "./Pages/Loader"
import NavContext from "./NavContext"
import SignMessage from "./Pages/SignMessage"
import SendAsset from "./Pages/Send"
import Mint from "./Pages/Mint"
import { useSocialConnect } from "@tria-sdk/connect"
import VerificationPage from "./Pages/Verification"
import OnboardingHome from "./Pages/OnboardingHome"
import VerifyAccount from "./Pages/VerifyAccount"
import Verified from "./Pages/Verified"
import Logout from "./Pages/Logout"
import Account from "./Pages/Recover/Account"
import Email from "./Pages/Recover/Email"
import Reset from "./Pages/Recover/Reset"
import ResetConfirm from "./Pages/Recover/ResetConfirm"
import LoginDetect from "./Pages/LoginDetect"
import LoginDetected from "./Components/Popup/LoginDetected"
import Incognito from "./Pages/Incognito"
import NewDevice from "./Pages/Recover/NewDevice"
import AccessToken from "./Pages/AccessToken"
import StackHome from "./Pages/Stack/StackHome"
import SignUp from "./Pages/Link"
import Approve from "./Pages/Approve"
import keyringController from "./utils/init"
import { eventTypes, useListenerSO } from "@tria-sdk/connect"
import PhoneEmailOtp from "./Pages/PhoneEmailOtp"
import PinRecovery from "./Pages/Recover/PinRecovery"
import SocialConnectorIframe from "./Components/SocialConnectorIframe/Index"
import SendNft from "./Pages/SendNft"
import SignUpIFrame from "./Pages/SignUp/SignUpIframe"
import UnityLogin from "./Pages/Unity"
import Test from "./Pages/Test"
import SignUpUnity from "./Pages/SignUp/SignUpUnity"
import Encrypt from "./Pages/Encrypt"
import Decrypt from "./Pages/Decrypt"
import SignUpWide from "./Components/SignUpIframe"
import SessionLogout from "./Components/Popup/SessionLogout"
import Swap from "./Pages/Swap"
import UnityLogin2 from "./Pages/Unity2"
import SocketListener from "./Pages/ListenForSocket"

const App = () => {
  const [token, setToken] = useState("")
  const [username, setUsername] = useState("")
  const [storedPassword, setStoredPassword] = useState("")
  const [dappName, setDappName] = useState("")
  const [dappLogo, setDappLogo] = useState("")
  const [connectWithEmail, setConnectWithEmail] = useState(false)
  const [continueOnIncognito, setContinueOnIncognito] = useState(false)
  const [userEmail, setUserEmail] = useState("")
  const [hash, setHash] = useState("")
  const [hashPass, setHashPass] = useState("")
  const [isFirefox, setIsFirefox] = useState(false)
  const [otpLogin, setOtpLogin] = useState(false)

  const { eventData }: any = useListenerSO()
  //const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('darkMode') === 'true');
  const [isDarkMode, setIsDarkMode] = useState(true)
  const { success } = useSocialConnect()
  const [sessionEnd, setSessionEnd] = useState(false)
  const [isFF, setIsFF] = useState(false)
  const [defaultCountryCode, setDefaultCountryCode] = useState("in")
  const [fromClientId, setFromClientId] = useState("")

  useEffect(() => {
    try {
      // console.log(" ONBOARDING FLOWWWWW")
      if (localStorage?.getItem("accessToken") !== "undefined") {
        keyringController.postMessage({
          //@ts-ignore
          type: eventTypes?.accessToken /* import {eventTypes} from "@tria-sdk/connect" */,
          data: localStorage?.getItem("accessToken") /*from local storage*/,
        })
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    var sBrowser,
      sUsrAg = window.navigator.userAgent
    let isFirefoxBrowser = false
    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox"
      // console.log("Browser: Firefox ")
      isFirefoxBrowser = true
      setIsFF(true)
    }
    try {
      if (localStorage?.getItem("idToken") !== null) {
        keyringController.postMessage({
          //@ts-ignore
          type: "Id Token Pass",
          data: localStorage?.getItem("idToken"),
        })
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    console.log("sdfsdf")
    ;(async () => {
      window.addEventListener(
        "unauthorized",
        function (event) {
          console.log("Unauthorized event triggered!")
          setSessionEnd(true)
        },
        false
      )
    })()
  }, [])

  // useEffect(() => {
  //   //@ts-ignore
  //   localStorage.setItem('darkMode', isDarkMode);
  // }, [isDarkMode]);

  const obj = {
    token,
    setToken,
    storedPassword,
    setStoredPassword,
    username,
    setUsername,
    dappName,
    setDappName,
    dappLogo,
    setDappLogo,
    connectWithEmail,
    setConnectWithEmail,
    continueOnIncognito,
    setContinueOnIncognito,
    userEmail,
    setUserEmail,
    hash,
    setHash,
    hashPass,
    setHashPass,
    isFirefox,
    setIsFirefox,
    otpLogin,
    setOtpLogin,
    isDarkMode,
    setIsDarkMode,
    sessionEnd,
    isFF,
    defaultCountryCode,
    setDefaultCountryCode,
    fromClientId,
    setFromClientId,
  }
  console.log("defaultCountryCode", defaultCountryCode)

  return (
    <div
      className={
        isFF
          ? `shadow-lg text-white right-0 bottom-0 ${isDarkMode ? "dark" : ""}`
          : ` h-[98vh] ${isDarkMode ? "dark" : ""}`
      }
    >
      {sessionEnd && <SessionLogout setSessionEnd={setSessionEnd} />}
      <div className={sessionEnd ? "blur-background" : ""}>
        <NavContext.Provider value={obj}>
          <Router>
            <Routes>
              <Route path='/' element={<OnboardingHome />} />
              <Route path='/confirmEmail/:param' element={<ConfirmEmail />} />
              <Route
                path='/signUpUserName/:param1/:param'
                element={<SignUpUserName />}
              />
              <Route
                path='/signUpUserNameIframe/:param1/:param/:param2/:param3'
                element={<SignUpIFrame />}
              />
              <Route path='/unitySignUp/' element={<SignUpUnity />} />
              <Route
                path='/signUpConfirmPassword'
                element={<SignUpPasswordConfirm />}
              />
              <Route
                path='/signInPassword/:param'
                element={<SignInPassword />}
              />
              <Route path='/callback/:param' element={<LoaderPage />} />
              <Route path='/signMessage/:param' element={<SignMessage />} />
              <Route path='/send/:param' element={<SendAsset />} />
              <Route path='/mint/:param' element={<Mint />} />
              <Route path='/approve/:param' element={<Approve />} />
              <Route path='/sendNft/:param' element={<SendNft />} />
              <Route path='/verify' element={<VerificationPage />} />
              <Route path='/accessToken' element={<AccessToken />} />
              <Route path='/verifyAccount' element={<VerifyAccount />} />
              <Route path='/verified' element={<Verified />} />
              <Route path='/resetConfirm' element={<ResetConfirm />} />
              <Route path='/reset' element={<Reset />} />
              <Route path='/loginDetect' element={<LoginDetect />} />
              <Route path='/loginDetected' element={<LoginDetected />} />
              <Route path='/incognito' element={<Incognito />} />
              <Route path='/newDevice' element={<NewDevice />} />
              <Route path='/stackHome' element={<StackHome />} />
              <Route path='/SignUp' element={<SignUp />} />
              <Route path='/newDevice' element={<NewDevice />} />
              <Route path='/pinRecovery' element={<PinRecovery />} />
              <Route
                path='/SocialLoginIframe/'
                element={<SocialConnectorIframe />}
              />
              <Route path='/iframeSignUp/' element={<SignUpWide />} />
              <Route path='/unityLogin/' element={<UnityLogin />} />
              <Route path='/unityLogin2/' element={<UnityLogin2 />} />
              <Route path='/test' element={<Test />} />
              <Route path='/phoneEmailOtp' element={<PhoneEmailOtp />} />
              <Route path='/encrypt/:param' element={<Encrypt />} />
              <Route path='/decrypt/:param' element={<Decrypt />} />
              <Route path='/swap/:param' element={<Swap />} />
              <Route path='/listenForSocket' element={<SocketListener />} />
            </Routes>
          </Router>
        </NavContext.Provider>
      </div>
    </div>
  )
}

export default App
