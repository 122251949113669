import React, { useContext, useEffect, useState } from "react"
import Nav from "../../Components/Nav"
import ContinueWithTria from "../../Components/ContinueWithTria"
import EmailAndSocial from "../../Components/EmailAndSocial"
import ConnectWallet from "../../Components/ConnectWallet"
import HomeBackgroundVector from "../../Components/HomeBackgroundVector"
import Footer from "../../Components/Footer"
import { useListenerSO } from "@tria-sdk/connect"
import { useNavigate, useLocation, useParams } from "react-router-dom"
import NavContext from "../../NavContext"
import { detectIncognito } from "detectincognitojs"
import { socket } from "../../../src/utils/init"

import Loader from "../../Components/Loader"

export default function UnityLogin() {
  const [continueWithTria, setContinueWithTria] = useState(true)
  const [emailAndSocial, setEmailAndSocial] = useState(false)
  const [connectWallet, setConnectWallet] = useState(false)
  const [socketId, setSocketId] = useState("")
  const [incognito, setIncognito] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [checkLoader, setCheckLoader] = useState(true)
  const {
    setToken,
    setUsername,
    setDappLogo,
    setDappName,
    dappName,
    dappLogo,
    connectWithEmail,
    setConnectWithEmail,
    continueOnIncognito,
    setContinueOnIncognito,
  } = useContext(NavContext)

  const { eventData }: any = useListenerSO()

  useEffect(() => {
    if (dappName === "" && dappLogo === "") {
      const searchParams = new URLSearchParams(location.search)
      const email = searchParams.get("connectWithEmail")
      setConnectWithEmail(!Boolean(email))
      const name = searchParams.get("dappName")
      setDappName(name)
      const logo = searchParams.get("dappLogo")
      setDappLogo(logo)
      const domain = searchParams.get("dappDomain")
      const userId = searchParams.get("userId")
      //@ts-ignore
      //localStorage.setItem("socketId", searchParams.get("userId"))
      //@ts-ignore
      setSocketId(userId)
      console.log("userid ---->", userId)
      const dapp_obj = {
        dappDomain: domain,
        dappLogo: logo,
        dappName: name,
      }
      socket.emit("loginV2", {
        userId: searchParams.get("userId"),
      })
      try {
        localStorage?.setItem("dapp.details", JSON.stringify(dapp_obj))
      } catch (err) {
        console.log(err)
      }
    }
    //Test logs
    const searchParams = new URLSearchParams(location.search)
    const name = searchParams.get("dappName")
    const logo = searchParams.get("dappLogo")
    const email = searchParams.get("connectWithEmail")
    console.log("DappName", name)
    console.log("Logo", logo)
    console.log("email", !Boolean(email))

    if (continueOnIncognito === false) {
      detectIncognito().then((result) => {
        console.log(result.browserName, result.isPrivate)
        if (result.isPrivate === true) {
          // navigate("/incognito")
          setIncognito(true)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (eventData?.message?.accountExists === false) {
      console.log("message_event", eventData?.message)
      //localStorage.setItem("accessToken", eventData?.message?.token)
      setUsername(eventData?.message?.username)
      setToken(eventData?.message?.token)
      navigate(
        `/signUpUserName/${localStorage?.getItem("socialNetwork")}/${
          eventData?.message?.userId
        }`
      )
    } else {
      //localStorage.setItem("accessToken", eventData?.message?.token)
      setToken(eventData?.message?.token)
    }
  }, [eventData])

  const handleStorageChange = (event) => {
    if (event.key === "tria.wallet.store") {
      const searchParams = new URLSearchParams(location.search)
      //@ts-ignore
      console.log(
        "change on ls --->",
        //@ts-ignore
        JSON.parse(localStorage.getItem("tria.wallet.store"))
      )
      socket.emit("messageV2", {
        userId: searchParams.get("userId"),
        //@ts-ignore
        message: JSON.parse(localStorage.getItem("tria.wallet.store")),
      })
      setTimeout(() => {
        window.close()
      }, 1000)
    }
  }

  // useEffect(() => {
  //     const handleStorage = (event) => {
  //         if (event.storageArea === localStorage) {
  //             handleStorageChange(event);
  //         }
  //     };

  //     window.addEventListener('storage', handleStorage);

  //     return () => {
  //         window.removeEventListener('storage', handleStorage);
  //     };
  // }, []);

  const socketCall = async () => {
    if (localStorage.getItem("tria.wallet.store") !== null) {
      const searchParams = new URLSearchParams(location.search)
      socket.emit("loginV2", {
        userId: searchParams.get("userId"),
      })
      setTimeout(() => {
        socket.emit("messageV2", {
          userId: searchParams.get("userId"),
          //@ts-ignore
          message: JSON.parse(localStorage.getItem("tria.wallet.store")),
        })
      }, 1000)
      setTimeout(() => {
        window.close()
      }, 2500)
    } else {
      window.location.replace("https://www.empireofsight.com/login")
    }
  }

  useEffect(() => {
    socketCall()
  }, [])

  const toggleState = () => {
    setContinueWithTria(!continueWithTria)
    setEmailAndSocial(false)
    setConnectWallet(false)
  }
  const toggleState2 = () => {
    setContinueWithTria(false)
    setEmailAndSocial(!emailAndSocial)
    setConnectWallet(false)
  }
  const toggleState3 = () => {
    setContinueWithTria(false)
    setEmailAndSocial(false)
    setConnectWallet(!connectWallet)
  }

  return (
    // <div style={{ fontFamily: 'Montserrat' }} className="w-[448px] bg-white rounded-2xl h-[840px] p-4 flex-col justify-between inline-flex">
    //   <div style={{ marginLeft: '-150px' }} className="absolute top-0 ">
    //     {' '}
    //     <HomeBackgroundVector />
    //   </div>
    //   <div className="flex-col justify-start gap-2 flex">
    //     <Nav />
    //   </div>
    //   {!continueWithTria && <div className='cursor-pointer' onClick={toggleState}>
    //     <div className="w-[416px] h-[78px] px-5 py-4 rounded-2xl border border-stone-950 border-opacity-5 flex-col justify-center items-center gap-2 inline-flex">
    //       <div className="self-stretch h-[46px] py-3 flex-col justify-center items-start gap-4 flex">
    //         <div className="self-stretch justify-start items-center gap-2 inline-flex">
    //           <div className="text-center text-stone-950 text-opacity-80 text-lg font-medium leading-snug">Continue with Tria</div>
    //           <div className="px-2 py-1 bg-indigo-500 bg-opacity-10 rounded-[22px] justify-center items-center gap-2 flex">
    //             <div className="text-center text-indigo-500 text-opacity-90 text-xs font-medium leading-[14.40px]">private</div>
    //           </div>
    //         </div>
    //       </div>

    //     </div>
    //   </div>}
    //   {continueWithTria && (
    //     <div >
    //       <ContinueWithTria toggleState={toggleState} />
    //     </div>
    //   )}
    //   {!emailAndSocial && <div className='cursor-pointer' onClick={toggleState2} >
    //     <div className="w-[416px] h-[78px] px-5 py-4 rounded-2xl border border-stone-950 border-opacity-5 flex-col justify-center items-center gap-2 inline-flex">
    //       <div className="self-stretch h-[46px] py-3 flex-col justify-center items-center gap-4 flex">
    //         <div className="self-stretch justify-start items-center gap-2 inline-flex">
    //           <div className="text-center text-stone-950 text-opacity-80 text-lg font-medium leading-snug">Email & Social</div>
    //           <div className="h-[22px] px-2 py-1 bg-pink-500 bg-opacity-10 rounded-[22px] justify-center items-center gap-2 flex">
    //             <div className="text-center text-pink-500 text-opacity-90 text-xs font-medium leading-[14.40px]">fast</div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>}
    //   {emailAndSocial && (
    //     <div >
    //       <EmailAndSocial toggleState2={toggleState2} />
    //     </div>
    //   )}
    //   {!connectWallet && <div className='cursor-pointer' onClick={toggleState3}>
    //     <div className="w-[416px] h-[78px] px-5 py-4 rounded-2xl border border-stone-950 border-opacity-5 flex-col justify-center items-center gap-2 inline-flex">
    //       <div className="self-stretch h-[46px] py-3 flex-col justify-start items-center gap-4 flex">
    //         <div className="self-stretch h-[22px] flex-col justify-center items-start gap-2 flex">
    //           <div className="text-center text-stone-950 text-opacity-80 text-lg font-medium leading-snug">Connect a Wallet</div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>}
    //   {connectWallet && (
    //     <div>
    //       <ConnectWallet toggleState3={toggleState3} />
    //     </div>
    //   )}
    //   <div className="self-stretch h-[43px] py-3 rounded-[44px] flex-col justify-start items-start gap-3 flex">
    //     <div className="self-stretch justify-center gap-2 inline-flex">
    //       <div className="w-[19px] h-[19px] relative rounded-[5px]" />
    //       {/* Tria Logo */}
    //       <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    //         <g clip-path="url(#clip0_240_3997)">
    //           <path fill-rule="evenodd" clip-rule="evenodd" d="M19 0H0V19H19V0ZM10.079 5.60869V8.07896L14.1585 6.47031V8.29788C14.1585 8.68991 13.9298 9.04048 13.5854 9.17628L10.079 10.559V8.73509C10.0767 8.34551 9.84853 7.99783 9.5059 7.86274L6.57263 6.70603C6.22823 6.57021 5.99951 6.21964 5.99951 5.82761V4.00003L10.079 5.60869ZM10.079 10.5699V11.0459H10.0848V16.2081H8.404V12.3894L6.57263 11.6672C6.22823 11.5314 5.99951 11.1809 5.99951 10.7888V8.96124L10.079 10.5699Z" fill="#808080" fill-opacity="0.4" />
    //         </g>
    //         <defs>
    //           <clipPath id="clip0_240_3997">
    //             <rect width="19" height="19" rx="5" fill="white" />
    //           </clipPath>
    //         </defs>
    //       </svg>
    //       <div style={{ fontFamily: 'Montserrat' }} className="text-center text-zinc-500 text-opacity-40 text-sm font-semibold leading-[16.80px]">
    //         Powered by Tria
    //       </div>
    //     </div>
    //   </div>
    // </div>
    // </div>
    <>
      {checkLoader === false ? (
        <div className='w-[448px] rounded-2xl drop dark:bg-fontLightColor h-[840px] p-4 flex-col justify-between inline-flex'>
          <div className='absolute top-0 left-0 '>
            {" "}
            <img className='w-3/4' src='/icons/back.svg' />
          </div>
          <div className='flex-col justify-start gap-2 flex'>
            <div>
              <div className='w-[416px]  justify-between items-start inline-flex'>
                <div className='p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-center gap-2 inline-flex'>
                  {/* 
              <div className=" relative" >
                <button onClick={() => { navigate("/") }}> <img src='/icons/close.svg'></img> </button>
              </div> 
              */}
                </div>
                <div className='p-3  rounded-[39px] flex-col justify-center items-center gap-2 inline-flex'>
                  {/* <img src='/icons/Shape.svg'></img> */}
                </div>
              </div>
              <div className='h-[221px] px-5 py-6 flex-col justify-center  gap-4 flex'>
                <div className='self-stretch py-2  justify-center  gap-2 inline-flex'>
                  <div className=' flex-col justify-center  gap-2 inline-flex'>
                    {/* <img className="w-[95px] h-[95px]" src={"https://www.empireofsight.com/assets/images/logo-icon.svg"} /> */}
                    <img
                      className='w-[95px] h-[95px]'
                      src='https://www.empireofsight.com/assets/images/logo-icon.svg'
                    />
                  </div>
                </div>
                <div className='self-stretch h-[46px] py-3 flex-col justify-center items-start gap-4 flex'>
                  <div className='self-stretch justify-center  gap-2 inline-flex'>
                    <div className='text-center text-stone-950 text-opacity-80 text-lg font-medium font-Montserrat leading-snug dark:text-text'>
                      Login to {dappName}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-auto'>
            <EmailAndSocial />
          </div>
          <Footer />
        </div>
      ) : (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Loader />
        </div>
      )}
    </>
  )
}
