import React, { useState, useEffect } from "react";

import {
  FeeController,

  ContractDetails,
} from "@tria-sdk/web";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../Components/Send/Loader";
import Loader1 from "../../Components/Loader";

import { AvatarItem } from "../../types";
import { walletType, baseUrl, walletUrl } from "../../utils/constants";


import FooterButtons from "../../Components/FooterButtons";
import ErrorMessage from "../../Components/Popup/ErrorMessage";
import { WalletController } from '@tria-sdk/web';
import { LiFi, RouteOptions } from '@lifi/sdk'
import { HideBalance } from "../../Components/Loaders";

const routeOptions: RouteOptions = {
  infiniteApproval: true,

  integrator: 'tria',
}

const lifi = new LiFi({
  defaultRouteOptions: routeOptions,
  integrator: 'tria',
})

const initialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)"
}






const fromChainNameTria = {
  // 80001: 'MUMBAI',
  "Polygon": 'POLYGON',
  'Ethereum': 'ETH',
  "Avalanche": 'AVALANCHE',
  "Arbitrum": 'ARBITRUM',
  "BSC": 'BINANCE',
  "Optimism": 'OPTIMISM',
  "Fantom": 'FANTOM',
  "FUSE": 'FUSE',
};

export default function Swap(props: any) {

  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState<any>();
  const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [senderAvatar, setSenderAvatar] = useState<AvatarItem>(initialAvatar);
  const [approveButtonDisable, setApproveButtonDisable] = useState<boolean>(true);
  const [transactionSuccessful, setTransactionSuccessful] = useState<boolean>(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [counter, setCounter] = useState(30);
  const navigate = useNavigate();
  const param = useParams();

  const [fromChainData, setFromChainData] = useState<any>();
  const [fromTokenData, setFromTokenData] = useState<any>();
  const [toTokenData, setToTokenData] = useState<any>();
  const [toChainData, setToChainData] = useState<any>();
  const [amount, setAmount] = useState();
  const [popUpVisible, setPopUpVisible] = useState(false);
  const [routesData, setRoutesData] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [feeLoading, setFeeLoading] = useState<boolean>(true);


  // console.log('routesData?.steps?.[0]?.toolDetails?.name',
  //   routesData?.[0]?.steps?.[0]?.toolDetails?.name, routesData, routesData?.[0]?.steps?.[0])


  // console.log('states of datas',fromChainData,fromTokenData,toTokenData,toChainData)
  const handleRejectClick = () => {
    if ((params as any)?.fromWallet) {
      window.open(`${walletUrl}/swap`, "_self");
    }
  };





  // debugger
  const getRoute = async (fromChainData, fromTokenData, toChainData, toTokenData, fromChainId, amount, toChainId) => {
    try {
      // const {fromChainName, fromChainId, amount, fromTokenAddress,toChainId, toTokenAddress,decimals } = JSON.parse(jsonString);
      const amounts = (BigInt(Math.floor(parseFloat(amount) * (10 **
        (fromTokenData?.decimals))))).toString();
      const wallet = new WalletController({
        baseUrl,
        walletType,
      });

      await wallet.init();
      // setFromChainName(fromChainName);
      console.log("response of get route", fromChainId, amounts, fromTokenData?.tokenAddress, toChainId?.id, toTokenData?.address);
      // debugger;

      const routes = await wallet.getRoutes(
        fromChainId, amounts, fromTokenData?.tokenAddress || "0x0000000000000000000000000000000000000000", toChainData?.id, toTokenData?.address);

      console.log("response of get route", routes);

      setRoutesData(routes);
      setFeeLoading(false);

      setApproveButtonDisable(false);

    } catch (err) {
      console.log(err);
    }
  };






  const setStateParams = async () => {
    try {
      const encodedParams = param.param;
      // console.log("en", encodedParams);
      if (encodedParams) {
        // Decode the string
        const jsonString = atob(encodedParams)
        // const jsonData = JSON.parse(jsonString);
        // console.log("jsonData------>", jsonString)
        const jsonData = JSON.parse(jsonString);
        const { fromChainData, fromTokenData, toChainData, toTokenData, fromChainId, amount, toChainId, fromWallet } = jsonData;


        console.log("jsonData", fromChainData, fromTokenData, toChainData, toTokenData, fromChainId, amount, toChainId);
        setFromChainData(fromChainData);
        setFromTokenData(fromTokenData);
        setToChainData(toChainData);
        setToTokenData(toTokenData);
        setAmount(amount);
        await getRoute(fromChainData, fromTokenData, toChainData, toTokenData, fromChainId, amount, toChainId);

        setParams(jsonData);
        // if (triaName) {
        //   const resp = await getAvatarsByTriaNames([triaName]);
        //   if (Object.keys(resp).length > 0) {
        //     const avatar_details = resp[triaName]?.[0];
        //     setSenderAvatar({ avatar: avatar_details?.avatar, background: avatar_details?.background });
        //   }
        // }



      }
    }
    catch (err) {
      console.log(err);
    }
  };







  const lifiSwap = async () => {
    try {
      setApproveLoading(true);
      // console.log("param", params);
      // console.log("executing swap.....", routesData);
      const wallet = new WalletController({
        baseUrl,
        walletType,
      });
      // console.log('wallet',wallet.memStore.toString());
      await wallet.init();
      // console.log('wallet',wallet.memStore.getState());
      clearInterval(intervalId as any);
      // const ChainName = fromChainNameTria[params?.fromChainData?.name]
      const signer = await wallet.getSigner((fromChainData as any)?.chainName);
      console.log('signer', routesData, (fromChainData as any)?.name, signer)
      if (routesData) {
        const sign = signer.signer
        const swapRes = await lifi.executeRoute(
          sign,
          routesData[selectedIndex],

        );

        console.log("swap execution  res-------------->", swapRes);

        if (true) {
          setTransactionSuccessful(true);
          if ((params as any)?.fromWallet) {
            // if (true) {
            setTimeout(() => {
              const walletParams = encodeURIComponent(
                JSON.stringify({
                  swapRes
                })
              );
              console.log(
                "redirecting to---->",
                `walletUrl/activities/${walletParams}`
              );
              // window.location.href = `${walletUrl}/activities/${walletParams}`;
              window.open(`${walletUrl}/activities/${walletParams}`, "_self");
            }, 2000);
          }
        }

      }
    } catch (error: any) {
      setError(error?.message || "");
      console.log("error", error);
      setApproveLoading(false);
    }
  };








  useEffect(() => {
    // Initial call
    setStateParams();
    const interval_Id = setInterval(() => {
      setStateParams();
    }, 30000);
    setIntervalId(interval_Id as any);
    return () => {
      clearInterval(interval_Id);
    };
  }, []);









  return (
    <>
      {approveLoading ? (
        <div>
          <div>
            <Loader
              heading={"Sending..."}
              dappLogo={params?.dappLogo}
              transactionSuccessful={transactionSuccessful}
            />
          </div>
        </div>
      ) : (
        <div className="w-full h-screen rounded-2xl  bg-white dark:bg-stone-950 p-[1.92vh] flex-col justify-between items-center inline-flex">
          <div className="flex-col w-full  justify-start items-center flex">
            <div className="self-stretch h-[8.64vh] rounded-2xl  flex-col justify-center items-center gap-[.96vh] flex">
              <div className="self-stretch h-[8.64vh] flex-col justify-center items-center gap-[.96vh] flex">
                <div className="self-stretch h-[8.64vh] px-[2.4vh] py-[1.44vh] rounded-[21px] flex-col justify-center items-center gap-[.96vh] flex">
                  <div className="self-stretch justify-start items-center gap-[1.92vh] inline-flex">
                    <div className="w-[2.16vh] h-[2.16vh] relative">
                      <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute ">
                        <img src="/icons/arrow-right2.svg" className="dark:visible invisible w-0 dark:w-[22px] rotate-180" />
                        <img src="/icons/arrow-down.svg" className="dark:invisible visible dark:w-0 rotate-90" />
                      </div>
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.96vh] inline-flex">
                      <div className="self-stretch justify-start items-center gap-[.96vh] inline-flex">
                        <div className="text-center dark:text-neutral-50 text-fontLightColor text-base md:text-xl font-semibold  leading-normal font-montserrat">Convert</div>
                        <div className="text-center dark:text-zinc-500 text-fontPrimaryColor text-opacity-20 md:text-base text-xs font-semibold  leading-tight font-montserrat">(Swap)</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!popUpVisible && <div className="self-stretch w-full h-[59vh] py-[.96vh] flex-col justify-start  gap-[.96vh] flex">
              <div className="w-full  py-[.96vh] flex-col justify-start items-cente gap-[.96vh] inline-flex">
                <div className="h-[46.8vh] py-[1.44vh] flex-col justify-end items-center gap-[4.8vh] flex">
                  <div className="self-stretch h-[43.9vh] flex-col justify-start items-center gap-[1.92vh] flex">
                    <div className="self-stretch h-[21vh] flex-col justify-start items-start gap-[1.44vh] flex">
                      <div className="px-[1.44vh] justify-start items-start gap-[.96vh] inline-flex">
                        <div className="text-center dark:text-zinc-500 text-fontPrimaryColor text-opacity-20 md:text-base text-sm  font-semibold  leading-tight font-montserrat">You pay</div>
                      </div>
                      <div className="self-stretch h-[17.28vh] px-[2.88vh] pb-[1.44vh]  dark:bg-zinc-900 bg-fontColorNftCardDark rounded-3xl flex-col justify-center items-center gap-[.96vh] flex">
                        <div className="self-stretch justify-start items-center gap-[1.92vh] inline-flex">
                          <div className="grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[1.44vh] flex">
                            <div className="w-[4.8vh] h-[4.8vh] relative  rounded-[40px] shadow overflow-hidden">
                              <img src=
                                {fromTokenData?.logoUrl}  className={`${
                                  fromTokenData?.name.toUpperCase() === 'FUSE'
                                    ? 'scale-[124%]'
                                    : ''
                                } `}></img>
                              <div className="w-[3.6vh] h-[3.6vh] left-[.6vh] top-[.61vh] absolute" />
                              {/* <div className="left-[3.12vh] top-[3vh] absolute justify-start items-center gap-[2.71vh] inline-flex">
                                <div className="w-[2.4vh] h-[2.4vh] relative  border-white backdrop-blur-[2.50px]">
                                  <div className="w-[1.8vh] h-[1.8vh] left-[.3vh] top-[.32vh] absolute" />
                                  <img src={
                                    fromChainData?.logo} className=" rounded border" />
                                </div>
                              </div> */}
                            </div>



                            <div className="left-[7.9vh] top-[27vh] absolute justify-start items-center gap-[2.71vh] inline-flex">
                                <div className="w-[2.4vh] h-[2.4vh] relative  border-whit backdrop-blur-[2.50px">
                                  <div className="w-[1.8vh] h-[1.8vh] left-[.3vh] top-[.32vh] absolute" />
                                  <img src={
                                    fromChainData?.logo} className=" rounded border" />
                                </div>
                              </div>
                            <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                              <div className="self-stretch justify-start items-center gap-[.48vh] inline-flex">
                                <div className="dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold  leading-tight font-montserrat">{
                                  fromTokenData?.symbol}</div>
                              </div>
                              <div className="text-zinc-500 md:text-sm text-xs  font-semibold  leading-[16.80px] font-montserrat uppercase">{
                                (fromChainData as any)?.name}</div>
                            </div>
                          </div>
                          {feeLoading ? (
                            <HideBalance />
                          ) : (
                            <div className="h-[1.92vh py-[1.44vh] flex-col justify-end items-end gap-[1.44vh] inline-flex">
                              <div className="self-stretch justify-end items-center gap-[.96vh] inline-flex">
                                <div className="justify-end items-baseline gap-[.38vh] flex">
                                  <div className="justify-end items-baseline flex">

                                    <div className="text-center dark:text-neutral-50 text-fontPrimaryColor md:text-2xl text-xl font-bold  leading-[57.60px]">{
                                      (Number(routesData?.[selectedIndex]?.fromAmount) / 10 ** (routesData?.[selectedIndex]?.fromToken?.decimals))?.toFixed(6)}</div>

                                  </div>
                                </div>
                              </div>
                              <div className="self-stretch justify-end items-start gap-[.48vh] inline-flex">
                                <div className="text-center dark:text-neutral-500 text-fontPrimaryColor text-opacity-40 md:text-lg text-sm font-semibold  leading-snug">${
                                  routesData?.[selectedIndex]?.fromAmountUSD}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch h-[21vh] flex-col justify-start items-start gap-[1.44vh] flex">
                      <div className="px-[1.44vh] justify-start items-start gap-[.96vh] inline-flex">
                        <div className="text-center dark:text-neutral-500 text-fontPrimaryColor text-opacity-20 md:text-base text-sm font-semibold  leading-tight font-montserrat">You get</div>
                      </div>
                      <div className="self-stretch w-full h-[17.28vh] px-[2.88vh] pb-[1.44vh] dark:bg-zinc-900 bg-fontColorNftCardDark rounded-3xl flex-col justify-center items-center gap-[.96vh] flex">
                        <div className="self-stretch justify-start items-center gap-[1.92vh] inline-flex">
                          <div className="grow shrink basis-0 self-stretch justify-start items-center gap-[1.92vh] flex">
                            <div className="grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[1.44vh] flex">
                              <div className="w-[4.8vh] h-[4.8vh] relative">
                                <img className="w-[4.8vh] h-[4.8vh] left-0 top-0 absolute rounded-[40px]" src=
                                  {toTokenData?.logoURI} />
                                <div className="w-[2.4vh] h-[2.4vh] left-[3.36vh] top-[3.36vh] absolute  border-white backdrop-blur-[2.3vh">
                                  <img className="w-[2.3vh] h-[2.3vh] left-[-0.23px] top-[-0.22px] absolute  rounded border" src={
                                    toChainData?.logoURI} />
                                </div>
                              </div>
                              <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                                <div className="self-stretch justify-start items-center gap-[.48vh] inline-flex">
                                  <div className="dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold  leading-tight font-montserrat">{
                                    toTokenData?.symbol}</div>
                                </div>
                                <div className="text-zinc-500 md:text-sm text-xs font-semibold  leading-[16.80px] uppercase font-montserrat">{
                                  toChainData?.name}</div>
                              </div>
                            </div>
                          </div>
                          {feeLoading ? (
                            <HideBalance />
                          ) : (
                            <div className="h-[1.92vh py-[1.44vh] flex-col justify-end items-end gap-[1.44vh] inline-flex">
                              <div className="self-stretch justify-end items-center gap-[.96vh] inline-flex">
                                <div className="justify-end items-baseline gap-[.38vh] flex">
                                  <div className="justify-end items-baseline flex">
                                    <div className="text-center dark:text-neutral-50 text-fontPrimaryColor md:text-2xl text-xl font-bold  leading-[57.60px]">{
                                      (Number(routesData?.[selectedIndex]?.toAmount) / 10 ** (routesData?.[selectedIndex]?.toToken?.decimals))?.toFixed(6)}  </div>
                                    {/* <div className="justify-center items-baseline flex">
                                    <div className="text-center text-zinc-500 text-2xl font-semibold leading-[28.80px]">.</div>
                                    <div className="text-center text-zinc-500 text-2xl font-medium  leading-[28.80px]">20</div>
                                  </div> */}
                                  </div>
                                </div>
                              </div>
                              <div className="self-stretch justify-end items-start gap-[.48vh] inline-flex">
                                <div className="text-center dark:text-neutral-500 text-fontPrimaryColor text-opacity-40 md:text-lg text-sm font-semibold font-montserrat leading-snug">${
                                  routesData?.[selectedIndex]?.toAmountUSD}</div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="self-stretch h-[21.6vh] px-[2.4vh] py-[1.92vh] rounded-2xl flex-col justify-center items-center gap-[.96vh] flex">
                  <div className="self-stretch py-[1.44vh] justify-start items-start gap-[1.92vh] inline-flex">
                    <div className="grow shrink basis-0 h-[2.64vh] justify-start items-center gap-[.96vh] flex">
                      <div className="mix-blend-difference text-center text-white text-opacity-90 md:text-lg text-sm font-semibold  leading-snug whitespace-nowrap font-montserrat">Convert via</div>
                    </div>
                    <div className="grow shrink basis-0 flex-col justify-center items-end gap-[.48vh] inline-flex">
                      <div className="justify-start items-center gap-[.96vh] inline-flex">
                        <img className="w-[1.76vh] h-[1.76vh]" src={
                          routesData?.[selectedIndex]?.steps?.[0]?.toolDetails?.logoURI} />
                        <div className="text-center dark:text-neutral-50 text-fontPrimaryColor text-opacity-80 md:text-lg text-sm font-medium  leading-snug font-montserrat">{
                          routesData?.[selectedIndex]?.steps?.[0]?.toolDetails?.name}</div>
                        <div className="w-[2.16vh] h-[2.16vh] relative" >
                          <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute cursor-pointer" onClick={() => { setPopUpVisible(!popUpVisible); setOverlayVisible(!overlayVisible) }}>
                            <img src="/icons/edit-dark.svg" className="dark:visible invisible w-0 dark:w-[2.16vh]" />
                            <img src="/icons/edit-2.svg" className="dark:invisible visible dark:w-0" />
                          </div>
                        </div>
                      </div>
                      <div className="text-center dark:text-neutral-500 text-fontPrimaryColor text-opacity-40 md:text-sm text-xs font-normal  leading-[16.80px] whitespace-nowrap font-montserrat">Best price out of {
                        routesData?.length} sources</div>
                    </div>
                  </div>
                  <div className="self-stretch py-[1.44vh] justify-start items-start gap-[1.92vh] inline-flex">
                    <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.96vh] inline-flex">
                      <div className="self-stretch justify-start items-center gap-[.96vh] inline-flex">
                        <div className="mix-blend-difference text-center text-white text-opacity-90 md:text-lg text-sm font-semibold  leading-snug font-montserrat">Network Fee</div>
                        <div className="w-[2.16vh] h-[2.16vh] relative">
                          <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute">
                            <img src="/icons/info-circle-dark.svg" className="dark:visible invisible w-0 dark:w-[2.16vh]" />
                            <img src="/icons/Vector.svg" className="dark:invisible visible dark:w-0" />
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch justify-start items-center gap-[.96vh] inline-flex">
                        <div className="mix-blend-difference text-center text-white text-opacity-40 text-xs  font-medium  leading-[1.7vh] font-montserrat">Refreshes in: 30</div>
                      </div>
                    </div>
                    {feeLoading ? (
                      <HideBalance />
                    ) : (
                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.96vh] inline-flex">
                        <div className="self-stretch justify-end items-center gap-[.96vh] inline-flex">
                          <div className="text-center dark:text-neutral-500 text-fontPrimaryColor text-opacity-60 md:text-lg text-sm font-normal font-montserrat leading-snug">${
                            routesData?.[selectedIndex]?.gasCostUSD}</div>
                        </div>
                        {/* <div className="self-stretch justify-end items-center gap-[.96vh] inline-flex">
                        <div className="text-center text-white text-opacity-60 text-sm font-normal  leading-[16.80px]">0 MATIC</div>
                      </div> */}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>}


            {overlayVisible &&
              <div
                style={{
                  position: 'relative',
                  // top: "20%",
                  // left: 0,
                  width: '100%',
                  // height: '50%',
                  // bottom: '185%',
                  // right: '2%',
                  zIndex: 1001, // Set a higher zIndex to ensure it appears above the blurred overlay
                  // background: "white",
                }}

              >
                {popUpVisible &&
                  <div className=" top-[50%] relative w-full  rounded-[20px] shadow -2 -zinc-500 -opacity-60">
                    <div className="h-[55.7vh] w-full left-0 top-[8vh] rounded-[2.4vh] dark:bg-stone-95  bg-whit  absolute flex-col justify-end items-center gap-[.96vh] inline-flex">
                      <div className="self-stretch h-[8.16vh] flex-col justify-end items-center flex">
                        <div className="self-stretch h-[8.16vh] pt-3 pb-1 rounded-tl-[20px] rounded-tr-[20px]  flex-col justify-center items-center gap-[.96vh] flex">
                          <div className="self-stretch px-[2.4vh] py-[1.44vh] rounded-lg justify-around items-center gap-[.96vh] inline-flex">
                            <div className="grow shrink basis-0 h-[2.28vh] justify-between items-center flex">
                              <div className="grow shrink basis-0 h-[2.28vh] justify-start items-centergap-[1.44vh] flex">
                                <div className="flex-col justify-start items-start gap-[.48vh] inline-flex">
                                  <div className="self-stretch justify-start items-center gap-[.96vh] inline-flex">
                                    <div className="text-center dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm  font-semibold  leading-tight font-montserrat">Select provider</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="w-[3.36vh] h-[3.36vh] relative">
                              <div className="w-[3.36vh] h-[3.36vh] left-[0.01px] top-0 absolute cursor-pointer" onClick={() => { setPopUpVisible(!popUpVisible); setOverlayVisible(!overlayVisible) }}>
                                <img src="/icons/close-circle-dark.svg" className="dark:visible invisible w-0 dark:w-[3.36vh]" />
                                <img src="/icons/close-circle2.svg" className="dark:invisible visible dark:w-0" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="self-stretch h-[46.6vh] px-[1.44vh] flex-col justify-center items-centergap-[1.44vh] flex ">
                        <ul className="overflow-y-auto ">
                          {
                            routesData?.length > 0 && routesData.map((item, index) => (<li className="mb-3 w-full self-stretch" key={index} onClick={() => { setSelectedIndex(index); setPopUpVisible(!popUpVisible); setOverlayVisible(!overlayVisible) }}><div className="cursor-pointer self-stretch h-[17.28vh] pr-[2.4vh] py-[1.92vh] dark:bg-zinc-900 bg-routesBgColor rounded-[20px] -2 -neutral-800 flex-col justify-center items-center gap-[2.88vh] flex">
                              <div className="self-stretch px-[.96vh] py-[1.44vh] justify-start items-start gap-[.96vh] inline-flex">
                                <div className="grow shrink basis-0 h-[6.24vh] justify-start items-start gap-[.96vh] flex">
                                  <div className="w-[3.84vh] h-[3.84vh] relative">
                                    <img className="w-[3.84vh] h-[3.84vh] left-0 top-0 absolute rounded-[32px]" src={
                                      toTokenData?.logoURI} />
                                    <div className="w-[1.7vh] h-[1.7vh] left-[2.4vh] top-[2.4vh] absolute    -white backdrop-blur-[1.80px">
                                      <img className="w-[1.7vh] h-[1.7vh] left-[-2.16vh top-[-2.16vh absolute rounded border" src={
                                        toChainData?.logoURI} />
                                    </div>
                                  </div>
                                  <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-[.48vh] inline-flex">
                                    <div className="px-[.96vh] justify-start items-center inline-flex">
                                      <div className="text-center dark:text-neutral-50 text-fontPrimaryColor md:text-xl text-base font-semibold  leading-[28.80px] tracking-wide  whitespace-nowrap">
                                        {(Number(item?.toAmount) / 10 ** (item?.toToken?.decimals))?.toFixed(5)}                                { 
                                          toTokenData?.symbol}</div>
                                    </div>
                                    <div className="px-[.96vh] justify-start items-center inline-flex">
                                      <div className="dark:text-neutral-500 text-fontPrimaryColor text-opacity-40 md:text-base text-sm font-medium  leading-tight tracking-tight">≈${item?.toAmountUSD}</div>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-col justify-start items-endgap-[1.44vh] inline-flex">
                                  {index === 0 && (
                                    <div className="px-[1.44vh] py-[.48vh] rounded-[48px] -zinc-500 -opacity-10 justify-center border dark:border-zinc-500 items-center gap-[.96vh] inline-flex">
                                      <div className="text-center dark:text-neutral-500 text-fontPrimaryColor text-opacity-20 text-xs  font-semibold  leading-[1.7vh]">Recommended</div>
                                    </div>
                                  )}
                                  <div className="justify-start items-center gap-[.96vh] inline-flex">
                                    <img className="w-[1.76vh] h-[1.76vh]" src={
                                      item?.steps?.[0]?.toolDetails?.logoURI} />
                                    <div className="text-center dark:text-white text-fontPrimaryColor text-opacity-80 md:text-base text-sm font-medium  leading-tight">{
                                      item?.steps?.[0]?.toolDetails?.name}</div>
                                  </div>
                                  <div className="justify-start items-center gap-[.96vh] inline-flex">
                                    <div className="justify-start items-center gap-[.96vh] flex">
                                      <div className="w-[2.16vh] h-[2.16vh] relative">
                                        <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute">
                                          <img src="/icons/clock.svg" className="dark:visible invisible w-0 dark:w-[22px] " />
                                          <img src="/icons/clock.svg" className="dark:invisible visible dark:w-0 " />
                                        </div>
                                      </div>
                                      <div className="text-center text-neutral-600 text-sm font-medium  leading-[16.80px] whitespace-nowrap">{Math.ceil(
                                        
                                        routesData?.[selectedIndex]?.steps
                                          .map((step) => step.estimate.executionDuration)
                                          .reduce((duration, x) => duration + x, 0) / 60,
                                      )} m</div>
                                    </div>
                                    <div className="justify-start items-center gap-[.96vh] flex">
                                      <div className="w-[2.16vh] h-[2.16vh] relative">
                                        <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute">
                                          <img src="/icons/gas-station.svg" className="dark:visible invisible w-0 dark:w-[22px] " />
                                          <img src="/icons/gas-station.svg" className="dark:invisible visible dark:w-0 " />
                                        </div>
                                      </div>
                                      <div className="text-center text-neutral-600 text-sm font-medium  leading-[16.80px]">${item?.gasCostUSD}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div> </li>))}

                             </ul>
                               </div> */}


                      <div className="self-stretch h-[46.6vh] px-[1.44vh] flex-col justify-cente items-centergap-[1.44vh] flex ">
                        <ul className="overflow-y-auto ">
                          {
                            routesData?.length > 0 && routesData.map((item, index) => (<li className="mb-[1.44vh] cursor-pointer w-full self-stretch" key={index} onClick={() => { setSelectedIndex(index); setPopUpVisible(!popUpVisible); setOverlayVisible(!overlayVisible) }}>
                              <div className={`w-full h-[18.16vh] px-[2.4vh] py-[1.44vh] bg-fontColorNftCardDark  dark:bg-hoverSwap2 hover:bg-hoverSwapLight dark:hover:bg-hoverSwap rounded-[20px] ${index === selectedIndex ? 'border-2 border-routesBorder dark:border-routesBorderDark' : ''}
 border-neutral-800 flex-col justify-cente items-center gap-[2.88vh] inline-flex`}>

                                <div className="self-stretch h-[14.28vh] p-[.96vh] flex-col justify-start items-start gap-[2.88vh flex">
                                  <div className="self-stretch justify-start items-start gap-[.96vh] inline-flex">
                                    <div className="w-[3.84vh] h-[3.84vh] relative">
                                      <img className="w-[3.84vh] h-[3.84vh] left-0 top-0 absolute rounded-[32px]" src={toTokenData?.logoURI} />
                                      <div className="w-[1.73vh] h-[1.73vh] left-[2.4vh] top-[2.41vh] absolute  rounded-[2.88px]  border-white backdrop-blur-[1.80px]">
                                        <img className="w-[1.73vh] h-[1.73vh] left-[-0.18px] border rounded top-[-0.18px] absolute" src={toChainData?.logoURI} />
                                      </div>
                                    </div>
                                    <div className="self-stretch flex-col justify-center items-start gap-[1.44vh] inline-flex">
                                      <div className="flex-col justify-center items-start gap-[.48vh flex">
                                        <div className="px-[.96vh] justify-start items-center inline-flex">
                                          <div className="text-center dark:text-neutral-50 text-fontPrimaryColor  md:text-xl text-base font-semibold font-montserrat  tracking-wide whitespace-nowrap ">{(Number(item?.toAmount) / 10 ** (item?.toToken?.decimals))?.toFixed(5)}                                {
                                            toTokenData?.symbol}</div>
                                        </div>
                                        <div className="px-[.96vh] justify-start items-center gap-[.48vh] inline-flex">
                                          <div className="dark:text-neutral-500 text-fontPrimaryColor text-opacity-60 md:text-base text-sm font-medium font-montserrat leading-tight tracking-tight">≈${item?.toAmountUSD}</div>
                                          <div className=" text-neutral-500 text-opacity-60 text-3xl font-medium font-montserrat items-center flex  tracking-wide">•</div>
                                          <div className="justify-start items-center gap-[.48vh] flex">
                                            <img className="w-[15.01px] h-[15px]" src={
                                              item?.steps?.[0]?.toolDetails?.logoURI} />
                                            <div className="text-center dark:text-neutral-500 text-fontPrimaryColor text-opacity-60 md:text-sm text-xs font-medium font-montserrat">{
                                              item?.steps?.[0]?.toolDetails?.name}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="self-stretch h-[2.64vh] justify-between items-center inline-flex">

                                    <div className="pr-[1.44vh] py-[.48vh] rounded-[48px] -zinc-500 -opacity-10 justify-center  dark:border-zinc-500 items-center gap-[.96vh] inline-flex">
                                      {index === 0 && (<div className="text-center dark:text-neutral-500 text-fontLightColor text-opacity-60 dark:border-zinc-500 border rounded-[48px] px-[1.44vh] py-[.48vh]  text-[1.6vh] font-montserrat font-semibold  leading-[1.7vh]">Recommended</div>
                                      )}</div>

                                    <div className="justify-start items-center gap-[1.92vh] flex">
                                      <div className="justify-start items-center gap-[.96vh] flex">
                                        <div className="w-[2.16vh] h-[2.16vh] relative">
                                          <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute">
                                            <img src="/icons/gas-station.svg" className="dark:visible invisible w-0 dark:w-[22px] " />
                                            <img src="/icons/gas-station-light.svg" className="dark:invisible visible dark:w-0 " />
                                          </div>
                                        </div>
                                        <div className="text-center dark:text-neutral-300 text-fontLightColor text-opacity-60 md:text-sm text-xs font-medium font-montserrat ">${item?.gasCostUSD}</div>
                                      </div>
                                      <div className="justify-start items-center gap-[.96vh] flex">
                                        <div className="w-[2.16vh] h-[2.16vh] relative">
                                          <div className="w-[2.16vh] h-[2.16vh] left-0 top-0 absolute">
                                            <img src="/icons/clock.svg" className="dark:visible invisible w-0 dark:w-[22px] " />
                                            <img src="/icons/clock-light.svg" className="dark:invisible visible dark:w-0 " />
                                          </div>
                                        </div>
                                        <div className="text-center dark:text-neutral-300 text-fontLightColor text-opacity-60 md:text-sm text-xs font-medium font-montserrat whitespace-nowrap">{Math.ceil(

                                          routesData?.[selectedIndex]?.steps
                                            .map((step) => step.estimate.executionDuration)
                                            .reduce((duration, x) => duration + x, 0) / 60,
                                        )} m</div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </li>))}

                        </ul>

                      </div>
                    </div>
                  </div>
                }
              </div>}



          </div>
          <div className="mt-[18%] w-full whitespace-nowrap">
            {error && <ErrorMessage error={error} />}
          </div>
          <div className="self-stretch h-[12.6vh] flex-col justify-center items-center gap-[.96vh] flex">

            <FooterButtons
              handleReject={handleRejectClick}
              approveButtonDisable={approveButtonDisable}
              callFunction={lifiSwap}
            />
            {overlayVisible && (
              <div
                className="fixed top-0 left-0 w-full h-full dark:bg-hoverSwa bg-bgOfOverla bg-opacity-45 backdrop-blur-sm -webkit-backdrop-blur-md z-50"

              />
            )}
          </div>
        </div>

      )}

    </>
  );
}