import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import React from 'react';
/* Types */
import type { PropsWithChildren } from 'react';

/* Data Things */
import { UserController } from '@tria-sdk/core';
import axios from 'axios';

 interface GetAssetDetailsResponse {
  success: boolean
  data?: {
    balanceInTokens: number
    balanceInUSD: number
    balanceOfTokensInUnits: string
    chainLogo: string
    chainName: string
    decimals: number
    isFavorite: boolean
    isNativeToken: boolean
    isSpam: boolean
    logoUrl: string
    name: string
    percentChangeIn24hr: number
    quoteRate: number
    symbol: string
    tokenAddress: string
  }
  message?: string
}

interface NftsItemDetails{
  amount: number;
  nftInfo: {
    contract_address: string;
    token_id: string;
    interface: string;
    market_data: {
      prices: {
        floor: number;
      };
    };
    external_links: {
      type: string;
      name: string;
      url: string;
    }[];
    name: string;
    tags: string[];
    content: {
      preview: {
        url: string;
      };
      detail: {
        url: string;
      };
      video: {
        url: string;
      };
    };
    attributes: {
      key: string;
      value: string;
    }[];
  };
  collectionInfo: {
    name: string;
    description: string;
    content: {
      icon: {
        url: string;
      };
      banner: {
        url: string;
      };
    };
  };
  chainName: string;
  chainLogo: string;
  isFavorite: boolean;
  contractExplorerUrl: string;
  blurUrl: string;
  openseaUrl: string;
}

interface TriaUserContext {
  getAssetDetails(
    chainName: string,
    tokenAddress?: string,
    triaName?: string
  ): Promise<Required<GetAssetDetailsResponse>['data']>;

  getUserByAddress(address: string,
    chainName: string):any

  getAvatarsByTriaNames(triaNames: [string],):any
  getNFTDetails(
    chainName: string,
    tokenAddress: string, 
    tokenId: string,
    triaName?: string
  ):Promise<NftsItemDetails>;
}

const initialValue = {
  getAssetDetails: () => Promise.reject(null),
  getUserByAddress:()=>Promise.reject(null),
  getAvatarsByTriaNames:()=>Promise.reject(null),
  getNFTDetails:()=>Promise.reject(null)
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
const TriaUserContext = createContext<TriaUserContext>(initialValue);

// This hook can be used to access the user info.
export const useTriaUser = () => useContext(TriaUserContext);
const SDK_BASE_URL = process.env.REACT_APP_BASE_URL
const userController = new UserController(SDK_BASE_URL ?? '' );
console.log('co', userController);
export function UserProvider(props: PropsWithChildren) {
  // const router = useTriaRouter()

  const getAssetDetails = useCallback(async (chainName: string, tokenAddress?: string,  triaName?: string) => {
    try {
      console.log("userController",userController);
      const response = (await userController.getAssetDetails(
        chainName,
        tokenAddress, 
        triaName
      )) as GetAssetDetailsResponse;

      if (!response.success || !response.data) {
        throw new Error(response.message);
      }
console.log("resp--------->",response);
      return response.data;
    } catch (error) {
      console.error(error);
      const { message } = error as Error;
      return Promise.reject(String(message || error));
    }
  }, []);

  const getUserByAddress = useCallback(
    async (address: string,
         chainName: string) => {
      try {
        const response = (await (userController.getUserByAddress as any)(
          address,
          chainName
        ));
  
        if (!response.success) {
          throw new Error((response as unknown as Error).message);
        }
      console.log("resp------------>",response.triaName);
        return response.triaName;
      } catch (error) {
        console.error(error);
        const { message } = error as Error;
        return Promise.reject(String(message || error));
      }
    },
    []
  );

  
  const getAvatarsByTriaNames = async (triaNames) => {
    try {
      const response = await axios.get(`${SDK_BASE_URL}/api/v1/user/getAvatarByTriaName`, {
        params: { triaNames: triaNames.join(',') },
      });
  
      return response.data?.response;
    } catch (error) {
      console.error('Error fetching avatar data:', error);
      throw error; // Rethrow the error to be handled by the calling code
    }
  };

  const getNFTDetails = useCallback(
    async (
      chainName: string,
      tokenAddress: string, 
      tokenId: string,
      triaName?: string
    ): Promise<NftsItemDetails> => {

      try {
        const response = await userController.getNFTDetails(
          chainName,
          tokenAddress,
          tokenId,
          triaName,
        );
        if (!response.success) {
          throw new Error((response as unknown as Error).message);
        }
        
        console.log(response);
        return response.data;
      } catch (error) {
        console.error(error);
        const { message } = error as Error;
        return Promise.reject(String(message || error));
      }
    },
    []
  );

  const value = useMemo(
    () => ({
      getAssetDetails,
      getUserByAddress,
      getAvatarsByTriaNames,
      getNFTDetails
    }),
    []
  );

  return <TriaUserContext.Provider value={value}>{props.children}</TriaUserContext.Provider>;
}
