import { AuthController } from "@tria-sdk/core";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import type { PropsWithChildren } from "react";

interface OTPVerificationParams {
  input: string;
  code: string;
  session:string;
  isPasswordLess: boolean;
  verificationType: string;
  fromClientId:string;
}

interface initiateCognitoAuth{
  input: string;
  password: string | undefined;
  origin?: string;
  verificationType: string;
  isPasswordLess: Boolean;
  fromClientId:string;
}
interface TriaAuthContext {
  initiateOTP(input: initiateCognitoAuth): Promise<any>;
  verifyOTP(params: OTPVerificationParams): Promise<any>;
}

const initialValue = {
  initiateOTP: () => Promise.reject(null),
  verifyOTP: () => Promise.reject(null),
};

const TriaAuthContext = createContext<TriaAuthContext>(initialValue);

export const useTriaAuth = () => useContext(TriaAuthContext);

const baseUrl = process.env.REACT_APP_BASE_URL || "";
const authContoller = new AuthController(baseUrl);

export function AuthProvider(props: PropsWithChildren) {
  const initiateOTP = useCallback(async (input: initiateCognitoAuth) => {
    try {
      console.log("input",input);
      const response = await (authContoller.initiateCognitoAuth as any)(input);

      return response;
    } catch (error) {
      console.error(error);
      const { message } = error as Error;
      return Promise.reject(String(message || error));
    }
  }, []);


  const verifyOTP = useCallback(async (params: OTPVerificationParams) => {
    try {
      console.log("paramss",params);
      const response = await (authContoller.verifyCognitoAuth as any)(params);

      return response;
    } catch (error) {
      console.error(error);
      const { message } = error as Error;
      return Promise.reject(String(message || error));
    }
  }, []);

  const value=useMemo(()=>({
    initiateOTP,
    verifyOTP
  }),[]
  );
  return <TriaAuthContext.Provider value={value}>{props.children}</TriaAuthContext.Provider>
}
