import React from 'react';

export default function TransactionSuccess() {
  return (
    <div className='flex justify-center ml-0'>
      <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 194 193" fill="none">
        <g clip-path="url(#clip0_1372_26390)">
          <mask id="mask0_1372_26390" maskUnits="userSpaceOnUse" x="1" y="0" width="193" height="193">
            <path d="M1 0H193.747V192.747H1V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1372_26390)">
            <path d="M96.9973 27.967C134.017 27.967 164.075 58.0244 164.075 95.0444C164.075 132.064 134.017 162.122 96.9973 162.122C59.9773 162.122 29.9199 132.064 29.9199 95.0444C29.9199 58.0244 59.9773 27.967 96.9973 27.967Z" fill="#8561F2" />
            <path d="M66.3154 98.0674L85.703 117.079L124.855 76.6093" stroke="white" stroke-width="10" stroke-linecap="round" stroke-linejoin="round" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1372_26390">
            <rect width="192.8" height="192.8" fill="white" transform="translate(0.599609 0.0999756)" />
          </clipPath>
        </defs>
      </svg>
    </div>

  )
}