import React, { useState, useEffect, useContext } from 'react'
import Connect from '../../Components/SignMessage/Connect';
import Sign from "../../Components/SignMessage/Sign";
import { WalletController } from '@tria-sdk/web';
import { useParams } from 'react-router-dom'
import { useTriaUser } from "../../contexts/tria-user-provider";
import { getDataFromLocalStorage } from "../../utils";
import {walletType,baseUrl} from "../../utils/constants";
import {AssetDetails,AvatarItem,signMessageParams,EncryptCalldataParams,dappDetails,ENV,encryptParams, postMessageResponse} from "../../types";
import Navbar from "../../Components/Navbar";
import DappDetails from '../../Components/DappDetails';
import Loader from '../../Components/SignMessage/Loader';
import ErrorMessage from '../../Components/Popup/ErrorMessage';
import Footer from '../../Components/Footer';

const initialParams: encryptParams = {
  chainName: '',
  data: '',
  version: '',
  environment: "mainnet",
  dappDomain: '',
  dappLogo: '',

}



const initialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)"
}




function Encrypt() {
  const [loading, setLoading] = useState(true);
  const [connect, setConnect] = useState(false);
  const [params, setParams] = useState<encryptParams>(initialParams);
  const { getAssetDetails, getAvatarsByTriaNames } = useTriaUser();
  const [tokenDetails, setTokenDetails] = useState<AssetDetails>();
  const param = useParams();
  const [senderAvatar, setSenderAvatar] = useState<AvatarItem>(initialAvatar);
  const [triaName, setTriaName] = useState<string>("");
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");


  const sendMessageToParent = (data:postMessageResponse) => {
    // Post a message to the parent window
    console.log("event emitted", data);
    window.parent.postMessage({ type: 'closeIframe', callFrom: 'encrypt', data: data }, '*');
  };




  console.log(btoa(JSON.stringify({
    chainName: "POLYGON",
    data: 'hello my name is lalit',
    version: 'x25519-xsalsa20-poly1305',
    environment: "mainnet",
    dappDomain: "https://facebook.com"
  })));


  const encrypt = async () => {
    try {
      const wallet = new WalletController({
        baseUrl,
        walletType,
        selectedChainName: params?.chainName,
        environment: params?.environment,
      });
      await wallet.init();
      console.log("wallet", wallet);
      const res = await wallet.encrypt(params?.data);
      console.log("Encrypted response", { res });
      if (res?.success) {
        setApproveLoading(true);
        setTimeout(() => {
          sendMessageToParent(res);
        }, 2000);
      } else {
        setError(res?.message || "");
        setApproveLoading(false);
      }
    } catch (err) {
      console.log("err", err);
      setError("'Encryption failed. Please check your input.");
      setApproveLoading(false);
    }
  };

  const setStateParams = async () => {
    try {
      //@ts-ignore
      if (param?.param) {
        //@ts-ignore
        const encodedParams = param.param;
        console.log("encodedParams", typeof (encodedParams));
        // Decode the string
        const jsonString = atob(encodedParams);
        console.log("jsonString", jsonString);
        // Parse the JSON
        const jsonData = JSON.parse(jsonString);
        const triaName = JSON.parse(localStorage.getItem("tria.wallet.store") || "{}")?.triaName;
        setTriaName(triaName);
        if (triaName) {
          const resp = await getAvatarsByTriaNames([triaName]);
          if (Object.keys(resp).length > 0) {
            const avatar_details = resp[triaName]?.[0];
            setSenderAvatar({ avatar: avatar_details?.avatar, background: avatar_details?.background });
          }
        }
        setParams(jsonData);
        getAsset(jsonData, triaName);
      }
    }
    catch (err) {
      console.log(err);
    }

  };

  const getAsset = async (asset: any, triaName: string) => {
    try {
      console.log("start----------------------->");
      const response = await getAssetDetails(
        asset?.chainName, asset?.tokenAddress, triaName
      );
      setLoading(false);
      setTokenDetails(response);
      console.log("asset---------------------->", response);
    }
    catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    setStateParams();
  }, []);



  return (
    <div>
      <div className="w-full h-screen bg-white dark:bg-fontLightColor rounded-2xl p-4 flex-col justify-between items-center inline-flex">
        {/* {loading ? <div className="w-[448px] h-[840px] p-4 flex-col justify-center items-center inline-flex"><Loader1 /></div> : <> */}
        {approveLoading ? (
          <div>
            <div>
              <Loader />
            </div>
          </div>) : (
          <>
            <div className="w-full justify-end items-start inline-flex">
              <div className="absolute top-0 left-0 ">
                {" "}
                <img className='w-3/4' src="/icons/back.svg" />
              </div>
              {/* <img
                className="dark:visible invisible W-[0] dark:W-18px cursor-pointer"
                src="/icons/ShapeW.svg"
                onClick={()=>sendMessageToParent({success:false,message:"User rejected the request."})}
              ></img> */}
            </div>
            <div className=" px-5 py-2 flex-col justify-center items-center gap-4 flex">
              <Navbar
                loading={loading}
                senderAvatar={senderAvatar}
                chainName={params?.chainName || ""}
                tokenDetails={tokenDetails}
                triaName={triaName}
              />
              <DappDetails params={params} />
            </div>
            <div className="w-full h-[149px] px-4 py-6 flex-col justify-center items-start gap-5 inline-flex">
              <div className="self-stretch justify-center items-center gap-2 inline-flex">
                <div className="text-center text-white text-opacity-80 text-sm font-semibold font-['Montserrat'] leading-normal">
                  Requesting encryption public key
                </div>
              </div>
              <div className="self-stretch px-2 justify-start items-center inline-flex">
                <div className="grow shrink basis-0 text-center text-zinc-400 text-xs font-normal font-['Montserrat'] leading-tight">
                  The website above would like your public encryption key. By consenting, this site will be able to compose encrypted messages to you.
                </div>
              </div>
            </div>
            <div className=" self-stretch grow shrink basis-0 flex-col  gap-2 flex">
              <div className="self-stretch grow shrink basis-0 flex-col justify-end items-center gap-4 flex">
                <div className="h-16">
                  {error && <ErrorMessage error={error} />}
                </div>
                <div className="w-full h-[53px] justify-center items-center gap-6 inline-flex">
                  <div
                    className="grow shrink basis-0  p-2 bg-white rounded-[58px] border border-zinc-500 border-opacity-30 justify-center items-center flex cursor-pointer"
                    onClick={()=>sendMessageToParent({success:false,message:"User rejected the request."})}
                  >
                    <div className="justify-center items-center flex">
                      <div className="text-center text-stone-950 text-opacity-80 text-xs md:text-lg font-semibold font-montserrat leading-snug">
                        Reject
                      </div>
                    </div>
                  </div>
                  <div
                    className="grow shrink basis-0  p-2 bg-gradient-to-r from-violet-400 to-indigo-500 rounded-[58px] justify-center items-center flex cursor-pointer"
                    onClick={() => encrypt()}
                  >
                    <div className="justify-center items-center flex">
                      <div className="text-center text-white text-xs md:text-lg font-semibold font-montserrat leading-snug">
                        Provide
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            {/* </>} */}
          </>)}
      </div>
    </div>
  );
}

export default Encrypt;