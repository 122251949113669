import React from "react";
import {HideBalance, SingleLine} from "../../Components/Loaders";
import {nativeTokenDetails} from  "@tria-sdk/web";


const NetworkFee = ({counter,feeLoading,gasFees,tokenDetails,totalAmountInUSD,totalAmountIncrypto,chainName})=>{
    return(
        <div className="self-stretch w-full p-2 rounded-2xl flex-col justify-center items-center gap-2 flex">
        <div className="self-stretch py-0 justify-start items-center gap-0 inline-flex  w-full">
          <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex ">
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <div className="text-center text-stone-950 text-opacity-80 text-xs font-semibold font-montserrat leading-snug dark:text-text">
                Network Fee
              </div>
              <div className="w-[18px] h-[18px] relative">
                <div className="font-montserrat">
                  <img
                    className="dark:invisible visible dark:w-0"
                    src="/icons/info-circle.svg"
                  ></img>
                  <img
                    className="dark:visible invisible w-0 dark:w-[16px]"
                    src="/icons/info-circle-dark.svg"
                  ></img>
                </div>
              </div>
            </div>
            <div className="self-stretch justify-start items-center gap-2 inline-flex">
              <div className="text-center text-stone-950 text-opacity-60 text-[9px] font-medium font-montserrat leading-[14.40px] dark:text-text">
                Refreshes in: {counter}
              </div>
            </div>
          </div>
          {feeLoading ? (
            <HideBalance />
          ) : (
            <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
              <div className="self-stretch justify-end items-center gap-2 inline-flex">
                <div className="text-center text-stone-950 text-opacity-60 text-xs font-normal font-montserrat leading-snug dark:text-text">
                  ${gasFees?.usd ? Number(gasFees.usd).toFixed(8) : "0.000000"}
                </div>
                {/* <div className="w-[18px] h-[18px] relative">
                  <div className="font-montserrat">
                    <img
                      className="dark:invisible visible dark:w-0"
                      src="/icons/setting-2.svg"
                    ></img>
                    <img
                      className="dark:visible  invisible w-0 dark:w-[18px]"
                      src="/icons/setting-2-dark.svg"
                    ></img>
                  </div>
                </div> */}
              </div>
              <div className="self-stretch justify-end items-center gap-2 inline-flex">
                <div className="text-center text-stone-950 text-opacity-60 text-xs font-normal font-montserrat leading-[16.80px] dark:text-text">
                  {gasFees?.eth ? Number(gasFees.eth).toFixed(8) : ""} {nativeTokenDetails?.[chainName]?.symbol}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="self-stretch py-3 justify-start items-start gap-4 inline-flex">
          <div className="grow shrink basis-0 h-[22px] justify-start items-center gap-2 flex">
            <div className="text-center text-stone-950 text-opacity-80 text-xs font-semibold font-montserrat leading-snug dark:text-text">
              Total Cost
            </div>
          </div>
          {feeLoading ? (
            <SingleLine />
          ) : (
            <div className="grow shrink basis-0 flex-col justify-center items-end gap-1 inline-flex">
              <div className="text-center text-stone-950 text-opacity-60 text-xs font-medium font-montserrat leading-snug dark:text-text">
                ${totalAmountInUSD?.toFixed(8)}
              </div>
              {/* <div className="text-center text-stone-950 text-opacity-60 text-xs font-normal font-montserrat leading-[16.80px] dark:text-text">
                {totalAmountIncrypto?.toFixed(8)} {tokenDetails?.symbol}
              </div> */}
            </div>
          )}
        </div>
        <div className="w-[376px] px-2 justify-center items-center gap-2 inline-flex">
          <div className="justify-center items-center gap-0.5 flex">
            <div className="text-center text-stone-950 text-opacity-60 text-xs font-normal font-['Red Hat Display'] text-zinc-500">
            </div>
          </div>
        </div>
      </div>
    );
}

export default NetworkFee;