// @ts-nocheck
import React, { useContext, useEffect, useState, useRef } from "react"
import Nav from "../../Components/Nav"
import HomeBackgroundVector from "../../Components/HomeBackgroundVector"
import { useNavigate, useParams } from "react-router-dom"
import Footer from "../../Components/Footer"
import NavContext from "../../NavContext"
import { AuthController } from "@tria-sdk/core"
import keyringController from "../../utils/init"
import mixpanel from "mixpanel-browser"
import { v4 as uuidv4 } from "uuid"
// import useWebSocket, { ReadyState } from "react-use-websocket"
import { socket } from "../../../src/utils/init"
import ReCAPTCHA from "react-google-recaptcha"

export default function SignInPassword() {
  const triaName = useParams()

  //Socket
  // const WS_URL = "process.env.REACT_APP_SOCKET_URL"

  // const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
  //   WS_URL,
  //   {
  //     share: false,
  //     shouldReconnect: () => true,
  //   },
  // )

  const [mainLoader, setMainLoader] = useState(true)
  const { setUserEmail, setStoredPassword, setHashPass, setHash, isDarkMode } =
    useContext(NavContext)
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [signUp, setSignUp] = useState(false)
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false)

  //error states
  const [showError, setShowError] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState("")
  const [showPasswordError, setPasswordShowError] = useState(false)

  //show or hide password fields
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)

  //password progress bar
  const [message, setMessage] = useState("")
  const [progress, setProgress] = useState("")
  const [checkPasswordError, setCheckPasswordError] = useState(false)
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("")
  const [isDisposalMail, setIsDisposalMail] = useState(false)
  const [captchaToken, setCaptchaToken] = useState("")

  const reCaptchaRef = useRef<ReCAPTCHA>(null)

  const handlePassword = (passwordValue) => {
    const strengthChecks: any = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    }

    strengthChecks.length = passwordValue.length >= 8 ? true : false
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue)
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue)
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue)
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue)

    let verifiedList = Object.values(strengthChecks).filter((value) => value)

    let strength =
      verifiedList.length == 5
        ? "Strong"
        : verifiedList.length >= 2
        ? "Medium"
        : "Weak"

    setProgress(`${(verifiedList.length / 5) * 100}%`)
    setMessage(strength)
  }

  const baseUrl = process.env.REACT_APP_BASE_URL || ""
  const recaptcha_site_key = "6LfYPaEpAAAAAJh-hB65sAtA574UxTLKRw1zTrli"
  const walletType = {
    embedded: true,
  }

  const authController = new AuthController(baseUrl)

  // useEffect(()=>{
  //   console.log("Connection state changed")
  //   if (readyState === ReadyState.OPEN) {
  //     // sendJsonMessage({
  //     //   event: "subscribe",
  //     //   data: {
  //     //     channel: "general-chatroom",
  //     //   },
  //     // })
  //     console.log("opened")
  //   }

  // },[readyState])

  useEffect(() => {
    //Mixpanel track page view - Sign in with link page
    mixpanel.track_pageview({ page: "Sign In With Password Link" })

    // console.log("socket io -->>",socket)
    // socket.connect()
    // socket.emit('login', {
    //   userId: triaName?.param
    // });

    socket.on("messageV2", (data) => {
      console.log("socket data", data)
      handleSignUp(data.token)
    })
    socket.emit("loginV2", {
      userId: triaName?.param,
    })
  }, [])

  const handleCaptchaChange = (value) => {
    console.log("Captcha value:", value)
    setCaptchaToken(value)
  }

  const handleSignUp = async (code) => {
    console.log("Called")
    const fromClientId = localStorage.getItem("clientId") || ""
    const check = await keyringController.verifyCognitoAuth({
      input: triaName?.param,
      code: code,
      isPasswordLess: false,
      fromClientId: fromClientId,
    })
    console.log("check", check)
    const auth = await keyringController.initiateCognitoAuth({
      input: triaName?.param,
      password: localStorage?.getItem("tempPass"),
      origin: document.referrer,
      isPasswordLess: false,
      verificationType: "link",
      fromClientId: fromClientId,
      captchaToken: captchaToken,
    })
    console.log("id token --> ", auth?.response?.AuthenticationResult?.IdToken)
    if (auth?.hash !== undefined) {
      localStorage.setItem("accessToken", auth?.accessToken)
      localStorage.setItem(
        "idToken",
        auth?.response?.AuthenticationResult?.IdToken
      )
      setUserEmail(triaName?.param)
      setStoredPassword(localStorage?.getItem("tempPass"))
      setHash(auth?.hash)
      setHashPass(auth?.password)
      localStorage.removeItem("tempPass")
      navigate("/SignUp")
    }
  }
  useEffect(() => {
    checkIfExists()
  }, [])

  const checkIfExists = async () => {
    try {
      const check = await authController.checkLinkEmailExists({
        email: triaName?.param,
      })
      console.log("check email", check)
      console.log("check email isDisposalMail", check)
      if (!check?.isValidEmail && !check.emailExist) {
        setIsDisposalMail(true)
      }
      if (check.emailExist === true) {
        setSignUp(false)
        setMainLoader(false)
      } else {
        setSignUp(true)
        setMainLoader(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const login = async () => {
    const fromClientId = localStorage.getItem("clientId") || ""
    let toBeChecked = confirmPassword
    if (signUp === false) {
      toBeChecked = password
    }
    let passwordStrength = message
    if (signUp === false) {
      passwordStrength = "Strong"
    }
    if (
      password.length !== 0 &&
      /^(?=.*[0-9])(?=.*[\^$*.[\]{}()?\"!@#%&\/\\,><'':;|_~`+=])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        toBeChecked
      ) &&
      passwordStrength === "Strong"
    ) {
      if (!captchaToken && isDisposalMail) {
        console.log("enter captcha")
        return
      }
      if (signUp === false) {
        try {
          setLoader(true)
          setCheckPasswordError(false)
          const check = await authController.checkLinkEmailExists({
            email: triaName?.param,
          })
          console.log("check email", check)
          const auth = await keyringController.initiateCognitoAuth({
            input: triaName?.param,
            password: password,
            origin: document.referrer,
            isPasswordLess: false,
            verificationType: "link",
            fromClientId: fromClientId,
            captchaToken: captchaToken,
          })
          localStorage.setItem("accessToken", auth?.accessToken)
          localStorage.setItem(
            "idToken",
            auth?.response?.AuthenticationResult?.IdToken
          )
          if (auth?.isAccountExists === true && auth?.success === true) {
            const vault = await keyringController.getVault({
              input: triaName?.param,
              link: true,
              hash: auth?.hash,
              password: auth?.password,
              origin: document.referrer,
              accessToken: auth?.accessToken,
              idToken: auth?.response?.AuthenticationResult?.IdToken,
            })
            const publicVault = vault?.publicVault
            if (vault?.exists === true) {
              const encodedData = btoa(JSON.stringify(publicVault))
              const socketId = uuidv4()

              window.open(
                `${process.env.REACT_APP_HOSTED_URL}/listenForSocket?socketId=${socketId}`,
                "_blank"
              )

              socket.emit("loginV2", { userId: socketId }, () => {
                console.log("Socket connection established")

                let emitCount = 0
                const maxEmits = 5

                const emitInterval = setInterval(() => {
                  if (emitCount >= maxEmits) {
                    clearInterval(emitInterval)

                    // Close the socket connection
                    socket.disconnect()

                    // Perform keyringController.postMessage after 5 attempts
                    console.log("Posting message to keyringController")
                    keyringController.postMessage({
                      type: "Log in",
                      success: true,
                      data: publicVault,
                    })

                    return
                  }

                  // Send the data through socket
                  socket.emit(
                    "messageV2",
                    { userId: socketId, message: encodedData },
                    (error, result) => {
                      if (error) {
                        console.error(
                          "Error sending data through socket:",
                          error
                        )
                      } else {
                        console.log(
                          `Data sent successfully through socket (${
                            emitCount + 1
                          }/${maxEmits})`
                        )
                      }
                    }
                  )

                  emitCount++
                }, 1000)
              })
            } else {
              setLoader(false)
              setCheckPasswordError(true)
              setPasswordErrorMessage("Test Error Message")
            }
          } else if (
            auth?.success === true &&
            auth?.isAccountExists === false
          ) {
            console.log("Account does not exist")
            setUserEmail(triaName?.param)
            setStoredPassword(localStorage?.getItem("tempPass"))
            setHash(auth?.hash)
            setHashPass(auth?.password)
            localStorage.removeItem("tempPass")
            navigate("/SignUp")
          } else {
            setCheckPasswordError(true)
            setLoader(false)
            if (auth?.success === false) {
              setPasswordErrorMessage("something went wrong")
            } else {
              setPasswordErrorMessage("Incorrect password. Please try again!")
            }
          }
        } catch (err) {
          console.log(err)
        }
      } else {
        if (password === confirmPassword) {
          try {
            setShowError(false)
            setPasswordShowError(true)
            setLoader(true)
            console.log("Sign up")
            localStorage.setItem("email", triaName?.param)

            const auth = await keyringController.initiateCognitoAuth({
              input: triaName?.param,
              password: password,
              origin: document.referrer,
              isPasswordLess: false,
              verificationType: "link",
              fromClientId: fromClientId,
              captchaToken: captchaToken,
            })
            console.log("store temp pass", password)
            localStorage.setItem("tempPass", password)
            navigate("/verifyAccount")
          } catch (err) {
            console.log(err)
          }
        } else {
          setShowError(true)
          setShowErrorMessage("Passwords do not match")
        }
      }
    } else {
      if (signUp === true) {
        setShowError(true)
        setShowErrorMessage(
          "Password should contain minimum eight characters, at least one letter, one number and one special character"
        )
      } else {
        setCheckPasswordError(true)
        setPasswordErrorMessage("Incorrect password. Please try again!")
      }
      // setPassword("")
      // setConfirmPassword("")
    }
  }

  // const walletType = {
  //   embedded: true,
  // };
  // const baseUrl = process.env.REACT_APP_BASE_URL;

  // const login = async () => {
  //   const vault = await getTriaAccount();
  //   console.log({ decryptedData: vault?.data });
  //   // if (!vault?.exists) {
  //   //   await createTriaAccount();
  //   // }
  // };

  // async function getTriaAccount() {
  //   console.log('Get Tria Account!!');
  //   const keyringController = new KeyringController({
  //     baseUrl,
  //     walletType,
  //   });
  //   console.log({ triaName, password });
  //   if (triaName && password) {
  //     const vault = await keyringController.getVault({ triaName, password });
  //     console.log({ vault });
  //     return vault;
  //   }
  // }
  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword)
  }
  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const eyeIcon = showConfirmPassword ? "eye-dark.svg" : "eye-slash.svg"
  const eyeIcon2 = showPassword ? "eye-dark.svg" : "eye-slash.svg"

  return (
    <div>
      <div className='w-full bg-white dark:bg-fontLightColor rounded-2xl h-screen p-3 flex-col justify-between inline-flex'>
        <div className='absolute top-0 left-0 '>
          {" "}
          {isDarkMode ? (
            <img className='w-3/4' src='/icons/back.svg' />
          ) : (
            <div className='-ml-8 w-3/4'>
              <HomeBackgroundVector />
            </div>
          )}
        </div>
        <div className='flex-col justify-start gap-2 flex'>
          <Nav />
        </div>
        {showError === true && signUp === true ? (
          <div className='absolute top-44 w-[92%] z-10'>
            <div
              id='toast-danger'
              className='flex items-center p-2  text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-black'
              role='alert'
            >
              <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200'>
                <svg
                  className='w-5 h-5'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                >
                  <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
                </svg>
                <span className='sr-only'>Error icon</span>
              </div>
              <div style={{ fontSize: "10px" }} className='ml-3 font-normal'>
                {showErrorMessage}
              </div>
              <button
                onClick={() => setShowError(false)}
                type='button'
                class='ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-black dark:hover:bg-gray-700'
                data-dismiss-target='#toast-danger'
                aria-label='Close'
              >
                <span class='sr-only'>Close</span>
                <svg
                  class='w-3 h-3'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 14 14'
                >
                  <path
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
                  />
                </svg>
              </button>
            </div>
          </div>
        ) : null}

        {checkPasswordError === true && signUp === false ? (
          <div className='mt-28'>
            <div
              id='toast-danger'
              className='flex items-center  p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-black'
              role='alert'
            >
              <div className='inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200'>
                <svg
                  className='w-5 h-5'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='currentColor'
                  viewBox='0 0 20 20'
                >
                  <path d='M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z' />
                </svg>
                <span className='sr-only'>Error icon</span>
              </div>
              {/* <div className="ml-3 text-sm font-normal">{passwordErrorMessage}</div> */}
              <div style={{ fontSize: "10px" }} className='ml-3 font-normal'>
                Incorrect password. Please try again!
              </div>
              <button
                onClick={() => {
                  setCheckPasswordError(false)
                  console.log("close")
                }}
                type='button'
                class='ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-black dark:hover:bg-gray-700'
                data-dismiss-target='#toast-danger'
                aria-label='Close'
              >
                <span class='sr-only'>Close</span>
                <svg
                  class='w-3 h-3'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 14 14'
                >
                  <path
                    stroke='currentColor'
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    stroke-width='2'
                    d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
                  />
                </svg>
              </button>
            </div>
          </div>
        ) : null}
        {mainLoader === false ? (
          <div className='w-full mt-auto  flex-col justify-center items-center gap-3 inline-flex '>
            <div
              className={`self-stretch px-3 py-2 rounded-2xl border border-violet-400 border-opacity-30 flex-col justify-center  items-center gap-2 flex`}
            >
              <div className='self-stretch py-3 flex-col justify-center items-start gap-4 flex '>
                <div className='self-stretch justify-start items-center gap-2 inline-flex '>
                  <div
                    className={`mix-blend-difference text-center text-white text-opacity-80 text-[12.5px] font-medium font-['Montserrat'] leading-snug`}
                  >
                    {signUp === false ? (
                      <span>Login with email</span>
                    ) : (
                      <>
                        <div className='flex justify-start'>
                          <span>Sign up with email</span>{" "}
                        </div>
                        <div className="w-full text-left mix-blend-difference text-white text-opacity-50 text-[10px] font-normal font-['Montserrat'] mt-3">
                          This email id and password will be the access to your
                          account.
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='self-stretch flex-col justify-center items-center flex'>
                <div className="text-center text-neutral-600 text-[7px] mb-1 font-bold font-['Montserrat'] uppercase leading-3 tracking-tight mr-auto ml-[.75rem]">
                  email
                </div>
                <div
                  className={
                    signUp
                      ? "self-stretch py-0 justify-center items-center gap-2 inline-flex mb-0"
                      : "self-stretch py-0 justify-center items-center gap-2 inline-flex mb-2"
                  }
                >
                  <div className='grow shrink basis-0 h-7 px-5 py-3 bg-zinc-200 cursor-not-allowed bg-opacity-10 rounded-[20px] justify-start items-center flex font-medium'>
                    <div className='justify-start items-center flex'>
                      <div className="mix-blend-difference text-center text-white text-opacity-90 text-[11.5px] font-semibold font-['Montserrat'] leading-tight">
                        {triaName?.param}
                      </div>
                    </div>
                  </div>
                </div>
                {signUp && (
                  <div className="text-center text-neutral-600 text-[7px] font-bold font-['Montserrat'] uppercase leading-3 tracking-tight mr-auto ml-[.75rem] mt-3">
                    password
                  </div>
                )}
                {signUp && (
                  <div className='self-stretch py-3 justify-center items-center gap-2 inline-flex'>
                    <div className="grow shrink basis-0 h-7 px-5 -mt-2  py-0 bg-zinc-500 bg-opacity-10 rounded-[20px] justify-start items-center flex font-['Montserrat'] text-white">
                      <input
                        title='Minimum eight characters, at least one letter, one number and one special character'
                        className="w-full grow shrink basis-0 h-7 bg-transparent text-[11.5px] focus:outline-none font-['Montserrat'] dark:text-white text-black font-medium"
                        placeholder='Password'
                        type={
                          showConfirmPassword === false ? "password" : "text"
                        }
                        onChange={(e) => {
                          setConfirmPassword(e.target.value)
                          handlePassword(e.target.value)
                        }}
                      />
                      <img
                        onClick={toggleShowConfirmPassword}
                        className='ml-2 cursor-pointer h-3 w-3'
                        src={
                          isDarkMode
                            ? `/icons/${eyeIcon}`
                            : `/icons/eye-slash-dark.svg`
                        }
                        alt='eye-slash'
                      />
                    </div>
                  </div>
                )}
                {confirmPassword.length !== 0 ? (
                  <div className='w-full self-stretch flex gap-2 items-center ml-2 -mt-2'>
                    <div
                      className={
                        message === "Weak"
                          ? "w-10 h-1 bg-red-500 rounded-3xl"
                          : message === "Medium"
                          ? "w-10 h-1 bg-yellow-500 rounded-3xl"
                          : message === "Strong"
                          ? "w-10 h-1 bg-lime-600 rounded-3xl"
                          : null
                      }
                    ></div>
                    <div
                      className={
                        message === "Weak"
                          ? "w-10 h-1 bg-zinc-500 rounded-3xl"
                          : message === "Medium"
                          ? "w-10 h-1 bg-yellow-500 rounded-3xl"
                          : message === "Strong"
                          ? "w-10 h-1 bg-lime-600 rounded-3xl"
                          : null
                      }
                    ></div>
                    <div
                      className={
                        message === "Weak"
                          ? "w-10 h-1 bg-zinc-500 rounded-3xl"
                          : message === "Medium"
                          ? "w-10 h-1 bg-zinc-500 rounded-3xl"
                          : message === "Strong"
                          ? "w-10 h-1 bg-lime-600 rounded-3xl"
                          : null
                      }
                    ></div>
                    <span
                      className={
                        message === "Medium"
                          ? "text-yellow-500 font-semibold text-[10px]"
                          : message === "Strong"
                          ? "text-lime-600 font-semibold text-[10px]"
                          : message === "Weak"
                          ? "text-red-500 font-semibold text-[10px]"
                          : null
                      }
                    >
                      {message}
                    </span>
                  </div>
                ) : null}
                <div className="text-center text-neutral-600 text-[7px] font-bold font-['Montserrat'] uppercase leading-3 tracking-tight mr-auto ml-[.75rem] mt-0">
                  {signUp === false ? (
                    <span>password</span>
                  ) : (
                    <span>confirm password</span>
                  )}
                </div>

                <div className='self-stretch py-3 justify-center items-center gap-2 inline-flex'>
                  <div className="grow shrink basis-0 h-7 px-5 -mt-2  py-3 bg-zinc-500 bg-opacity-10 rounded-[20px] text-xs justify-start items-center flex font-['Montserrat'] text-white">
                    <input
                      title='Minimum eight characters, at least one letter, one number and one special character'
                      className="w-full grow shrink basis-0 h-7 text-[11.5px] bg-transparent focus:outline-none font-['Montserrat'] dark:text-white text-black font-medium"
                      placeholder={
                        signUp === false ? "Password" : "Confirm Password"
                      }
                      type={showPassword === false ? "password" : "text"}
                      onChange={(e) => {
                        setPassword(e.target.value)
                      }}
                    />
                    <img
                      onClick={toggleShowPassword}
                      className='ml-2 cursor-pointer h-3 w-3'
                      src={
                        isDarkMode
                          ? `/icons/${eyeIcon2}`
                          : `/icons/eye-slash-dark.svg`
                      }
                      alt='eye-slash'
                    />
                  </div>
                  <div
                    onClick={() => {
                      login()
                    }}
                    className='w-[80px] -mt-2 cursor-pointer h-7 px-5 py-3 mix-blend-difference bg-white bg-opacity-90 rounded-[20px] justify-center items-center flex'
                  >
                    <div className='justify-center items-center flex'>
                      {signUp === false ? (
                        <button>
                          <div className="text-center text-stone-950 text-[10.5px] font-semibold font-['Montserrat'] leading-tight">
                            {loader === false ? (
                              <span>Log in</span>
                            ) : (
                              <div className='flex space-x-1 justify-center items-center '>
                                <div
                                  style={
                                    isDarkMode === true
                                      ? { backgroundColor: "gray" }
                                      : { backgroundColor: "gray" }
                                  }
                                  className='h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.3s]'
                                ></div>
                                <div
                                  style={
                                    isDarkMode === true
                                      ? { backgroundColor: "gray" }
                                      : { backgroundColor: "gray" }
                                  }
                                  className='h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.15s]'
                                ></div>
                                <div
                                  style={
                                    isDarkMode === true
                                      ? { backgroundColor: "gray" }
                                      : { backgroundColor: "gray" }
                                  }
                                  className='h-2 w-2 bg-white rounded-full animate-bounce'
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                      ) : (
                        <button>
                          {" "}
                          <div className="text-center text-stone-950 text-[10px] font-semibold font-['Montserrat'] leading-tight">
                            {loader === false ? (
                              <span>Sign up</span>
                            ) : (
                              <div className='flex space-x-1 justify-center items-center '>
                                <div
                                  style={
                                    isDarkMode === true
                                      ? { backgroundColor: "gray" }
                                      : { backgroundColor: "gray" }
                                  }
                                  className='h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.3s]'
                                ></div>
                                <div
                                  style={
                                    isDarkMode === true
                                      ? { backgroundColor: "gray" }
                                      : { backgroundColor: "gray" }
                                  }
                                  className='h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.15s]'
                                ></div>
                                <div
                                  style={
                                    isDarkMode === true
                                      ? { backgroundColor: "gray" }
                                      : { backgroundColor: "gray" }
                                  }
                                  className='h-2 w-2 bg-white rounded-full animate-bounce'
                                ></div>
                              </div>
                            )}
                          </div>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {isDisposalMail && (
                  <div>
                    <ReCAPTCHA
                      style={{
                        display: "inline-block",
                        transform: "scale(0.7)",
                      }}
                      theme='dark'
                      ref={reCaptchaRef}
                      sitekey={recaptcha_site_key}
                      onChange={handleCaptchaChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div role='status' className='flex justify-center mb-auto mt-20'>
            <svg
              aria-hidden='true'
              className='w-[4.75rem] h-[4.7rem] mr-2 text-gray-200 animate-spin dark:text-zinc-500 fill-white'
              viewBox='0 0 100 101'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                fill='currentColor'
              />
              <path
                d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                fill='currentFill'
              />
            </svg>
          </div>
        )}
        <Footer />
      </div>
    </div>
  )
}
