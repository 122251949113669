import keyringController from "../../utils/init";
import { eventTypes } from "@tria-sdk/connect";
import { useEffect } from 'react';


const AccessToken = () => {

    useEffect(() => {
        try {
            if (localStorage?.getItem("accessToken") !== "undefined") {
                keyringController.postMessage({
                    //@ts-ignore
                    type: eventTypes?.accessToken /* import {eventTypes} from "@tria-sdk/connect" */,
                    data: localStorage?.getItem("accessToken") /*from local storage*/
                })
            }
        } catch (err) {
            console.log(err)
        }
    }, [])


    return (
        <>
        </>
    )

}

export default AccessToken