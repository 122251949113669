import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from "./contexts/tria-user-provider";
import { AuthProvider } from './contexts/tria-auth-provider';
import mixpanel from 'mixpanel-browser';


import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import { mainnet, polygon, optimism, arbitrum, base, zora, goerli } from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';

window.Buffer = window.Buffer || require("buffer").Buffer;

mixpanel.init('340ed33775b5eaac3b1072cd9b7da294', {debug: true, track_pageview: true, persistence: 'localStorage'});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(


    <AuthProvider>
        <UserProvider>
            <App />
        </UserProvider>
    </AuthProvider>


);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
