import React, { useContext, useEffect, useState } from "react"
import Nav from "../../Components/Nav"
import ContinueWithTria from "../../Components/ContinueWithTria"
import EmailAndSocial from "../../Components/EmailAndSocial"
import ConnectWallet from "../../Components/ConnectWallet"
import HomeBackgroundVector from "../../Components/HomeBackgroundVector"
import Footer from "../../Components/Footer"
import { useListenerSO } from "@tria-sdk/connect"
import { useNavigate, useLocation } from "react-router-dom"
import NavContext from "../../NavContext"
import { detectIncognito } from "detectincognitojs"
import PhoneAndSocial from "../../Components/PhoneAndSocial"
import mixpanel from "mixpanel-browser"

export default function OnboardingHome() {
  const [continueWithTria, setContinueWithTria] = useState(true)
  const [emailAndSocial, setEmailAndSocial] = useState(false)
  const [connectWallet, setConnectWallet] = useState(false)
  const [incognito, setIncognito] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const {
    setToken,
    setUsername,
    setDappLogo,
    setDappName,
    dappName,
    dappLogo,
    connectWithEmail,
    setConnectWithEmail,
    continueOnIncognito,
    setContinueOnIncognito,
    setIsDarkMode,
    isDarkMode,
    setFromClientId,
  } = useContext(NavContext)

  const { eventData }: any = useListenerSO()

  useEffect(() => {
    //Mixpanel track page view - Home Page
    mixpanel.track_pageview({ page: "Home Page" })

    if (dappName === "" && dappLogo === "") {
      const searchParams = new URLSearchParams(location.search)
      const email = searchParams.get("connectWithEmail")
      setConnectWithEmail(!Boolean(email))
      const name = searchParams.get("dappName")
      setDappName(name)
      const logo = searchParams.get("dappLogo")
      setDappLogo(logo)
      const fromClientId = searchParams.get("clientId")
      if (fromClientId !== null) {
        localStorage.setItem("clientId", fromClientId)
      }
      setFromClientId(fromClientId)
      const ui = searchParams.get("stackui")
      const domain = searchParams.get("dappDomain")
      const darkMode = searchParams.get("darkMode")
      const dapp_obj = {
        dappDomain: domain,
        dappLogo: logo,
      }
      try {
        if (localStorage.getItem("dapp.details") === null) {
          localStorage?.setItem("dapp.details", JSON.stringify(dapp_obj))
        }
      } catch (err) {
        console.log(err)
      }
      if (ui === "yes") {
        navigate("/stackhome")
      }
      // if (darkMode === "true") {
      //   setIsDarkMode(true)
      // } else if (darkMode === "false") {
      //   setIsDarkMode(false)
      // } else {
      //   setIsDarkMode(false)
      // }
    }
    //Test logs
    const searchParams = new URLSearchParams(location.search)
    const name = searchParams.get("dappName")
    const logo = searchParams.get("dappLogo")
    const email = searchParams.get("connectWithEmail")
    console.log("DappName", name)
    console.log("Logo", logo)
    console.log("email", !Boolean(email))

    // if (continueOnIncognito === false) {
    //   detectIncognito().then((result) => {
    //     console.log(result.browserName, result.isPrivate);
    //     if (result.isPrivate === true) {
    //       // navigate("/incognito")
    //       setIncognito(true)
    //     }
    //   });
    // }
  }, [])

  useEffect(() => {
    if (eventData?.message?.accountExists === false) {
      console.log("message_event", eventData?.message)
      //localStorage.setItem("accessToken", eventData?.message?.token)
      setUsername(eventData?.message?.username)
      setToken(eventData?.message?.token)
      navigate(
        `/signUpUserName/${localStorage?.getItem("socialNetwork")}/${
          eventData?.message?.userId
        }`
      )
    } else {
      //localStorage.setItem("accessToken", eventData?.message?.token)

      setToken(eventData?.message?.token)
    }
  }, [eventData])

  const toggleState = () => {
    setContinueWithTria(!continueWithTria)
    setEmailAndSocial(false)
    setConnectWallet(false)
  }
  const toggleState2 = () => {
    setContinueWithTria(false)
    setEmailAndSocial(!emailAndSocial)
    setConnectWallet(false)
  }
  const toggleState3 = () => {
    setContinueWithTria(false)
    setEmailAndSocial(false)
    setConnectWallet(!connectWallet)
  }

  return (
    <>
      {incognito === false ? (
        <div className='w-full shadow-lg rounded-2xl bg-white drop dark:bg-fontLightColor h-screen p-3 flex-col justify-between inline-flex'>
          <div className='absolute top-0 left-0 '>
            {" "}
            {isDarkMode ? (
              <img className='w-3/4' src='/icons/back.svg' />
            ) : (
              <div className='-ml-8 w-3/4 '>
                <HomeBackgroundVector />
              </div>
            )}
          </div>
          <Nav />
          <div className='mt-auto'>
            {/* <PhoneAndSocial/> */}
            <EmailAndSocial />
          </div>
          <Footer />
        </div>
      ) : (
        <div>
          <div className='dark:bg-fontLightColor bg-white rounded-2xl p-3 flex-col justify-between items-center inline-flex'>
            <div style={{ marginLeft: "-150px" }} className='absolute top-0 '>
              {" "}
              <HomeBackgroundVector />
            </div>
            <div className='flex-col justify-start items-center gap-2 flex'>
              <div>
                <div className=' justify-between items-start inline-flex'>
                  {/* <div className="p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-center gap-2 inline-flex">
                  <div className=" relative" >
                    <button onClick={() => { navigate("/") }}> <img src='/icons/close.svg'></img> </button>
                  </div>
                </div> */}
                  <div className='p-3  rounded-[39px] flex-col justify-center items-center gap-2 inline-flex'>
                    {/* <img src='/icons/Shape.svg'></img> */}
                  </div>
                </div>
                <div className='h-[221px] px-5 py-6 flex-col justify-center  gap-4 flex'>
                  <div className='self-stretch py-2  justify-center  gap-2 inline-flex'>
                    <div className=' flex-col justify-center  gap-2 inline-flex'>
                      <img className='w-[95px] h-[95px]' src={dappLogo} />
                    </div>
                  </div>
                  <div className='self-stretch h-[46px] py-3 flex-col justify-center items-start gap-4 flex'>
                    <div className='self-stretch justify-center  gap-2 inline-flex'>
                      <div className="text-center text-stone-950 text-opacity-80 text-xs font-medium font-['Montserrat'] leading-snug dark:text-text">
                        Login to {dappName}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="h-[289px] flex-col  items-center gap-3 flex"> */}
            <div className='w-[416px] mb-auto  px-5 py-4 rounded-2xl border border-violet-400 border-opacity-30 flex-col justify-center items-center gap-2 inline-flex'>
              <div className='self-stretch py-3 justify-center items-center gap-4 inline-flex'>
                <div className='grow shrink basis-0  justify-center items-center gap-2 flex'>
                  <div className='w-7 relative'>
                    <img src='/icons/danger.svg'></img>
                  </div>
                  <div className="text-center text-neutral-50 text-2xl font-medium font-['Montserrat'] leading-[28.80px]">
                    Attention!
                  </div>
                </div>
              </div>
              <div className=' px-2 py-3 flex-col justify-center items-start gap-2 flex'>
                <div className="self-stretch mix-blend-difference text-zinc-400 text-sm font-semibold font-['Montserrat']">
                  You might lose access your account if you are logging in or
                  signing to this application from an Incognito tab.
                </div>
                <div className="self-stretch mix-blend-difference text-zinc-400 text-sm font-normal font-['Montserrat']">
                  If you still want to, you can continue.
                </div>
              </div>
              <div className='self-stretch  flex-col justify-center items-center flex'>
                <div className='self-stretch py-3 justify-center items-center gap-2 inline-flex'>
                  <div
                    onClick={() => {
                      setIncognito(false)
                      setContinueOnIncognito(true)
                    }}
                    className='grow shrink basis-0 cursor-pointer  px-5 py-3 bg-gradient-to-r from-violet-400 to-indigo-500 rounded-[20px] justify-center items-center flex'
                  >
                    <div className='justify-center items-center flex'>
                      <div className="text-center text-white text-base font-semibold font-['Montserrat'] leading-tight">
                        Continue
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
            {/* </div> */}
          </div>
        </div>
      )}
    </>
  )
}
