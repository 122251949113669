//@ts-nocheck
import React, { useState, useEffect } from "react";
import Navbar from "../../Components/Navbar";
import { FeeController, WalletController } from "@tria-sdk/web";
import { useParams } from "react-router-dom";
import { useTriaUser } from "../../contexts/tria-user-provider";
import Loader from "../../Components/Send/Loader";
import { shortenWalletAddress } from "../../utils";
import Loader1 from "../../Components/Loader";
import { AssetDetails, NftsItemDetails, sendNftParam, fee, AvatarItem, postMessageResponse } from "../../types";
import { walletType, baseUrl, walletUrl } from "../../utils/constants";
import { NftAsset } from '../../Components/Loaders';
import DappDetails from "../../Components/DappDetails";
import NetworkFee from "../../Components/NetworkFee";
import FooterButtons from "../../Components/FooterButtons";
import ErrorMessage from "../../Components/Popup/ErrorMessage";


const initialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)"
}

const recieverInitialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)"
}



export default function SendNft(props: any) {
  const {
    getAssetDetails,
    getUserByAddress,
    getAvatarsByTriaNames,
    getNFTDetails,
  } = useTriaUser();

  const encodedParams = btoa(
    JSON.stringify({
      chainName: "POLYGON",
      fromTriaName: "lalit277@tria",
      recipientTriaName: "lalit277@tria",
      nftDetails: {
        type: "ERC721",
        tokenAddress: "0xd1fD14e3Cf4f96E63A1561681dc8765DF8f7Cf91",
        tokenId: "1",
        amount: 1,
      },
      fromWallet: true,
      dappDomain: "https://facebook.com",
    })
  );

  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState<sendNftParam>();
  const [tokenDetails, setTokenDetails] = useState<AssetDetails>();
  const [gasFees, setGasFees] = useState<fee>();
  const [recieverTriaName, setRecieverTriaName] = useState();
  const [amountInUSD, setAmountInUSD] = useState<number>();
  const [totalAmountInUSD, setTotalAmountInUSD] = useState<number>();
  const [totalAmountIncrypto, setTotalAmountIncrypto] = useState<number>();
  const [feeLoading, setFeeLoading] = useState<boolean>(true);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [senderAvatar, setSenderAvatar] = useState<AvatarItem>(initialAvatar);
  const [recipientAvatar, setRecipientAvatar] =
    useState<AvatarItem>(recieverInitialAvatar);
  const [nftDetails, setNftDetails] = useState<NftsItemDetails>();
  const [approveButtonDisable, setApproveButtonDisable] = useState<boolean>(true);
  const [triaName, setTriaName] = useState<string>("");
  const [timerStarted, setTimerStarted] = useState(false);
  const [transactionSuccessful, setTransactionSuccessful] = useState<boolean>(false);
  const [counter, setCounter] = useState(30);
  const [updateStateFromParam, setUdateStateFromParam] = useState<boolean>(false);
  const param = useParams();



  const sendMessageToParent = (data: postMessageResponse) => {
    console.log("event post message", data);
    // Post a message to the parent window
    window.parent.postMessage(
      { type: "closeIframe", callFrom: "sendNft", data: data },
      "*"
    );
  };

  const handleRejectClick = () => {
    if (params?.fromWallet) {
      window.open(`${walletUrl}/home/sendCrypto/nftsend`, "_self");
    }
    else {
      sendMessageToParent({success:false,message:"User rejected the request."});
    }
  }

  const sendNFT = async () => {
    try {
      setApproveLoading(true);
      console.log("sending token!!");
      const wallet = new WalletController({
        baseUrl,
        walletType,
      });
      const payload = params;
      delete payload?.fromTriaName;
      console.log("payload------>", payload);
      await wallet.init();
      const txn = await wallet.sendNFT(
        payload?.recipientTriaName,
        payload?.nftDetails,
        payload?.chainName
      );


      const { wait, ...txnDataWithoutWait } = txn.data || {};
      const modifiedTxnResponse = {
        ...txn,
        data: txnDataWithoutWait,
      };

      if (modifiedTxnResponse?.success) {
        setTransactionSuccessful(true);
        if (params?.fromWallet) {
          setTimeout(() => {
            const walletParams = encodeURIComponent(JSON.stringify({ txnId: modifiedTxnResponse?.data?.txnId, viewInExplorer: modifiedTxnResponse?.data?.viewInExplorer, assetType: "NFT", params, tokenDetails }));
            // console.log("redirecting to---->", `${walletUrl}/activities/${walletParams}`);
            window.open(`${walletUrl}/activities/${walletParams}`, "_self");
          }, 2000);
        } else {
          setTimeout(() => {
            sendMessageToParent(modifiedTxnResponse);
          }, 2000);
        }
      }
      else {
        setError(modifiedTxnResponse?.message || "");
        setApproveLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError(err?.message);
      setApproveLoading(false);
    }
  };

  const getTriaName = async (address: any, chainName: any) => {
    try {
      const triaName = await getUserByAddress(address, chainName);

      return triaName;
    } catch (err) {
      console.log("error", err);
      return null;
    }
  };

  const getSendNFTFee = async (feeCallData: sendNftParam) => {
    console.log("getSendFee----------------------------------------->")
    setApproveButtonDisable(true);
    setError("");
    try {
      const fee = new FeeController({
        baseUrl,
        walletType,
      });
      const chainName = feeCallData?.chainName;
      const fromTriaName = triaName;
      const recipientTriaName = feeCallData?.recipientTriaName;
      const nftDetails = feeCallData?.nftDetails;

      if (!(chainName && fromTriaName && recipientTriaName && nftDetails)) {
        return;
      }


      const res = await fee.getSendNFTFee(
        chainName,
        fromTriaName,
        recipientTriaName,
        nftDetails
      );
      setCounter(30);
      if (res.success === true) {
        setApproveButtonDisable(false);
        setGasFees(res.fee);
        setTotalAmountInUSD(parseFloat(res.fee?.usd || "0"));
        setTotalAmountIncrypto(parseFloat(res?.fee?.eth || "0"));
        setFeeLoading(false);
      } else {
        setError(res?.message || "");
      }

      console.log({ res });
    } catch (error: any) {
      setError(error?.message || "");
      console.error(error);
    }
  };
  

  const setStateParams = async () => {
    try {
      console.log("setting params");
      setUdateStateFromParam(true);
      //@ts-ignore
      const encodedParams = param?.param;
      console.log("en", encodedParams);
      if (encodedParams) {
        const jsonString = atob(encodedParams);
        console.log("jsonString", jsonString);
        // Parse the JSON
        const jsonData: sendNftParam = JSON.parse(jsonString);
        const triaName = JSON.parse(localStorage.getItem("tria.wallet.store") || "{}")?.triaName;
        setTriaName(triaName);
        fetchNftDetails(jsonData, triaName);
        getAsset(jsonData, triaName);
        setParams(jsonData);
        if (triaName) {
          const resp = await getAvatarsByTriaNames([triaName]);
          if (Object.keys(resp).length > 0) {
            const avatar_details = resp[triaName]?.[0];
            setSenderAvatar({
              avatar: avatar_details?.avatar,
              background: avatar_details?.background,
            });
          }
        }

        let recieverTriaName = jsonData?.recipientTriaName;
        if (!recieverTriaName?.includes("@")) {
          const reciever = await getTriaName(recieverTriaName, jsonData?.chainName);
          if (reciever) {
            recieverTriaName = reciever;
          }
        }
        if (recieverTriaName?.includes("@")) {
          setRecieverTriaName(recieverTriaName);
          const resp = await getAvatarsByTriaNames([recieverTriaName]);
          if (Object.keys(resp).length > 0) {
            const recipient_avatar_details = resp[recieverTriaName]?.[0];
            setRecipientAvatar({ avatar: recipient_avatar_details?.avatar, background: recipient_avatar_details?.background });
          }
        }
        else {
          const recieverAddressShort =
            shortenWalletAddress(recieverTriaName) || "";
          setRecieverTriaName(recieverAddressShort);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchNftDetails = async (paramData: sendNftParam, triaName: string) => {
    try {
      const nftDetails = await getNFTDetails(
        paramData?.chainName,
        paramData?.nftDetails?.tokenAddress,
        paramData?.nftDetails?.tokenId,
        triaName
      );
      console.log("nftDetails->", nftDetails);
      setNftDetails(nftDetails);
    } catch (err) {
      console.log(err);
      setError(" Error in fetching Nft details");
    }
  };

  const getAsset = async (asset: any, triaName: string) => {
    try {
      const response = await getAssetDetails(
        asset?.chainName,
        asset?.tokenAddress,
        triaName
      );
      setLoading(false);
      setTokenDetails(response);
      if (asset?.amount) {
        const total = asset?.amount * response.quoteRate;
        setAmountInUSD(total);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (gasFees && amountInUSD) {
      setTotalAmountInUSD(
        parseFloat(gasFees?.usd || "0") + (amountInUSD || 0)
      );
    }
  }, [gasFees, amountInUSD]);

  const fetchSendFee = async () => {
    try {
      await getSendNFTFee(params);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (params) {
      setFeeLoading(true);
      fetchSendFee();
    }
  }, [params]);

  useEffect(() => {
    if (counter === 0 && !feeLoading) {
      setFeeLoading(true);
      fetchSendFee();
    }
  }, [counter])

  useEffect(() => {
    if (params && !!gasFees?.eth && !approveLoading) {
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter > 0) {
            return prevCounter - 1;
          } else {
            return 0;
          }
        });

      }, 1000);
      setTimerStarted(true);
      return () => clearInterval(intervalId);
    }
  }, [counter, params, gasFees,approveLoading]);


  useEffect(() => {
    console.log("encoded data ", param.param);
    if (!updateStateFromParam) {
      setStateParams();
    }
  }, []);

  return (
    <div className="w-full h-screen p-3 flex-col bg-white dark:bg-fontLightColor rounded-2xl justify-between items-center inline-flex">
      {/* {loading ? (
        <div className="w-[448px] h-[840px] p-4 flex-col justify-center items-center inline-flex">
          <Loader1 />
        </div>
      ) : (
        <> */}
          {approveLoading ? (
            <div className="">
              <div >
                <Loader
                  heading={"Sending..."}
                  dappLogo={params?.dappLogo}
                  transactionSuccessful={transactionSuccessful}
                />
              </div>
            </div>
          ) : (
            <div className="w-full flex-col justify-center items-center flex">
              <div className="absolute top-0 left-0 ">
                {" "}
                <img className="w-3/4" src="/icons/back.svg" />
              </div>
            
              <div className="self-stretch flex-col justify-center items-center gap-0 flex">
                <div className="self-stretch border-zinc-500 pt-0 border-opacity-10 justify-center items-center gap-0 inline-flex">
                  <div>
                    <Navbar
                      loading={loading}
                      senderAvatar={senderAvatar}
                      chainName={params?.chainName || ""}
                      tokenDetails={tokenDetails}
                      triaName={triaName}
                    />
                  </div>{" "}
                </div>
                <DappDetails params={params} />
                <div className="self-stretch justify-center align-center items-center gap-2 inline-flex">
                  <div className="text-center text-stone-950 text-opacity-90 text-xs md:text-base font-semibold font-montserrat leading-normal dark:text-text">
                    Transfer NFT
                  </div>
                </div>
              </div>
              <div className="self-stretch  py-2 mt-2 md:mt-4 flex-col justify-center items-center gap-2 flex">
                <div className="w-full px-5 py-4 rounded-2xl border border-violet-400 border-opacity-30 justify-center items-center gap-6 inline-flex">
                  {nftDetails ? (
                    <div className="w-full p-[2.52px] bg-white rounded-lg shadow border-2 border-neutral-50 flex-col justify-center items-center gap-[6.52px] inline-flex">
                      <div className="w-[95.96px] h-[112.64px]  relative">
                        <img
                          className="w-[95.96px] h-[112.64px]  left-0 top-0 absolute rounded"
                          src={nftDetails?.nftInfo?.content?.preview?.url}
                        />
                        <div className="w-[19.54px] h-[19.59px] left-[4.88px] top-[6.12px] absolute shadow justify-start items-center gap-[5.59px] inline-flex">
                          <div className="w-[19.54px] h-[19.59px] pl-0.5 pr-[2.44px] pt-0.5 pb-[2.45px] bg-white rounded-[19.56px] border border-white backdrop-blur-[2.45px] flex-col justify-center items-center inline-flex">
                            <img
                              className="w-[15.14px] h-[15.18px]"
                              src={nftDetails?.chainLogo}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch px-[4.89px] py-[6.52px] rounded-[13.04px] justify-start items-center gap-[6.52px] inline-flex">
                        <div className="grow shrink basis-0 h-3.5 justify-between items-center flex">
                          <div className="text-center text-neutral-500 text-xs font-semibold font-['Montserrat'] leading-[13.70px]">
                            {nftDetails?.nftInfo?.name}
                          </div>
                          <div className="text-center text-zinc-400 text-xs font-semibold font-['Montserrat'] leading-[13.70px]">
                            x{params?.nftDetails?.amount}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NftAsset />
                  )}
                  <div className="grow shrink basis-0 flex-col justify-center items-start inline-flex">
                    <div className="grow shrink basis-0 py-3  justify-center items-center gap-3 inline-flex">
                      <div
                        className="h-[35px] w-[35px] rounded-[50%] flex justify-center items-center"
                        style={{ backgroundImage: senderAvatar?.background }}
                      >
                      {senderAvatar?.avatar &&  <img
                          className="w-[35px] h-[35px] rounded-[50px]"
                          src={senderAvatar?.avatar}
                        />
                      }
                      </div>
                      <div className="px-2 justify-start items-center inline-flex">
                        <div className="text-center text-zinc-500 text-[10px] md:text-base font-semibold font-montserrat leading-[16.80px] ">
                          {/* {params?.fromTriaName} */}
                          You
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch px-1.5 py-1 justify-start items-center gap-1 inline-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                      >
                        <path
                          d="M18.0697 14.6799L11.9997 20.7499L5.92969 14.6799"
                          stroke="#808080"
                          stroke-opacity="0.8"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M12 3.75V20.58"
                          stroke="#808080"
                          stroke-opacity="0.8"
                          stroke-width="1.5"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="grow shrink basis-0 py-3  justify-center items-center gap-3 inline-flex">
                      <div
                        className="h-[35px] w-[35px] rounded-[50%] flex justify-center items-center"
                        style={{ backgroundImage: recipientAvatar?.background }}
                      >
                        {recipientAvatar.avatar ? (
                          <img
                            className="w-[35px] h-[35px] rounded-[50px]"
                            src={recipientAvatar?.avatar}
                          />
                        ) : recieverTriaName?.includes("@") ? (
                          <div className="font-extrabold font-montserrat text-2xl text-black">
                            {recieverTriaName?.charAt(0)}
                          </div>
                        ) : (
                          <div className="font-extrabold font-montserrat text-2xl text-black">
                            {recieverTriaName?.charAt(
                              recieverTriaName?.length - 1
                            )}
                          </div>
                        )}
                      </div>
                      <div className="px-2 justify-start items-center inline-flex">
                        <div className="text-center text-zinc-500 text-[10px] md:text-base font-semibold font-montserrat leading-[16.80px]">
                          {recieverTriaName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <NetworkFee
                  counter={counter}
                  feeLoading={feeLoading}
                  gasFees={gasFees}
                  tokenDetails={tokenDetails}
                  totalAmountInUSD={totalAmountInUSD}
                  totalAmountIncrypto={totalAmountIncrypto}
                  chainName={params?.chainName || ""}
                />
              </div>
              <div className="">
               {error && <ErrorMessage error={error}/>}
            </div>
              <FooterButtons
                handleReject={handleRejectClick}
                approveButtonDisable={approveButtonDisable}
                callFunction={sendNFT}
              />
            </div>
          )}
        {/* </>
      )} */}
    </div>
  );
}