import React, { useState, useEffect, useContext } from 'react'
import { WalletController } from '@tria-sdk/web';
import { useParams } from 'react-router-dom'
import { useTriaUser } from "../../contexts/tria-user-provider";
import { walletType, baseUrl } from "../../utils/constants";
import { AssetDetails, AvatarItem, DecryptParams, postMessageResponse } from "../../types";
import Navbar from "../../Components/Navbar";
import DappDetails from '../../Components/DappDetails';
import ErrorMessage from '../../Components/Popup/ErrorMessage';
import NavContext from '../../NavContext';
import Footer from '../../Components/Footer';



const initialParams: DecryptParams = {
  chainName: '',
  encryptedData: '',
  environment: "mainnet",
  dappDomain: '',
  dappLogo: ''
}


const initialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)"
}




function Decrypt() {
  const [loading, setLoading] = useState(true);
  const [connect, setConnect] = useState(false);
  const [params, setParams] = useState<DecryptParams>();
  const { getAssetDetails, getAvatarsByTriaNames } = useTriaUser();
  const [tokenDetails, setTokenDetails] = useState<AssetDetails>();
  const param = useParams();
  const [senderAvatar, setSenderAvatar] = useState<AvatarItem>(initialAvatar);
  const [triaName, setTriaName] = useState<string>("");
  const [text, setText] = useState("");
  const [error, setError] = useState<string>("");
  const [copied, setIsCopied] = useState(false);
  const [dataDecryptionSuccess, setDataDecryptionSuccess] = useState<boolean>(false);
  const { isDarkMode } = useContext(NavContext);

  const sendMessageToParent = (data: postMessageResponse) => {
    // Post a message to the parent window
    console.log("event emitted");
    window.parent.postMessage({ type: 'closeIframe', callFrom: 'decrypt', data: data }, '*');
  };


  console.log("params----->", params);

  console.log("data",
    btoa(JSON.stringify({
      chainName: "POLYGON",
      encryptedData: "{\"version\":\"x25519-xsalsa20-poly1305\",\"nonce\":\"voGXwamUcE6L2x6ytfNrSgqLTp5ubPNc\",\"ephemPublicKey\":\"Epxu3BgMJ/5+Wlg0YYAPLIYZBHGyM3+Hu8L6aYpjsV8=\",\"ciphertext\":\"6rU/tnJx+3t+D7wA8Oc4aKtCD0RqbL9eM+10ItpJMoahyD51tYQ=\"}",
      dappDomain: "https://facebook.com"
    })));

  const decrypt = async () => {
    try {
      const wallet = new WalletController({
        baseUrl,
        walletType,
        selectedChainName: params?.chainName,
        environment: params?.environment,
      });
      await wallet.init();
      console.log("Decrypt it", params?.encryptedData);
      const res = await wallet.decrypt(params?.encryptedData || "");
      console.log({ res });
      if (res.success) {
        setDataDecryptionSuccess(true);
        setText(res?.data || "");
        sendMessageToParent(res);
      }
      else {
        setError(res?.message || "");
      }
    }
    catch (err) {
      console.log("err", err);
      setError('Decryption failed. Please check your input.');
    }
  };


  const copyToClipboard = () => {
    if (text) {
      setIsCopied(false)
      navigator.clipboard.writeText(text).then(() => {
        setTimeout(() => setIsCopied(true), 200)

      }).catch((error) => {
        console.error("Failed to copy: ", error);
      });
    }
  };

  const stringToHex = (str) => {
    if (!str) return '';
    console.log("strrr", str);
    let hex = '';
    for (let i = 0; i < str?.length; i++) {
      const charCode = str?.charCodeAt(i);
      const hexValue = charCode?.toString(16);
      // Pad with zeros to ensure two-digit representation
      hex += hexValue.padStart(2, '0');
    }
    return hex;
  };

  const setStateParams = async () => {
    console.log("setting params-->")
    try {
      //@ts-ignore
      if (param?.param) {
        //@ts-ignore
        const encodedParams = param.param;
        console.log("encodedParams", typeof (encodedParams));
        // Decode the string
        const jsonString = atob(encodedParams);
        // Parse the JSON
        const jsonData = JSON.parse(jsonString);
        setText(stringToHex(jsonData?.encryptedData));
        console.log("jsonString", jsonData);
        const triaName = JSON.parse(localStorage.getItem("tria.wallet.store") || "{}")?.triaName;
        setTriaName(triaName);
        if (triaName) {
          const resp = await getAvatarsByTriaNames([triaName]);
          if (Object.keys(resp).length > 0) {
            const avatar_details = resp[triaName]?.[0];
            setSenderAvatar({ avatar: avatar_details?.avatar, background: avatar_details?.background });
          }
        }
        setParams(jsonData);
        getAsset(jsonData, triaName);
      }
    }
    catch (err) {
      console.log(err);
    }

  };

  const getAsset = async (asset: any, triaName: string) => {
    try {
      console.log("start----------------------->");
      const response = await getAssetDetails(
        asset?.chainName, asset?.tokenAddress, triaName
      );
      setLoading(false);
      setTokenDetails(response);
      console.log("asset----------------------->", response);
    }
    catch (err) {
      console.log(err);
    }
  }


  useEffect(() => {
    setStateParams();
  }, []);

  console.log("param?.encryptedData", params?.encryptedData);


  return (
    <div>
      <div className="w-full h-screen bg-white dark:bg-fontLightColor rounded-2xl p-4 flex-col justify-between items-center inline-flex">
        {/* {loading ? <div className="w-[448px] h-[840px] p-4 flex-col justify-center items-center inline-flex"><Loader1 /></div> : <> */}
        <div className="w-full justify-end items-start inline-flex">
          <div className="absolute top-0 left-0 ">
            {" "}
            <img className='w-3/4' src="/icons/back.svg" />
          </div>
          {/* <img
            className="dark:visible invisible W-[0] dark:W-18px cursor-pointer"
            src="/icons/ShapeW.svg"
            onClick={() => sendMessageToParent({ success: false, message: "User rejected the request." })}
          ></img> */}
        </div>
        <div className=" px-5 py-2 flex-col justify-center items-center gap-4 flex">
          <Navbar
            loading={loading}
            senderAvatar={senderAvatar}
            chainName={params?.chainName || ""}
            tokenDetails={tokenDetails}
            triaName={triaName}
          />
          <DappDetails params={params} />
        </div>
        <div className="w-full mt-3 px-4 py-0 flex-col justify-center items-start gap-3 inline-flex">
          <div className="self-stretch justify-center items-center gap-2 inline-flex">
            <div className="text-center text-white text-opacity-80 text-sm font-semibold font-['Montserrat'] leading-normal">
              Decrypt message request
            </div>
          </div>
          <div className="self-stretch px-2 justify-start items-center inline-flex">
            <div className="grow shrink basis-0 text-center text-zinc-400 text-xs font-normal font-montserrat leading-tight">
              The website above would like to read this message to complete your
              action. Do you accept the action?
            </div>
          </div>
        </div>
        <div className="w-full mt-4 px-2 flex-col justify-start align-start items-start gap-2 text-center break-all font-Montserrat scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-stone-900  overflow-y-auto">
          <div className="">
            <div className="text-stone-950 dark:text-text text-opacity-60 text-xs font-normal font-['Montserrat']  gap-2 flex overflow-none">
              {dataDecryptionSuccess ? text : text?.substring(0, 220)}
            </div>

            {!dataDecryptionSuccess &&
              <div
                className=" w-[142px] py-2 mt-2 mb-2 ring-1 ring-gray-100  bg-white dark:bg-fontLightColor flex-col justify-center items-center gap-2 inline-flex cursor-pointer"
                onClick={() => decrypt()}
              >
                <div className="w-4 h-4 justify-center items-center inline-flex ">
                  <div className="w-4 h-4 relative">
                    <img src={isDarkMode ? "/icons/lock.svg" : "/icons/lockdark.svg"} alt="" />
                  </div>
                </div>
                <div className="px-2 justify-start items-center inline-flex">
                  <div className="text-center text-stone-950 dark:text-text text-opacity-60 text-xs font-medium font-['Montserrat] leading-[16.80px]">
                    Decrypt message
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
        {dataDecryptionSuccess && (
          <div
            className="w-full h-[34px] mt-4 p-2 justify-start items-center inline-flex cursor-pointer"
            onClick={() => copyToClipboard()}
          >
            <div className="h-[18px] justify-start items-center gap-2 flex">
              <div className="w-[18px] h-[18px] relative">
                <div className="w-[18px] h-[18px] left-0 top-0 absolute">
                  <img src="/icons/copy.svg" alt="" />
                </div>
              </div>
              {!copied ? <div className="grow shrink basis-0 text-stone-950 dark:text-text text-opacity-40 text-sm font-semibold font-Montserrat">
                Copy to clipboard
              </div> : <div className="grow shrink basis-0 text-green-700 text-opacity-80 text-sm font-semibold font-Montserrat">
                Copied to clipboard
              </div>
              }
            </div>
          </div>
        )}
        <div className=" self-stretch grow shrink basis-0 flex-col  gap-2 flex">
          <div className="self-stretch grow shrink basis-0 flex-col justify-end items-center gap-2 flex">
            <div className="h-16 ">
              {error && <ErrorMessage error={error} />}
            </div>
            <div className="w-full h-[53px] justify-center items-center gap-2 inline-flex">
              <div
                className="grow shrink basis-0 p-2 bg-white rounded-[58px] border border-zinc-500 border-opacity-30 justify-center items-center flex cursor-pointer"
                onClick={() => sendMessageToParent({ success: false, message: "User rejected the request." })}
              >
                <div className="justify-center items-center flex">
                  <div className="text-center text-stone-950 text-opacity-80 text-xs md:text-lg font-semibold font-montserrat leading-snug">
                    Reject
                  </div>
                </div>
              </div>
              <div
                className="grow shrink basis-0 p-2 bg-gradient-to-r from-violet-400 to-indigo-500 rounded-[58px] justify-center items-center flex cursor-pointer"
                onClick={() => decrypt()}
              >
                <div className="justify-center items-center flex">
                  <div className="text-center text-white text-xs md:text-lg font-semibold font-montserrat leading-snug">
                    Decrypt
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
        {/* </>} */}
      </div>
    </div>
  );
}

export default Decrypt;