export const walletType = { embedded: true }
export const baseUrl = process.env.REACT_APP_BASE_URL || ""
export const walletUrl = "https://wallet.tria.so"
export const socketUrl = process.env.REACT_APP_SOCKET_URL || ""

type KEYRING_ENV = "testnet" | "mainnet" | "mainnet-staging"

let env: KEYRING_ENV | undefined

const keyring_env: string = process.env.REACT_APP_KEYRING_ENV || "mainnet"

export { keyring_env, env }
