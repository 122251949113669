import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import NavContext from '../../NavContext';

export default function Nav() {
  const navigate = useNavigate();

  const { dappLogo } = useContext(NavContext)

  return (
    <div>
      <div className="h-[221px] px-5 py-6 flex-col justify-center items-center gap-4 flex">
        <div className="self-stretch py-2  justify-center items-center inline-flex">
          <div className=" flex-col justify-center items-center gap-2 inline-flex">
            <img className="w-[95px] h-[95px]" src={dappLogo} alt="dapp_logo" />
          </div>
        </div>
      </div>
    </div>
  )
}
