import React, { useContext, useEffect, useState } from "react";
import Nav from "../../Components/Nav";
import ContinueWithTria from "../../Components/ContinueWithTria";
import EmailAndSocial from "../../Components/EmailAndSocial";
import ConnectWallet from "../../Components/ConnectWallet";
import HomeBackgroundVector from "../../Components/HomeBackgroundVector";
import Footer from "../../Components/Footer";
import { useListenerSO } from "@tria-sdk/connect";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import { detectIncognito } from "detectincognitojs";
import { socket } from "../../../src/utils/init";

export default function Test() {
  const callButton = () => {
    window.open(
      `https://auth.tria.so/unityLogin/?userId=51651651&dappLogo=https://www.empireofsight.com/assets/images/logo-icon.svg&dappName=Empire%20of%20Sight`,
      "SSO",
      `width=${500},height=${600},left=${0},top=${0}`
    );
  };

  useEffect(() => {
    socket.on("messageV2", (data) => {
      console.log("socket data", data);
    });
    socket.emit("loginV2", {
      userId: "51651651",
    });
  }, []);

  return (
    <div className="">
      <button onClick={() => callButton()} className="bg-red-200 h-20 w-80">
        Login from Unity
      </button>
    </div>
  );
}
