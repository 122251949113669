import React, { useContext } from 'react';
import { signMessageParams, AssetDetails, AvatarItem } from '../../types';
import { SingleLine } from '../Loaders';


interface Props {
  loading: boolean,
  senderAvatar: AvatarItem,
  chainName: string;
  tokenDetails?: AssetDetails,
  triaName: string
}



const Nav: React.FC<Props> = ({ loading, senderAvatar, chainName, tokenDetails, triaName }) => {
  return (
    <div className='w-[90vw]'>
      <div className="w-full border-b-2 border-zinc-500 border-opacity-10 items-center justify-between flex">
        <div className="justify-start items-center gap-0 flex ">
          <div className="w-full h-[50px]">
            {
              <div
                className="h-[36px] w-[36px] rounded-[18px] flex justify-center items-center"
                style={{ backgroundImage: senderAvatar?.background }}
              >
                {senderAvatar?.avatar &&  <img
                    className="w-8 h-8 rounded-[22.50px] left-0 top-0 "
                    src={senderAvatar?.avatar} alt=""
                  />
                }
              </div>
            }

<div className="w-[20px] h-[20px] rounded-full pl-[0.69px] pr-[0.52px] pb-[0.35px] left-7 -top-10 relative justify-center items-center inline-flex">
    <img src={tokenDetails?.logoUrl} className="rounded-full"></img>
    <div className="w-[10.55px] h-[5.55px] relative origin-top-left rotate-[-19.05deg] flex-col justify-start items-start flex" />
</div>
          </div>
          <div className="flex-col ml-1 justify-center items-start inline-flex">
            <div className="h-[14px] px-2 flex-col justify-center items-start flex">
              <div className="text-center text-neutral-500 text-[10px] font-normal font-montserrat leading-[16.80px]">
                {chainName}
              </div>
            </div>
            <div className="px-2 justify-start items-center inline-flex">
              <div className="text-center text-neutral-500 text-[10px] font-semibold font-montserrat leading-[16.80px]">
              {triaName?.length > 20 ? (
                  <>
                    {triaName?.substring(0, 12)}
                    ...
                    {triaName?.substring(triaName?.length - 5)}
                  </>
                ) : (
                  <div>{triaName}</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="py-3 flex-col justify-center items-end  inline-flex">
          <div className="px-2 flex-col justify-center items-end flex">
            <div className="text-right text-neutral-500 text-[10px] font-normal font-montserrat leading-[16.80px]">
              Balance
            </div>
          </div>
          <div className="px-2 justify-start items-center inline-flex">
            <div className="text-right text-neutral-500 text-[10px] font-semibold font-montserrat leading-[16.80px]">
              {" "}
              {loading?<SingleLine/> : <> {tokenDetails?.balanceInTokens.toFixed(8)} {tokenDetails?.symbol}{" "}</>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Nav;