// @ts-nocheck
import React, { useState, useContext, useEffect } from "react"
import Nav from "../../Components/Nav"
import { useNavigate } from "react-router-dom"
import Footer from "../../Components/Footer"
import keyringController from "../../utils/init"
import axios from "axios"
import { useParams } from "react-router-dom"
import NavContext from "../../NavContext"
import { checkDidAvailability, getDidRecommendations } from "../../utils"
import Loader from "../../Components/Loader"
import { keyring_env, env } from "../../utils/constants"
import ToC from "../../Components/SignUp/ToC"
import HomeBackgroundVector from "../../Components/HomeBackgroundVector"
import { eventTypes } from "@tria-sdk/connect"

export default function SignUpIFrame() {
  // const [showPassword,setShowPassword]= useState(false);
  // const [showEmail,setShowEmail]=useState(false);
  // const toggleState=()=>{
  //     setShowPassword(!showPassword);
  //     setShowEmail(false);
  //   }

  const baseUrl = process.env.REACT_APP_BASE_URL || ""

  const { dappName, isDarkMode } = useContext(NavContext)

  const [recommendations, setRecommendations] = useState([])
  const [available, setAvailable] = useState()
  const [name, setName] = useState("")
  const [loader, setLoader] = useState(false)
  const [conditions, setConditions] = useState(true)
  const [checkLoader, setCheckLoader] = useState(true)

  const userId = useParams()
  const handle = useParams()
  const user = useParams()
  const token = useParams()

  const walletType = {
    embedded: true,
  }

  const getNameRecommendations = async (name) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v2/get-name-recommendation?name=${name?.toLowerCase()}`
      )
      console.log("recommed", data?.data)
      setRecommendations(data?.data)
    } catch (err) {
      console.log(err)
    }
  }

  const checkIfAvailable = async (name) => {
    try {
      const { data } = await axios.post(`${baseUrl}/api/v1/did/check`, {
        did: name?.toLowerCase() + "@tria",
      })
      console.log("did", data?.response?.availabilityStatus)
      setAvailable(data?.response?.availabilityStatus)
    } catch (err) {
      console.log(err)
    }
  }
  const [Id, setId] = useState("")

  async function createAccountWithoutPassword() {
    if (name?.length > 3) {
      setLoader(true)
      try {
        console.log("token ---->>>", token)

        const fromClientId =
          "eyJjbGllbnRJZCI6ImI0OGQ4MjMwLTU3ZjktNDNmYi1hOTUyLTcyMjY2OGJiMzUyMCIsInByb2plY3RJZCI6ImY1YzlhYTJjLWE5NGUtNDJjNS1hODViLWI5NDNmMDdiOGJjOCJ9"

        const res = await keyringController.socialogin({
          triaName: name?.toLowerCase() + "@tria",
          //@ts-ignore
          platform: handle?.param1,
          //@ts-ignore
          userId: userId?.param,
          isPasswordLess: true,
          //@ts-ignore
          password: null,
          //@ts-ignore
          accessToken: token?.param3,
          //@ts-ignore
          origin: document.referrer,
          fromClientId: fromClientId,
        })
        console.log("res", res.success)
        if (res.success === true) {
          keyringController.postMessage(
            {
              type: "Social Sign up",
              success: true,
              data: JSON.parse(
                localStorage.getItem("tria.wallet.store") || "{}"
              ),
            },
            true
          )
          console.log("successful login")
          setTimeout(() => {
            setLoader(false)
            //window.close()
          }, 2000)
        } else {
          setLoader(false)
        }
        // setUserData({ address: res.address });
      } catch (err) {
        setLoader(false)
        console.log(err)
      }
    }
  }
  const navigate = useNavigate()

  const checkSpecialChar = (e) => {
    if (!/[0-9a-zA-Z]/.test(e.key)) {
      e.preventDefault()
    }
  }

  const check = async () => {
    //@ts-ignore
    const refined_email = user?.param2.replace(/_/g, "")
    if (refined_email.length !== 0) {
      const more_refined_email = String(refined_email)?.toLowerCase()
      console.log("more refined email -->", more_refined_email)
      console.log("check", await checkDidAvailability(more_refined_email))
      if ((await checkDidAvailability(more_refined_email)) === true) {
        console.log("name after check", more_refined_email)
        setName(more_refined_email)
        checkIfAvailable(more_refined_email)
        getNameRecommendations(more_refined_email)
        setTimeout(() => {
          setCheckLoader(false)
        }, 1000)
      } else {
        const suggestedName = await getDidRecommendations(more_refined_email)
        setName(suggestedName)
        checkIfAvailable(suggestedName)
        getNameRecommendations(suggestedName)
        setTimeout(() => {
          setCheckLoader(false)
        }, 1000)
      }
    }
  }

  const goBack = () => {
    keyringController.postMessage(
      {
        type: eventTypes.passMessage,
        message: {
          go_back: true,
        },
      },
      true
    )
  }

  useEffect(() => {
    check()
  }, [])

  return (
    <div>
      {checkLoader === false ? (
        <div className="w-full h-screen p-3 bg-white dark:bg-fontLightColor rounded-2xl flex-col justify-between items-center font-['Montserrat'] inline-flex">
          <div className='absolute top-0 left-0 '>
            {" "}
            {isDarkMode ? (
              <img className='w-3/4' src='/icons/back.svg' />
            ) : (
              <div className='-ml-8 w-3/4 '>
                <HomeBackgroundVector />
              </div>
            )}
          </div>
          <div>
            <div className='w-[100vw] justify-between items-start inline-flex'>
              <div className='p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-center inline-flex'>
                <div className='relative'>
                  <div className='mt-11'></div>
                </div>
              </div>
              <div className='p-3 rounded-[39px] flex-col justify-center items-center gap-2 inline-flex'>
                {/* <img src='/icons/Shape.svg'></img> */}
              </div>
            </div>
            <div className='px-5 py-6 flex-col justify-center -mt-20  gap-4 flex'>
              <div className='self-stretch py-2  justify-center  gap-2 inline-flex'>
                <div className=' flex-col justify-center  gap-2 inline-flex'>
                  {/* @ts-ignore */}
                  <img
                    className='w-[75px] h-[75px]'
                    src={
                      JSON?.parse(localStorage.getItem("dapp.details"))
                        ?.dappLogo
                    }
                  />
                </div>
              </div>
              <div className='self-stretch py-0 flex-col justify-center items-start gap-4 flex'>
                <div className='self-stretch justify-center  gap-2 inline-flex'>
                  {/* @ts-ignore */}
                  <div className="text-center text-stone-950 text-opacity-80 text-sm font-medium font-['Montserrat'] leading-snug dark:text-text">
                    Login to{" "}
                    {
                      JSON?.parse(localStorage.getItem("dapp.details"))
                        ?.dappName
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='self-stretch  flex-col justify-center  items-center gap-2 flex'>
            <div className='self-stretch  flex-col justify-start  items-center gap-3 flex rounded-md'>
              <div className='w-full px-3 py-3 rounded-2xl mt-auto border border-violet-400 border-opacity-30 flex-col justify-center items-center gap-1 inline-flex'>
                <div className='self-stretch py-0 flex-col justify-center items-start gap-0 flex mb-1'>
                  <div className='self-stretch justify-start items-center gap-0 inline-flex '>
                    <div className="mix-blend-difference text-center text-white text-opacity-80 text-xs font-medium font-['Montserrat']  leading-snug">
                      Create your username
                    </div>
                  </div>
                </div>
                <div className='w-full px-0 justify-start items-center inline-flex ml-2 -mt-1'>
                  <div className='grow shrink basis-0 mix-blend-difference'>
                    <div>
                      <span
                        className='text-[10px] md:text-xs'
                        style={{
                          color: "white",
                          opacity: 0.5,
                          fontWeight: "normal",
                        }}
                      >
                        This will be your in-game name.
                      </span>
                    </div>
                  </div>
                </div>
                <div className='self-stretch  flex-col justify-center items-center flex'>
                  <div className='self-stretch py-1 justify-center items-center gap-2 inline-flex'>
                    <div className=' h-7 text-xs px-1 py-0 bg-zinc-500 bg-opacity-10 w-full rounded-[20px] justify-between items-center flex '>
                      <input
                        style={{ textTransform: "lowercase" }}
                        onKeyDown={(e) => {
                          checkSpecialChar(e)
                        }}
                        className=" bg-transparent px-2 py-2 font-['Montserrat'] focus:outline-none dark:text-white text-black text-[10px] md:text-base"
                        placeholder='Your name'
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value)
                          getNameRecommendations(e.target.value)
                          checkIfAvailable(e.target.value)
                        }}
                      />
                    </div>
                    <button
                      onClick={() => {
                        if (loader === false && available === true) {
                          createAccountWithoutPassword()
                        }
                      }}
                      disabled={!conditions}
                      className={
                        conditions === true
                          ? " cursor-pointer h-7 px-5 py-3 mix-blend-difference bg-white bg-opacity-90 rounded-[20px] justify-center items-center flex"
                          : " cursor-not-allowed h-7 px-5 py-3 mix-blend-difference bg-white bg-opacity-90 rounded-[20px] justify-center items-center flex"
                      }
                    >
                      <div className='justify-center  items-center flex'>
                        <div>
                          {" "}
                          <div className='text-center text-stone-950 text-xs font-semibold font-Montserrat leading-tight'>
                            {loader === false ? (
                              <span>Next</span>
                            ) : (
                              <div className='flex space-x-1 justify-center items-center '>
                                <div
                                  style={{ backgroundColor: "gray" }}
                                  className='h-[6px] w-[6px] bg-white rounded-full animate-bounce [animation-delay:-0.3s]'
                                ></div>
                                <div
                                  style={{ backgroundColor: "gray" }}
                                  className='h-[6px] w-[6px] bg-white rounded-full animate-bounce [animation-delay:-0.15s]'
                                ></div>
                                <div
                                  style={{ backgroundColor: "gray" }}
                                  className='h-[6px] w-[6px] bg-white rounded-full animate-bounce'
                                ></div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
                {name.length !== 0 ? (
                  <div className='self-stretch justify-start items-center gap-2 inline-flex'>
                    {available === true ? (
                      <div className="text-center text-green-600 text-xs font-semibold font-['Montserrat'] leading-[16.80px] flex gap-1 items-center">
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            d='M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z'
                            fill='#14AE5C'
                          />
                        </svg>
                        username available
                      </div>
                    ) : (
                      <div className="text-center text-red-500 text-xs font-semibold font-['Montserrat'] leading-[16.80px] flex gap-1 items-center">
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='16'
                          viewBox='0 0 24 24'
                          fill='none'
                        >
                          <path
                            d='M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z'
                            fill='#DA4343'
                          />
                        </svg>
                        username not available
                      </div>
                    )}
                  </div>
                ) : null}
                {name.length > 0 ? (
                  <div className='w-full'>
                    <div className='self-stretch justify-start items-center gap-2 inline-flex mt-4'>
                      <div className='mix-blend-difference text-center text-gray-400 text-[11px] font-medium font-Montserrat leading-snug'>
                        Recommended :{" "}
                      </div>
                    </div>
                    <div className='self-stretch justify-start items-center gap-2 inline-flex'>
                      <div className='flex flex-wrap gap-1 items-center w-full px-0 h-16 overflow-y-auto'>
                        {recommendations?.slice(0, 3)?.map((item, index) => {
                          return (
                            <div
                              onClick={() => {
                                setName(item)
                                checkIfAvailable(item)
                              }}
                              key={index}
                              className='self-stretch cursor-pointer justify-start items-center gap-2 inline-flex mt-1'
                            >
                              <div className='bg-zinc-500 bg-opacity-5 dark:text-white text-black text-[10px] dark:text-opacity-80 text-opacity-80 rounded-full px-5 py-2 font-Montserrat'>
                                {item}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className='w-full  py-0 justify-center items-center gap-2 inline-flex'>
                  <div>
                    <input
                      type='checkbox'
                      className='w-3 h-3'
                      checked={conditions}
                      onChange={() => setConditions(!conditions)}
                    />
                  </div>
                  <ToC />
                </div>
              </div>
            </div>
            <div className='-mt-2'>
              <Footer />
            </div>
          </div>
        </div>
      ) : (
        <div className='fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Loader />
        </div>
      )}
    </div>
  )
}
