import React, { useState, useEffect, useContext } from 'react';
import Navbar from '../Navbar/index';
import NavContext from "../../NavContext";
import Loader1 from '../Loader';
import { AssetDetails, signMessageParams, AvatarItem } from '../../types';
import DappDetails from '../DappDetails';
import Loader from './Loader';
import ErrorMessage from '../Popup/ErrorMessage';
import Footer from '../Footer';


interface Props {
  error: string,
  approveLoading: boolean,
  senderAvatar: AvatarItem
  params: signMessageParams;
  signMessage: () => Promise<void>;
  tokenDetails?: AssetDetails;
  sendMessageToParent: (data: any) => void;
  loading: boolean
  triaName: string
}

const Sign: React.FC<Props> = ({ error, approveLoading, senderAvatar, triaName, params, signMessage, tokenDetails, sendMessageToParent, loading }) => {



  return (
    <div>
      <div className="w-full h-screen bg-white dark:bg-fontLightColor rounded-2xl p-3 flex-col justify-between items-center inline-flex">
        {/* {loading ? <div className="w-[448px] h-[840px] p-4 flex-col justify-center items-center inline-flex"><Loader1 /></div> : <> */}
        {approveLoading ? (
          <div>
            <div>
              <Loader />
            </div>
          </div>) : (
          <>
            <div className="w-full justify-end items-start inline-flex mt-2 mr-4">
              <div className="absolute top-0 left-0 ">
                {' '}
                <img className='w-3/4' src="/icons/back.svg" />
              </div>
              {/* <img className="dark:visible invisible W-[0] dark:W-18px cursor-pointer h-3 w-3 " src="/icons/ShapeW.svg" onClick={() => sendMessageToParent({ success: false, message: "User rejected the request." })}></img> */}
            </div>
            <div className=" w-[100vw] flex-col justify-center items-center gap-4 flex">
              <Navbar loading={loading} senderAvatar={senderAvatar} chainName={params?.chainName || ""} tokenDetails={tokenDetails} triaName={triaName} />
              <DappDetails params={params} />
            </div>
            <div className="px-5 py-0 -mt-5 flex-col justify-center items-center gap-4 flex">
              <div className="self-stretch h-[98px] py-3 flex-col justify-center items-start gap-4 flex">
                <div className="self-stretch justify-center items-center gap-2 inline-flex">
                  <div className="text-center text-neutral-500 text-sm font-semibold font-montserrat leading-normal dark:text-text">
                    Sign Message
                  </div>
                </div>
                <div className="w-full px-2 justify-start items-center inline-flex">
                  <div className="grow shrink basis-0 text-center text-neutral-500  text-[9px] font-normal font-montserrat leading-[16.80px]">
                    Only sign this message if you fully understand the content and trust the requesting
                    site.
                  </div>
                </div>
              </div>
            </div>
            <div className="self-stretch grow shrink basis-0 flex-col justify-center items-center gap-2 flex">
              <div className="self-stretch grow shrink basis-0 flex-col justify-center items-center gap-2 flex">
                <div className='text-neutral-500 text-xs font-medium font-montserrat flex w-full justify-start ml-3'>
                  Message:
                </div>
                <div className='rounded-2xl border-2 border-violet-400 border-opacity-30 w-full h-1/2'>
                  <div className='text-neutral-500 text-opacity-90 text-xs font-normal font-montserrat p-2'>
                    {typeof params?.message === 'string' ? params?.message : <pre>{JSON.stringify(params?.message, null, 2)}</pre>}
                  </div>
                </div>
                <div className="h-16 mt-5">
                  {error && <ErrorMessage error={error} />}
                </div>
                <div className="w-full justify-center items-center gap-3 inline-flex">
                  <div className="grow shrink basis-0  p-2 bg-white rounded-[58px] border border-zinc-500 border-opacity-30 justify-center items-center flex cursor-pointer"
                    onClick={() => sendMessageToParent({ success: false, message: "User rejected the request." })}>
                    <div className="justify-center items-center flex">
                      <div className="text-center text-stone-950 text-opacity-80 text-xs font-semibold font-montserrat leading-snug">
                        Reject
                      </div>
                    </div>
                  </div>
                  <div className="grow shrink basis-0 p-2 bg-gradient-to-r from-violet-400 to-indigo-500 rounded-[58px] justify-center items-center flex cursor-pointer"
                    onClick={() => signMessage()}>
                    <div className="justify-center items-center flex">
                      <div className="text-center text-white text-xs font-semibold font-montserrat leading-snug">
                        Approve
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='' style={{ transform: "scale(0.7)"}} >
                <div className="w-full -mb-16 -mt-2 py-3 rounded-[44px] justify-start items-start inline-flex">
                  <div className="grow shrink basis-0 justify-center items-center gap-2 flex">
                    <div className="w-[19px] h-[19px] relative rounded-[5px]" />
                    <img src="/icons/logo.svg" alt="logo" />
                    <div className="text-center text-zinc-500 text-opacity-40 text-sm font-semibold font-['Montserrat'] leading-[16.80px]">Powered by Tria</div>
                  </div>
                </div>
              </div>
            </div>
          </>)}
        {/* </>} */}
      </div>
    </div>
  );
}


export default Sign;