import React from "react";
import "./index.css";

export const NftAsset: React.FC = () => {
  return (
    <div className="  p-2  dark:bg-fontLightColor bg-gray-300 rounded-[10px] border-2 border-zinc-300 border-opacity-10 flex-col justify-center items-center gap-2 inline-flex">
   <div className="w-[95.96px] h-[112.64px] relative">
        <div className="w-[95.96px] h-[112.64px] custom-pulse left-0 top-0 absolute bg-gray-300 dark:bg-fontLightColor rounded" />
      </div>
      <div className="self-stretch  flex-col justify-center items-start gap-2 flex">
        <div className="self-stretch grow shrink basis-0 justify-start items-center gap-4 inline-flex">
          <div className="grow shrink basis-0 self-stretch flex-col justify-center items-center gap-2 inline-flex">
            <div className="w-full h-[9px] relative">
              <div className="w-[100%] custom-pulse h-[9px] left-0 top-0 absolute bg-gray-300 dark:bg-fontLightColor rounded-[17px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
