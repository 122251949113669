import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import NavContext from "../NavContext";
import keyringController from "../utils/init";
import Footer from "./Footer";

import { socket } from "../../src/utils/init";
import { checkDidAvailability, getDidRecommendations } from "../utils";

const SignUpWide = () => {
  const { dappName, hash, hashPass, userEmail, otpLogin } =
    useContext(NavContext);
  console.log("otpLogin------------->", otpLogin);
  // const userEmail = "gzb4ytvup6@waterisgone.com"

  const [name, setName] = useState("");
  const [recommendations, setRecommendations] = useState([]);
  const [available, setAvailable] = useState();
  const [loader, setLoader] = useState(false);

  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL || "";

  // useEffect(() => {
  //     setTimeout(() => {
  //         navigate('/welcome')
  //     }, 2000)
  // }, [])

  const getNameRecommendations = async (name) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/v2/get-name-recommendation?name=${name?.toLowerCase()}`
      );
      console.log("recommed", data?.data);
      setRecommendations(data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const checkIfAvailable = async (name) => {
    try {
      const { data } = await axios.post(`${baseUrl}/api/v1/did/check`, {
        did: name?.toLowerCase() + "@tria",
      });
      console.log("did", data?.response?.availabilityStatus);
      setAvailable(data?.response?.availabilityStatus);
    } catch (err) {
      console.log(err);
    }
  };

  // const call = async () => {
  //     const searchParams = new URLSearchParams(location.search);
  //     const email = searchParams.get('email');
  //     const token = searchParams.get('token');
  //     socket.emit('message', {
  //         "userId": email,
  //         "message": {
  //             token: token
  //         }
  //     })
  //     setEmail(email)
  //     console.log('Email', email);
  //     console.log('Token', token);
  //     const check = await keyringController.verifyCognitoAuth({ email: email, code: token })
  //     console.log('check ', check)
  //     const auth = await keyringController.initiateCognitoAuth({
  //         email: email,
  //         password: localStorage.getItem('tempPass')
  //     })
  //     console.log('auth', auth)
  //     if (auth?.hash !== undefined) {
  //         setOpen(true)
  //         setHash(auth?.hash)
  //         setPassword(auth?.password)
  //     }
  // }

  const call2 = async () => {
    if (name.length > 3) {
      setLoader(true);
      const searchParams = new URLSearchParams(location.search);
      const origin = searchParams.get("origin");
      const type = otpLogin ? "otp" : "link";
      const fromClientId = localStorage.getItem("clientId") || "";
      const res = await keyringController.generateAccountByOTPOrLINK({
        triaName: name.toLowerCase() + "@tria",
        input: userEmail,
        hash: hash,
        password: hashPass,
        type: type,
        origin: document.referrer,
        //@ts-ignore
        accessToken: localStorage.getItem("accessToken"),
        //@ts-ignore
        IdToken: localStorage.getItem("idToken"),
        fromClientId: fromClientId,
      });
      console.log("res", res);
      if (res.success === true) {
        keyringController.postMessage({
          type: "Email Pwd Sign up",
          success: true,
          //@ts-ignore
          data: JSON.parse(localStorage.getItem("tria.wallet.store")), // JSON object // tria-wallet.store
        });
        // const created_wallet_store = localStorage.getItem("tria.wallet.store")
        // socket.emit('message', {
        //     "userId": email,
        //     "message": JSON.parse(created_wallet_store)
        // })
        // setTimeout(() => {
        //     window.close()
        // }, 2000)
      }
      //window.open(`${origin}?verified=true`, "_self")
    }

    // const resp = await keyringController.getVault({
    //     input: email,
    //     link: true,
    //     hash: hash,
    //     password: password
    // })
    // console.log("resp", resp)
  };

  const check = async () => {
    const refined_email = userEmail?.substring(0, userEmail.indexOf("@"));
    if (refined_email.length !== 0) {
      const more_refined_email = String(refined_email)?.toLowerCase();
      console.log("more refined email -->", more_refined_email);
      console.log("check", await checkDidAvailability(more_refined_email));
      if ((await checkDidAvailability(more_refined_email)) === true) {
        console.log("name after check", more_refined_email);
        setName(more_refined_email);
        checkIfAvailable(more_refined_email);
        getNameRecommendations(more_refined_email);
      } else {
        const suggestedName = await getDidRecommendations(more_refined_email);
        setName(suggestedName);
        checkIfAvailable(suggestedName);
        getNameRecommendations(suggestedName);
      }
    }
  };

  useEffect(() => {
    check();
  }, []);

  const checkSpecialChar = (e) => {
    if (!/[0-9a-zA-Z]/.test(e.key)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    socket.emit("loginV2", {
      userId: localStorage.getItem("socketId"),
    });
    const interval = setInterval(() => {
      // console.log("store", localStorage.getItem('tria.wallet.store'))
      if (localStorage.getItem("tria.wallet.store") !== null) {
        socket.emit("messageV2", {
          userId: localStorage.getItem("socketId"),
          //@ts-ignore
          message: JSON.parse(localStorage.getItem("tria.wallet.store")),
        });
        clearInterval(interval);
      }
    }, 1000);
  }, []);

  return (
    <div className="remove-zoom">
      <div className="self-stretch  flex-col justify-center  items-center gap-2 flex">
        <div className="self-stretch  flex-col justify-start  items-center gap-3 flex rounded-md">
          <div className="w-[416px]  px-5 py-4 rounded-2xl mt-auto border border-violet-400 border-opacity-30 flex-col justify-center items-center gap-2 inline-flex">
            <div className="self-stretch py-3 flex-col justify-center items-start gap-4 flex">
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                <div className="mix-blend-difference text-center text-white text-opacity-80 text-lg font-medium font-['Montserrat']  leading-snug">
                  Create your username
                </div>
              </div>
            </div>
            <div className="w-[376px] px-2 justify-start items-center inline-flex">
              <div className="grow shrink basis-0 mix-blend-difference">
                <div>
                  {/* <span style={{ color: 'white', opacity: 0.5, fontSize: '0.875rem', fontWeight: 'normal' }}>Your </span>
                                                <span style={{ color: 'white', opacity: 0.9, fontSize: '0.875rem', fontWeight: 'bold' }}>@tria</span> */}
                  <span
                    style={{
                      color: "white",
                      opacity: 0.5,
                      fontSize: "0.875rem",
                      fontWeight: "normal",
                    }}
                  >
                    This will be your in-game name.
                  </span>
                </div>
              </div>
            </div>
            <div className="self-stretch h-16 flex-col justify-center items-center flex">
              <div className="self-stretch py-3 justify-center items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-10 px-5 py-3 bg-zinc-500 bg-opacity-10 rounded-[20px] justify-between items-center flex">
                  <input
                    style={{ textTransform: "lowercase" }}
                    onKeyDown={(e) => {
                      checkSpecialChar(e);
                    }}
                    className="justify-start bg-transparent px-2 py-2 font-['Montserrat'] focus:outline-none dark:text-white text-black"
                    placeholder="Your name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      getNameRecommendations(e.target.value);
                      checkIfAvailable(e.target.value);
                    }}
                  />
                  {/* <span className='justify-end' style={{ color: 'white', opacity: 0.4, fontSize: '1rem', fontWeight: 'normal' }}>@tria</span> */}
                  {/* <div className='text-gray-700 font-bold font-Montserrat'>@tria</div> */}
                </div>
                <div
                  onClick={() => {
                    if (loader === false && available === true) {
                      call2();
                    }
                  }}
                  className="w-[99px] cursor-pointer h-10 px-5 py-3 mix-blend-difference bg-white bg-opacity-90 rounded-[20px] justify-center items-center flex"
                >
                  <div className="justify-center items-center flex">
                    <button>
                      {" "}
                      <div className="text-center text-stone-950 text-base font-semibold font-Montserrat leading-tight">
                        {loader === false ? (
                          <span>Next</span>
                        ) : (
                          <div className="flex space-x-1 justify-center items-center ">
                            <div
                              style={{ backgroundColor: "gray" }}
                              className="h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.3s]"
                            ></div>
                            <div
                              style={{ backgroundColor: "gray" }}
                              className="h-2 w-2 bg-white rounded-full animate-bounce [animation-delay:-0.15s]"
                            ></div>
                            <div
                              style={{ backgroundColor: "gray" }}
                              className="h-2 w-2 bg-white rounded-full animate-bounce"
                            ></div>
                          </div>
                        )}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {name.length !== 0 ? (
              <div className="self-stretch justify-start items-center gap-2 inline-flex">
                {available === true ? (
                  <div className="text-center text-green-600 text-sm font-semibold font-['Montserrat'] leading-[16.80px] flex gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                        fill="#14AE5C"
                      />
                    </svg>
                    username available
                  </div>
                ) : (
                  <div className="text-center text-red-500 text-sm font-semibold font-['Montserrat'] leading-[16.80px] flex gap-1 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM15.36 14.3C15.65 14.59 15.65 15.07 15.36 15.36C15.21 15.51 15.02 15.58 14.83 15.58C14.64 15.58 14.45 15.51 14.3 15.36L12 13.06L9.7 15.36C9.55 15.51 9.36 15.58 9.17 15.58C8.98 15.58 8.79 15.51 8.64 15.36C8.35 15.07 8.35 14.59 8.64 14.3L10.94 12L8.64 9.7C8.35 9.41 8.35 8.93 8.64 8.64C8.93 8.35 9.41 8.35 9.7 8.64L12 10.94L14.3 8.64C14.59 8.35 15.07 8.35 15.36 8.64C15.65 8.93 15.65 9.41 15.36 9.7L13.06 12L15.36 14.3Z"
                        fill="#DA4343"
                      />
                    </svg>
                    username not available
                  </div>
                )}
              </div>
            ) : null}
            {name.length > 0 ? (
              <div>
                <div className="self-stretch justify-start items-center gap-2 inline-flex mt-4">
                  <div className="mix-blend-difference text-center text-gray-400 text-sm font-medium font-Montserrat leading-snug">
                    Recommended :{" "}
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-2 inline-flex">
                  <div className="flex flex-wrap gap-3 items-center w-[376px] px-2 h-24 overflow-y-auto">
                    {recommendations?.slice(0, 3)?.map((item, index) => {
                      return (
                        <div
                          onClick={() => {
                            setName(item);
                            checkIfAvailable(item);
                          }}
                          key={index}
                          className="self-stretch cursor-pointer justify-start items-center gap-2 inline-flex mt-3"
                        >
                          <div className="bg-zinc-500 bg-opacity-5 dark:text-white text-black dark:text-opacity-80 text-opacity-80 rounded-full px-5 py-2 font-Montserrat">
                            {item}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpWide;
