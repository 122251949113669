import { useSocialLoginConnectorsStack } from "../../socialLogins/socialLoginConnectors"
import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import NavContext from "../../NavContext"
import { smsIcon, callIcon } from "../../socialLogins/socialLoginConnectors"
import keyringController, { socket } from "../../utils/init"
import { eventTypes } from "@tria-sdk/connect"
import { v4 as uuidv4 } from "uuid"
import { setAllLocalStorageFromEncoded } from "../../utils"
// import { verifyAndLogUserIn } from "../../utils"
const SocialConnectorIframe = () => {
  const socialLogins = useSocialLoginConnectorsStack()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const [darkMode, setDarkMode] = useState(true)
  const { isFF, setIsDarkMode } = useContext(NavContext)

  var sBrowser,
    sUsrAg = window.navigator.userAgent

  const { setDappName, setDappLogo, setIsFirefox, setFromClientId } =
    useContext(NavContext)

  const baseUrl = process.env.REACT_APP_BASE_URL || ""
  const walletType = {
    embedded: true,
  }

  const socialLoginClicked = async (socialLoginIndex: number) => {
    const width = window.innerWidth / 2.6
    const top = 0
    const left = 0
    const height = window.innerHeight
    // const width=(window.outerWidth/2)-225
    const socialNetwork = socialLogins[socialLoginIndex].type
    localStorage.setItem("socialNetwork", socialNetwork)
    const fromClientId = localStorage.getItem("clientId") || ""
    console.log("fromClientId", fromClientId)
    const socketId = uuidv4()
    try {
      const call = await axios.get(
        `${baseUrl}/api/v1/auth/oauth/${socialNetwork}?origin=${window?.origin}&fromClientId=${fromClientId}&socketId=${socketId}`
      )
      //console.log('all',call)
      socket.emit("loginV2", {
        userId: socketId,
      })
      socket.on("messageV2", (data: any) => {
        console.log("received socket event", "messageV2", data)
        // await verifyAndLogUserIn(data)
        if (data) {
          console.log("callback data", JSON.parse(atob(data)))
        }
        const userInfo = JSON.parse(atob(JSON.parse(atob(data))?.userInfo))
        console.log("userInfo", userInfo)
        setAllLocalStorageFromEncoded(data)
        if (userInfo?.method === "login") {
          console.log("posting event for login", {
            type: "Log in",
            success: true,
            data: localStorage.getItem("tria.wallet.store"),
          })
          keyringController.postMessage({
            type: "Log in",
            success: true,
            data: JSON.parse(localStorage.getItem("tria.wallet.store") || "{}"),
          })
        } else if (userInfo?.method === "signup") {
          keyringController.postMessage({
            type: eventTypes.passMessage,
            message: {
              accountExists: userInfo?.accountExists,
              userId: userInfo?.userId,
              token: userInfo?.token,
              username: userInfo?.username,
              social: userInfo?.social,
            },
          })
        }
        socket.emit("messageReceived", { status: "success" })
      })
      console.log("json", call?.data?.url)
      const redirect_url = call?.data?.url
      window.open(redirect_url, "_blank")
      //window.open(`${baseUrl}/api/v1/auth/oauth/${socialNetwork}?origin=${window?.origin}`, "SSO", `width=${500},height=${600},left=${0},top=${top}`);
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const appName = searchParams.get("dappName")
    const appLogo = searchParams.get("dappLogo")
    const mode = searchParams.get("darkMode")
    const fromClientId = searchParams.get("clientId")
    console.log("fromClientId...", fromClientId)
    if (fromClientId !== null) {
      localStorage.setItem("clientId", fromClientId)
    }
    const dapp_obj = {
      dappDomain: appName,
      dappLogo: appLogo,
      dappName: appName,
    }
    setDappLogo(appLogo)
    setDappName(appName)
    setFromClientId(fromClientId)
    console.log("name and logo logged ----->", appName)
    try {
      localStorage?.setItem("dapp.details", JSON.stringify(dapp_obj))
    } catch (err) {
      console.log(err)
    }
    if (sUsrAg.indexOf("Firefox") > -1) {
      sBrowser = "Mozilla Firefox"
      console.log("Browser: Firefox ")
      setIsFirefox(true)
    }
    // if(mode === "true"){
    //   document.body.style.backgroundColor = "#101010";
    //   setDarkMode(true)
    //   setIsDarkMode(true)
    // } else {
    //   document.body.style.backgroundColor = "#FFFFFF";
    //   setDarkMode(false)
    //   setIsDarkMode(false)
    // }
  }, [])

  const sendMessageToParent = (data: any = null) => {
    keyringController.postMessage(
      {
        type: eventTypes.passMessage,
        message: {
          type: "otpLogin",
        },
      },
      true
    )
  }

  document.body.style.backgroundColor = "#101010"

  return (
    <>
      <div
        style={{ transform: "scale(0.975)" }}
        className='fixed lg:right-[40%] shadow-lg text-white lg:top-[15%] -right-1 bottom-0'
      >
        {socialLogins?.map((social, index) => {
          return (
            <div onClick={() => socialLoginClicked(index)}>
              <div
                className={`self-stretch cursor-pointer mb-2 rounded-2xl hover:bg-opacity-80  ${
                  social.bgname && `bg-${social.bgname}`
                } flex-col justify-center w-[376px] items-center gap-2 flex`}
              >
                <div className='self-stretch px-2 py-3  bg-opacity-60 rounded-2xl justify-center items-center gap-2 inline-flex'>
                  {social?.iconUrl}
                  <div className='grow shrink basis-0 h-10 px-2 py-3 rounded-[20px] justify-start items-center flex'>
                    <div className='justify-start items-center flex'>
                      <div className="text-center text-white text-base font-medium font-['Montserrat'] leading-tight">
                        {social?.name}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <div
          className='w-[376px] h-16 px-2 py-3 bg-neutral-900 hover:bg-opacity-20 hover:transition duration-200 rounded-2xl justify-center items-center gap-2 inline-flex cursor-pointer'
          onClick={() => sendMessageToParent()}
        >
          <div className='pl-[7px] pr-[5px] pt-[3px] pb-2.5 rounded-[28.33px] justify-center items-center flex'>
            <div className='w-7 h-[27px] relative'>
              <div className='w-[15px] h-[15px] left-[13px] top-0 absolute justify-center items-center inline-flex'>
                <div className='w-[15px] h-[15px] relative'>{callIcon()}</div>
              </div>
              <div className='w-[40px] h-[40px] left-0 top-[5px] absolute justify-center items-center inline-flex'>
                <div className='w-[40px] h-[40px] '>{smsIcon()}</div>
              </div>
            </div>
          </div>
          <div className='grow shrink basis-0 h-10 px-2 py-3 rounded-[20px] justify-start items-center flex'>
            <div className='justify-start items-center flex'>
              <div className="text-center text-white text-base font-medium font-['Montserrat'] leading-tight">
                Continue with Email/ Phone
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialConnectorIframe
