import { KeyringController } from "@tria-sdk/web";
import { keyring_env, env } from "./constants";
import io from "socket.io-client";
const baseUrl = process.env.REACT_APP_BASE_URL || ""

const walletType = {
    embedded: true,
};

const keyringController = new KeyringController({
    baseUrl,
    walletType,
    sentryDns: 'https://f7200916c247762ad52bc252be9aa628@o4505837187366912.ingest.sentry.io/4506503590772736',
    environment: keyring_env as typeof env
});
export const socket = io(process.env.REACT_APP_SOCKET_URL || "");

export default keyringController
