import React from 'react'

export default function Footer() {
  return (
    <div className='' style={{ transform: "scale(0.7)" }} >
      <div className="w-full -mb-16 -mt-2 py-3 rounded-[44px] justify-start items-start inline-flex">
        <div className="grow shrink basis-0 justify-center items-center gap-2 flex">
          <div className="w-[19px] h-[19px] relative rounded-[5px]" />
          <img src="/icons/logo.svg" alt="logo" />
          <div className="text-center text-zinc-500 text-opacity-40 text-sm font-semibold font-['Montserrat'] leading-[16.80px]">Powered by Tria</div>
        </div>
      </div>
    </div>
  )
}
