import { Link } from "react-router-dom"
import {useDisconnect} from "@tria-sdk/connect";

const SessionLogout = ({setSessionEnd}) => {
    const { disconnect } = useDisconnect(); 
    
    return (
        <div style={{ zIndex: 9999 , transform: 'translate(-50%, -50%)'}} className="w-[80%]  absolute  top-[50%] left-[50%] shadow-xl  ring-2 ring-gray-300  h-[150px] px-3 pt-3 pb-4 bg-white dark:bg-fontLightColor rounded-xl flex-col align-center justify-center items-center gap-5 inline-flex">
          <div className="self-stretch h-[20px] flex-col justify-center items-end gap-3 flex">
                <div className="w-[18px] h-[18px] relative">
                    <div onClick={()=>setSessionEnd(false)} className="w-[18px] h-[18px] left-[0.01px] top-0 absolute cursor-pointer">
                        <img src="/icons/close-circle.svg" alt="close" />
                    </div>
                </div>
            </div>
            <div className="h-[84px] flex-col justify-center items-center gap-3 flex">
                <div className="text-center text-stone-950 text-opacity-80  font-semibold font-['Montserrat'] leading-normal dark:text-text">Session Expired</div>
            </div>
            <div className="self-stretch h-[50px] px-2 flex-col justify-center items-center gap-1 flex cursor-pointer" onClick={()=>disconnect()}>
                    <div className=" h-10 px-5 py-3 bg-gray-400  dark:bg-gray-800   rounded-[20px] justify-center items-center inline-flex">
                        <div className="justify-center items-center flex">
                            <div className="text-center text-stone-950 font-semibold font-['Montserrat'] leading-tight dark:text-text">Logout</div>
                        </div>
                    </div>
            </div>
        </div>
            )
}   

export default SessionLogout;