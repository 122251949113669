import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../Components/Navbar";
import { useLocation } from "react-router-dom";
import {
  FeeController,
  WalletController,
  ContractDetails,
  CheckTokenAllowanceResponse,
} from "@tria-sdk/web";
import { useParams, useNavigate } from "react-router-dom";
import NavContext from "../../NavContext";
import { useTriaUser } from "../../contexts/tria-user-provider";
// import Loader from "../../Components/Loader";
import Loader from "../../Components/Send/Loader";
import Loader1 from "../../Components/Loader";
import { AssetDetails ,approveParam,fee,AvatarItem, postMessageResponse} from "../../types";
import {walletType,baseUrl,walletUrl} from "../../utils/constants";
import { shortenWalletAddress, getDataFromLocalStorage } from "../../utils";
import DappDetails from "../../Components/DappDetails";
import {HideBalance,SingleLine,SingleLineLeft,NftAsset} from '../../Components/Loaders';
import NetworkFee from "../../Components/NetworkFee";
import FooterButtons from "../../Components/FooterButtons";
import ErrorMessage from "../../Components/Popup/ErrorMessage";
import { HideTransferBalance } from "../../Components/Loaders/HideTransferBalance";


const initialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)"
}



export default function Approve(props: any) {
  const walletType = { embedded: true };
  const baseUrl = process.env.REACT_APP_BASE_URL || "";
  const fee = new FeeController({
    baseUrl,
    walletType,
  });

  const encodedParams = btoa(
    JSON.stringify({
      chainName: "POLYGON",
      payload: {
        tokenAddress: "0xCf85FF1c37c594a10195F7A9Ab85CBb0a03f69dE",
        amount: 1,
        spender: "0x5927Aa58fb36691A4be262c63955b47b67c6e641",
      },
    })
  );

  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState<approveParam>();
  const [tokenDetails, setTokenDetails] = useState<AssetDetails>();
  const [gasFees, setGasFees] = useState<fee>();
  const [recieverAddress, setRecieverAddress] = useState<string>();
  const [amountInUSD, setAmountInUSD] = useState<number>();
  const [totalAmountInUSD, setTotalAmountInUSD] = useState<number>();
  const [totalAmountIncrypto, setTotalAmountIncrypto] = useState<number>();
  const [feeLoading, setFeeLoading] = useState<boolean>(true);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [senderAvatar, setSenderAvatar] = useState<AvatarItem>(initialAvatar);
  const [nativeSymbol, setNativeSymbol] = useState<string>("");
  const { getAssetDetails, getUserByAddress, getAvatarsByTriaNames } = useTriaUser();
  const navigate = useNavigate();
  const [approveButtonDisable, setApproveButtonDisable] = useState<boolean>(true);
  const [transactionSuccessful, setTransactionSuccessful] = useState<boolean>(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [counter, setCounter] = useState(30);
  const [triaName, setTriaName] = useState<string>("");
  const param = useParams();
  const sendMessageToParent = (data: postMessageResponse) => {
    console.log("event post message",data);
    // Post a message to the parent window
    window.parent.postMessage(
      { type: "closeIframe", callFrom: "mint", data: data },
      "*"
    );
  };

  const approve = async () => {
    try {
      setApproveLoading(true);
      console.log("approving..!!");
      const chain = params?.chainName;
      const wallet = new WalletController({
        baseUrl,
        walletType,
        selectedChainName: chain,
      });
      if (params) {
        await wallet.init();
        if (params.payload && params?.chainName) {
          const res = await wallet.approve(params?.payload);
        await res?.data?.wait();
        const { wait, ...txnDataWithoutWait } = res.data || {};
        const modifiedTxnResponse = {
          ...res,
          data: txnDataWithoutWait,
      };
        if(modifiedTxnResponse?.success){
        setTransactionSuccessful(true);
        console.log("call contract res---------------->", res);
        setTimeout(() => {
        navigate(-1);
        sendMessageToParent(modifiedTxnResponse);
      }, 2000);
          }
          else {
            setError(modifiedTxnResponse?.message || "");
            setApproveLoading(false);
          }
        }
      }
    }
    catch (error: any) {
      setError(error?.message || "");
      setApproveLoading(false);
      console.log("error", error);
    }
 
  };

  // const getTriaName = async (address: any, chainName: any) => {
  //   const triaName = await getUserByAddress(address, chainName);
  //   setRecieverTriaName(triaName);
  //   console.log("triaName", triaName);
  // };

  const getApproveFee = async () => {
    try {
      setApproveButtonDisable(true);
      setError("");
      if (!params?.payload) return;
      const contractFee = await fee.getApproveFee(
        triaName,
        params?.chainName || "",
        params?.payload
      );

       console.log("contractFee--------------------->", contractFee);
      // console.log("chain------------------>", params);
      setCounter(30);
      if (contractFee.success === true) {
        setApproveButtonDisable(false);
        setGasFees(contractFee.fee);
        setTotalAmountInUSD(
          parseFloat(contractFee.fee?.usd || "0") + (amountInUSD || 0)
        );
        // setTotalAmountIncrypto(
        //   parseFloat(contractFee?.fee?.eth || "0") +
        //     (params?.payload?.amount || 0)
        // );
        setFeeLoading(false);
      } else {
        setError(contractFee?.message || "");
        setFeeLoading(false);
      }
      console.log({ contractFee });
    } catch (error: any) {
      setError(error?.message || "");
      console.error(error);
    }
  };

  const setStateParams = async () => {
    try {
      // const encodedParams = searchParams.get('params');
      //@ts-ignore
      const encodedParams = param?.param;
      console.log("en", encodedParams);
      if (encodedParams) {
        // Decode the string
        const jsonString = atob(encodedParams);
        console.log("jsonString", jsonString);
        // Parse the JSON
        const jsonData = JSON.parse(jsonString);
        const triaName = JSON.parse(localStorage.getItem("tria.wallet.store") || "{}")?.triaName;
        setTriaName(triaName);
        getAsset(jsonData, triaName);
        if (triaName) {
          const resp = await getAvatarsByTriaNames([triaName]);
          if(Object.keys(resp).length>0){
          const avatar_details = resp[triaName]?.[0];
          setSenderAvatar({ avatar: avatar_details?.avatar, background: avatar_details?.background });
          }
        }


        setParams(jsonData);
        const recieverAddressShort =
          shortenWalletAddress(jsonData?.payload?.spender) || "";
        setRecieverAddress(recieverAddressShort);
      }
    }
    catch (err) {
      console.log(err);
    }
  };

  const getAsset = async (paramData: any, triaName: string) => {
    try {
      console.log("start------------------------>");
      const [response, fetchNativeSymbol] = await Promise.all([
        getAssetDetails(paramData?.chainName, paramData?.payload?.tokenAddress, triaName),
        getAssetDetails(paramData?.chainName, "", triaName),
      ]);

      setNativeSymbol(fetchNativeSymbol?.symbol)
      setLoading(false);
      setTokenDetails(response);
      if (paramData?.payload?.amount) {
        const total = paramData?.payload?.amount * response?.quoteRate || 0;
        // console.log("total--------------->", total);
        setAmountInUSD(total);
      }

      // console.log("asset----------------------->", response);
    }
    catch (err) {
      console.log(err);
    }
  };

  const fetchApproveFee = async () => {
    try {
      await getApproveFee();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (params) {
      setFeeLoading(true);
      fetchApproveFee();
    }
  }, [params]);

  useEffect(() => {
    if (counter === 0 && !feeLoading) {
      setFeeLoading(true);
      fetchApproveFee();
    }
  }, [counter])

  useEffect(() => {
    // console.log("pppp",params && !timerStarted && !!gasFees?.eth);
    if (params && !!gasFees?.eth && !approveLoading) {


      const intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter > 0) {
            return prevCounter - 1;
          } else {
            return 0;
          }
        });

      }, 1000);
      setTimerStarted(true);
      return () => clearInterval(intervalId);
    }
  }, [counter, params, gasFees]);

  useEffect(() => {
    console.log("eecc", encodedParams);
    setStateParams();
  }, []);

  return (
    <div className="w-full h-screen p-3 flex-col bg-white dark:bg-fontLightColor rounded-2xl justify-between items-center inline-flex">
      {/* {loading ? <div className="w-[448px] h-[840px] p-4 flex-col justify-center items-center inline-flex"><Loader1 /></div> : <> */}
      {approveLoading ? (
        <div>
          <div>
            <Loader
              heading={"Approving..."}
              dappLogo={params?.dappLogo}
              transactionSuccessful={transactionSuccessful}
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex-col justify-center items-center flex">
          <div className="absolute top-0 left-0 ">
            {" "}
            <img className="w-3/4" src="/icons/back.svg" />
          </div>
          <div className="w-full justify-end items-start inline-flex">
            <div className="p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-end gap-2 inline-flex" />
            {/* <img
              className="dark:invisible visible dark:w-0"
              src="/icons/Shape.svg"
            ></img>
            <img className="dark:visible invisible W-[0] dark:W-18px " src="/icons/ShapeW.svg"></img>{" "} */}
          </div>
          <div className="self-stretch flex-col justify-center items-center gap-2 flex -mt-4 md:mt-0">
            <div className="self-stretch justify-center items-center gap-0 inline-flex">
              <div>
                <Navbar
                  loading={loading}
                  senderAvatar={senderAvatar}
                  chainName={params?.chainName || ""}
                  tokenDetails={tokenDetails}
                  triaName={triaName}
                />
              </div>{" "}
            </div>
            <DappDetails params={params} />
            <div className="self-stretch justify-center  items-center gap-2 inline-flex">
              <div className="text-center mb-0 text-stone-950 text-opacity-90 text-sm md:text-xl font-semibold font-montserrat leading-normal dark:text-text">
                Approve
              </div>
            </div>
          </div>
          <div className="self-stretch mt-3 flex-col justify-center items-center gap-2 flex">
            <div className="self-stretch py-4 rounded-2xl border border-violet-400 border-opacity-30 flex-col justify-center items-center flex">
              {loading ? (
                <HideTransferBalance />
              ) : (
                <div className="h-20 py-3 flex-col justify-center items-start gap-2 flex">
                  <div className="self-stretch justify-center items-center gap-2 inline-flex">
                    <div className="text-center text-stone-950 text-opacity-90 text-2xl font-semibold font-montserrat leading-[28.80px] dark:text-text">
                      ${amountInUSD?.toFixed(6) || 0}
                    </div>
                  </div>
                  <div className="self-stretch justify-center items-center gap-1 inline-flex">
                    <div className="text-center text-stone-950 text-opacity-60 text-base font-medium font-montserrat leading-tight dark:text-text">
                      {params?.payload?.amount || 0} {tokenDetails?.symbol}
                    </div>
                  </div>
                </div>
              )}
              <div className="w-full justify-center items-start gap-4 inline-flex">
                <div className="grow shrink basis-0 py-3 flex-col justify-center items-center gap-3 inline-flex">
                  <div
                    className="h-[35px] w-[35px] rounded-[50%] flex justify-center items-center"
                    style={{ backgroundImage: senderAvatar?.background }}
                  >
                  {senderAvatar?.avatar &&  <img
                      className="w-[35px] h-[35px] rounded-[50px]"
                      src={senderAvatar?.avatar}
                    />}
                  </div>
                  <div className="px-2 justify-start items-center inline-flex">
                    <div className="text-center text-zinc-500 text-[10px] md:text-base font-semibold font-montserrat leading-[16.80px] ">
                      You
                    </div>
                  </div>
                </div>
                <div className="py-6 flex-col justify-center items-center gap-3 inline-flex">
                  <div className="w-6 h-6 relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M14.4297 6.26318L20.4997 12.3332L14.4297 18.4032"
                        stroke="#808080"
                        stroke-opacity="0.8"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.5 12.3333H20.33"
                        stroke="#808080"
                        stroke-opacity="0.8"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="w-6 h-6 left-0 top-0 absolute"></div>
                  </div>
                </div>
                <div className="grow shrink basis-0 py-3 flex-col justify-center items-center gap-3 inline-flex">
                  <div
                    className="h-[35px] w-[35px] rounded-[50%] flex justify-center items-center bg-gray-700"
                    // style={{ backgroundImage: senderAvatar?.background }}
                  >
                    <div className=" font-bold font-montserrat text-sm md:text-3xl absolute">
                      c
                    </div>
                  </div>
                  <div className="px-2 justify-start items-center inline-flex">
                    <div className="text-center text-zinc-500 text-[10px] md:text-base font-semibold font-montserrat leading-[16.80px]">
                      {recieverAddress}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NetworkFee
              counter={counter}
              feeLoading={feeLoading}
              gasFees={gasFees}
              tokenDetails={tokenDetails}
              totalAmountInUSD={totalAmountInUSD}
              totalAmountIncrypto={totalAmountIncrypto}
              chainName={params?.chainName || ""}
            />
          </div>
          <div className="-mt-10">
               {error && <ErrorMessage error={error}/>}
            </div>
          <FooterButtons
            handleReject={sendMessageToParent}
            approveButtonDisable={approveButtonDisable}
            callFunction={approve}
          />
        </div>
      )}
      {/* </>} */}
    </div>
  );
}