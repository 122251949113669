import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import io from "socket.io-client"
import { socket } from "../../utils/init"

export default function SocketListener() {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const socketId = searchParams.get("socketId")
  const [status, setStatus] = useState<string>("Connecting...")
  useEffect(() => {
    if (!socketId) {
      console.error("No socketId provided")
      return
    }

    socket.emit("loginV2", { userId: socketId }, () => {
      setStatus("Connected, waiting for data...")

      socket.on("messageV2", (data: any) => {
        setStatus("Received data, processing...")
        const unitySocketId = localStorage.getItem("unitySocketId")
        try {
          const decodedData = atob(data)
          console.log("decodedData", decodedData)
          localStorage.setItem("tria.wallet.store", decodedData)
          if (unitySocketId) {
            socket.emit("loginV2", {
              userId: unitySocketId,
            })
            setTimeout(() => {
              socket.emit("messageV2", {
                userId: unitySocketId,
                message: JSON.parse(decodedData),
              })
            }, 1000)
            setTimeout(() => {
              const walletData = decodedData
              const deepLink = `com.Empire-of-Sight.EOS://login?username=${
                JSON.parse(walletData).triaName
              }`
              window.location.replace(deepLink)
            }, 2500)
            setTimeout(() => {
              window.close()
            }, 5000)
          } else {
            setTimeout(() => {
              window.close()
            }, 1000)
          }

          // Store data in localStorage
          // Object.entries(decodedData).forEach(([key, value]) => {
          //   localStorage.setItem(key, JSON.stringify(value))
          // })

          setStatus("Data stored in localStorage. Closing window...")

          // Close the window after a short delay
        } catch (error) {
          setStatus(`Error processing received data: ${error}`)
        }
      })
    })

    return () => {
      socket.disconnect()
    }
  }, [socketId])
  useEffect(() => {
    console.log("status", status)
  }, [status])

  return <div>Listening for socket data...</div>
}
