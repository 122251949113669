import React, { useState, useEffect, useContext } from "react";
import Navbar from "../../Components/Navbar";
import { useLocation } from "react-router-dom";
import {
  FeeController,
  WalletController,
  ContractDetails,
} from "@tria-sdk/web";
import { Send, Approve } from "@tria-sdk/web";
import { useParams, useNavigate } from "react-router-dom";
import NavContext from "../../NavContext";
import { useTriaUser } from "../../contexts/tria-user-provider";
import Loader from "../../Components/Send/Loader";
import Loader1 from "../../Components/Loader";
import { getDataFromLocalStorage } from "../../utils";
import {
  AssetDetails,
  mintParam,
  fee,
  AvatarItem,
  postMessageResponse,
} from "../../types";
import {
  walletType,
  baseUrl,
  walletUrl,
  socketUrl,
} from "../../utils/constants";
import { shortenWalletAddress } from "../../utils";
import DappDetails from "../../Components/DappDetails";
import { socket } from "../../../src/utils/init";
import { v4 as uuidv4 } from "uuid";
import NetworkFee from "../../Components/NetworkFee";
import FooterButtons from "../../Components/FooterButtons";
import ErrorMessage from "../../Components/Popup/ErrorMessage";
import { HideTransferBalance } from "../../Components/Loaders/HideTransferBalance";

const initialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)",
};

export default function Mint(props: any) {
  const { getAssetDetails, getUserByAddress, getAvatarsByTriaNames } =
    useTriaUser();

  const encodedParams = btoa(
    JSON.stringify({
      chainName: "FUSE",
      // payToken: {tokenAddress:"0632cad6-f84e-4dce-aaa4-d23e57715a360", amount:".0001"},
      contractDetails: {
        contractAddress: "0xd1fD14e3Cf4f96E63A1561681dc8765DF8f7Cf91",
        abi: [
          {
            inputs: [
              { internalType: "uint256", name: "_tokenID", type: "uint256" },
              { internalType: "address", name: "_claimer", type: "address" },
            ],
            name: "claimCoupon",
            outputs: [],
            stateMutability: "nonpayable",
            type: "function",
          },
        ],
        functionName: "claimCoupon",
        args: [1, "0x7Ae1bBCe3557D46313a960C0982637967eF5c1f7"],
        // value: 1,
      },
    })
  );

  const [loading, setLoading] = useState(true);
  const [params, setParams] = useState<mintParam>();
  const [tokenDetails, setTokenDetails] = useState<AssetDetails>();
  const [gasFees, setGasFees] = useState<fee>();
  const [recieverAddress, setRecieverAddress] = useState<string>();
  const [amountInUSD, setAmountInUSD] = useState<number>();
  const [totalAmountInUSD, setTotalAmountInUSD] = useState<number>();
  const [totalAmountIncrypto, setTotalAmountIncrypto] = useState<number>();
  const [feeLoading, setFeeLoading] = useState<boolean>(true);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [senderAvatar, setSenderAvatar] = useState<AvatarItem>(initialAvatar);
  const [approveButtonDisable, setApproveButtonDisable] =
    useState<boolean>(true);
  const [triaName, setTriaName] = useState<string>("");
  const [transactionSuccessful, setTransactionSuccessful] =
    useState<boolean>(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [counter, setCounter] = useState(30);
  const navigate = useNavigate();
  const param = useParams();

  const sendMessageToParent = (data: postMessageResponse) => {
    console.log("event post message", data);
    // Post a message to the parent window
    window.parent.postMessage(
      { type: "closeIframe", callFrom: "mint", data: data },
      "*"
    );
  };

  const fee = new FeeController({
    baseUrl,
    walletType,
  });

  useEffect(() => {
    joinRoomAndPostMessage();
  }, []);

  async function joinRoomAndPostMessage() {
    try {
      // Perform socket.emit to join the room
      const userId = await new Promise((resolve, reject) => {
        const id = uuidv4();
        socket.emit("loginV2", { userId: id }, (response) => {
          console.log("loginV2 response", response);
          if (response.success) {
            resolve(response.userId);
          } else {
            reject(new Error("Failed to join room!"));
          }
        });
      });

      // Perform window.postMessage with the obtained roomName
      window.parent.postMessage(
        { type: "sendParams", callFrom: "mint", data: { userId } },
        "*"
      );
    } catch (error) {
      // Handle errors
      console.error("Operation failed:", error);
      throw error; // Re-throw the error if needed
    }
  }

  useEffect(() => {
    const handleSocketMessage = (data) => {
      console.log(
        "socket data ------------------------------------------------------>",
        data
      );
      console.log("setting params");
      setStateParams(data);
      // socket.disconnect();
    };

    socket.on("messageV2", handleSocketMessage);

    return () => {
      socket.off("messageV2", handleSocketMessage);
    };
  }, []);

  const sendToken = async () => {
    try {
      setApproveLoading(true);
      console.log("calling contract....!!");
      const wallet = new WalletController({
        baseUrl,
        walletType,
      });
      if (params && gasFees) {
        await wallet.init();
        if (params.contractDetails && params?.chainName) {
          const mintRes = await wallet.callContract(
            params.contractDetails,
            params?.chainName
          );
          console.log("call contract res-------------->", mintRes);
          const { wait, ...txnDataWithoutWait } = mintRes.data || {};
          const modifiedTxnResponse = {
            ...mintRes,
            data: txnDataWithoutWait,
          };
          if (modifiedTxnResponse?.success) {
            setTransactionSuccessful(true);
            setTimeout(() => {
              sendMessageToParent(modifiedTxnResponse);
            }, 2000);
          } else {
            setError(modifiedTxnResponse?.message || "");
            setApproveLoading(false);
          }
        }
      }
    } catch (error: any) {
      setError(error?.message || "");
      console.log("error", error);
      setApproveLoading(false);
    }
  };

  // const getTriaName = async (address: any, chainName: any) => {
  //   const triaName = await getUserByAddress(address, chainName);
  //   setRecieverTriaName(triaName);
  //   console.log("triaName", triaName);
  // };

  const getSendFee = async (feeCallData: mintParam) => {
    console.log("calling gas fee");
    setApproveButtonDisable(true);
    setError("");
    try {
      const contractFee = await fee.getCallContractFee(
        triaName,
        feeCallData?.chainName,
        feeCallData?.contractDetails
      );

      console.log("contractFee------------------->", contractFee);
      // console.log("chain------------------>", feeCallData);
      setCounter(30);
      if (contractFee.success === true) {
        setApproveButtonDisable(false);
        setGasFees(contractFee.fee);
        setTotalAmountInUSD(
          parseFloat(contractFee.fee?.usd || "0") + (amountInUSD || 0)
        );
        setTotalAmountIncrypto(
          parseFloat(contractFee?.fee?.eth || "0") +
            (feeCallData?.contractDetails?.value || 0)
        );
        setFeeLoading(false);
      } else {
        setError(contractFee?.message || "");
      }
      console.log({ contractFee });
    } catch (error: any) {
      setError(error?.message || "");
      console.error(error);
    }
  };

  const setStateParams = async (callData: string) => {
    try {
      //@ts-ignore
      const encodedParams = callData;
      console.log("en", encodedParams);
      if (encodedParams) {
        // Decode the string
        const jsonString = atob(encodedParams);
        const jsonData: mintParam = JSON.parse(jsonString);
        console.log("mintParam------------------------->", jsonData);
        const triaName = JSON.parse(
          localStorage.getItem("tria.wallet.store") || "{}"
        )?.triaName;
        console.log("jsonData?.payToken?.amount", jsonData?.payToken?.amount);
        if (jsonData?.payToken?.amount > 0) {
          //@ts-ignore
          await checkTokenAllowance(jsonData, triaName);
        }
        setTriaName(triaName);
        getAsset(jsonData, triaName);
        setParams(jsonData);
        if (triaName) {
          const resp = await getAvatarsByTriaNames([triaName]);
          if (Object.keys(resp).length > 0) {
            const avatar_details = resp[triaName]?.[0];
            setSenderAvatar({
              avatar: avatar_details?.avatar,
              background: avatar_details?.background,
            });
          }
        }

        //@ts-ignore

        const recieverAddressShort =
          shortenWalletAddress(jsonData?.contractDetails?.contractAddress) ||
          "";
        setRecieverAddress(recieverAddressShort);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const checkTokenAllowance = async (
    paramData: mintParam,
    triaName: string
  ) => {
    try {
      const payload: Approve = {
        tokenAddress: paramData?.payToken?.tokenAddress,
        amount: paramData?.payToken?.amount || 0,
        spender: paramData?.contractDetails?.contractAddress,
      };
      const res = await fee.checkTokenAllowance(
        triaName,
        paramData?.chainName,
        payload
      );
      console.log("token allowence------------------>", res);
      if (!res?.allowance) {
        const url = btoa(
          JSON.stringify({ chainName: paramData?.chainName, payload })
        );
        navigate(`/approve/${url}`);
      }
      console.log("token allowence----->", res);
    } catch (err) {
      console.log("err", err);
    }
  };

  const getAsset = async (asset: mintParam, triaName: string) => {
    try {
      console.log("start----------------------->");
      const response = await getAssetDetails(
        asset?.chainName,
        asset?.payToken?.tokenAddress,
        triaName
      );
      setLoading(false);
      setTokenDetails(response);
      if (asset?.contractDetails?.value) {
        const total = asset?.contractDetails?.value * response.quoteRate;
        console.log("total-------------->", total);
        setAmountInUSD(total);
      }

      console.log("asset------------------------>", response);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSendFee = async () => {
    try {
      if (params) await getSendFee(params);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (params) {
      setFeeLoading(true);
      fetchSendFee();
    }
  }, [params]);

  useEffect(() => {
    if (counter === 0 && !feeLoading) {
      setFeeLoading(true);
      fetchSendFee();
    }
  }, [counter]);

  useEffect(() => {
    // console.log("pppp",params && !timerStarted && !!gasFees?.eth);
    if (params && !!gasFees?.eth && !approveLoading) {
      const intervalId = setInterval(() => {
        setCounter((prevCounter) => {
          if (prevCounter > 0) {
            return prevCounter - 1;
          } else {
            return 0;
          }
        });
      }, 1000);
      setTimerStarted(true);
      return () => clearInterval(intervalId);
    }
  }, [counter, params, gasFees]);

  // useEffect(()=>{
  //    setStateParams("eyJjaGFpbk5hbWUiOiJGVVNFIiwiY29udHJhY3REZXRhaWxzIjp7ImNvbnRyYWN0QWRkcmVzcyI6IjB4ZDFmRDE0ZTNDZjRmOTZFNjNBMTU2MTY4MWRjODc2NURGOGY3Q2Y5MSIsImFiaSI6W3siaW5wdXRzIjpbeyJpbnRlcm5hbFR5cGUiOiJ1aW50MjU2IiwibmFtZSI6Il90b2tlbklEIiwidHlwZSI6InVpbnQyNTYifSx7ImludGVybmFsVHlwZSI6ImFkZHJlc3MiLCJuYW1lIjoiX2NsYWltZXIiLCJ0eXBlIjoiYWRkcmVzcyJ9XSwibmFtZSI6ImNsYWltQ291cG9uIiwib3V0cHV0cyI6W10sInN0YXRlTXV0YWJpbGl0eSI6Im5vbnBheWFibGUiLCJ0eXBlIjoiZnVuY3Rpb24ifV0sImZ1bmN0aW9uTmFtZSI6ImNsYWltQ291cG9uIiwiYXJncyI6WzEsIjB4N0FlMWJCQ2UzNTU3RDQ2MzEzYTk2MEMwOTgyNjM3OTY3ZUY1YzFmNyJdfX0=")
  // },[]);

  return (
    <div className="w-full h-screen p-3 flex-col bg-white dark:bg-fontLightColor rounded-2xl justify-between items-center inline-flex">
      {/* {loading ? <div className="w-[448px] h-[840px] p-4 flex-col justify-center items-center inline-flex"><Loader1 /></div> : <> */}
      {approveLoading ? (
        <div>
          <div>
            <Loader
              heading={"Sending..."}
              dappLogo={params?.dappLogo}
              transactionSuccessful={transactionSuccessful}
            />
          </div>
        </div>
      ) : (
        <div className="w-full flex-col justify-center items-center flex">
          <div className="absolute top-0 left-0 ">
            {" "}
            <img className="w-3/4" src="/icons/back.svg" />
          </div>

          <div className="self-stretch  flex-col justify-center items-center gap-2 flex">
            <div className="self-stretch border-zinc-500 pt-0 border-opacity-10 justify-center items-center gap-4 inline-flex">
              <div>
                <Navbar
                  loading={loading}
                  senderAvatar={senderAvatar}
                  chainName={params?.chainName || ""}
                  tokenDetails={tokenDetails}
                  triaName={triaName}
                />
              </div>{" "}
            </div>
            <DappDetails params={params} />
            <div className="self-stretch justify-center  items-center gap-2 inline-flex">
              <div className="text-center mb-2 text-stone-950 text-opacity-90 text-sm font-semibold font-montserrat leading-normal dark:text-text">
                {params?.contractDetails?.functionName}
              </div>
            </div>
          </div>
          <div className="self-stretch py-2 mt-0 w-full flex-col justify-center items-center gap-2 -mt-4">
            <div className="self-stretch px-0 py-0 rounded-2xl border border-violet-400 border-opacity-30 flex-col justify-center items-center flex mb-2">
              {loading ? (
                <HideTransferBalance />
              ) : (
                <div className="h-20 py-3 flex-col justify-center items-start gap-2 flex">
                  <div className="self-stretch justify-center items-center gap-2 inline-flex">
                    <div className="text-center text-stone-950 text-opacity-90 text-sm font-semibold font-montserrat leading-[28.80px] dark:text-text">
                      ${amountInUSD?.toFixed(6) || 0}
                    </div>
                  </div>
                  <div className="self-stretch justify-center items-center gap-1 inline-flex">
                    <div className="text-center text-stone-950 text-opacity-60 text-xs font-medium font-montserrat leading-tight dark:text-text">
                      {params?.contractDetails?.value || 0}{" "}
                      {tokenDetails?.symbol}
                    </div>
                  </div>
                </div>
              )}

              <div className="w-full justify-center items-start gap-4 inline-flex">
                <div className="grow shrink basis-0 py-3 flex-col justify-center items-center gap-3 inline-flex">
                  <div
                    className="h-[35px] w-[35px] rounded-[50%] flex justify-center items-center"
                    style={{ backgroundImage: senderAvatar?.background }}
                  >
                    {senderAvatar?.avatar && (
                      <img
                        className="w-[35px] h-[35px] rounded-[50px]"
                        src={senderAvatar?.avatar}
                      />
                    )}
                  </div>
                  <div className="px-2 justify-start items-center inline-flex">
                    <div className="text-center text-zinc-500 text-[10px] font-semibold font-['Montserrat'] leading-[16.80px] ">
                      {/* {dappDetails?.triaName} */}
                      You
                    </div>
                  </div>
                </div>
                <div className="py-6 flex-col justify-center items-center gap-3 inline-flex">
                  <div className="w-6 h-6 relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="25"
                      viewBox="0 0 24 25"
                      fill="none"
                    >
                      <path
                        d="M14.4297 6.26318L20.4997 12.3332L14.4297 18.4032"
                        stroke="#808080"
                        stroke-opacity="0.8"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M3.5 12.3333H20.33"
                        stroke="#808080"
                        stroke-opacity="0.8"
                        stroke-width="1.5"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div className="w-6 h-6 left-0 top-0 absolute"></div>
                  </div>
                </div>
                <div className="grow shrink basis-0 py-3 flex-col justify-center items-center gap-3 inline-flex">
                  <div
                    className="h-[35px] w-[35px] rounded-[50%] flex justify-center items-center bg-gray-700"
                    // style={{ backgroundImage: senderAvatar?.background }}
                  >
                    <div className=" font-bold font-montserrat text-4xl absolute">
                      {recieverAddress?.charAt(recieverAddress?.length - 1)}
                    </div>
                  </div>
                  <div className="px-2 justify-start items-center inline-flex">
                    <div className="text-center text-zinc-500 text-[10px] font-['Montserrat'] leading-[16.80px]">
                      {recieverAddress}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NetworkFee
              counter={counter}
              feeLoading={feeLoading}
              gasFees={gasFees}
              tokenDetails={tokenDetails}
              totalAmountInUSD={totalAmountInUSD}
              totalAmountIncrypto={totalAmountIncrypto}
              chainName={params?.chainName || ""}
            />
          </div>
          <div className="-mt-10 md:mt-0">
            {error && <ErrorMessage error={error} />}
          </div>
          <FooterButtons
            handleReject={sendMessageToParent}
            approveButtonDisable={approveButtonDisable}
            callFunction={sendToken}
          />
        </div>
      )}
      {/* </>} */}
    </div>
  );
}
