import React, { useState, useEffect, useContext } from 'react'
import Connect from '../../Components/SignMessage/Connect';
import Sign from "../../Components/SignMessage/Sign";
import { WalletController } from '@tria-sdk/web';
import { useParams } from 'react-router-dom'
import { useTriaUser } from "../../contexts/tria-user-provider";
import { getDataFromLocalStorage } from "../../utils";
import {walletType,baseUrl} from "../../utils/constants";
import {AssetDetails,AvatarItem,postMessageResponse,signMessageParams} from "../../types";
import {useAccount} from "@tria-sdk/connect";


const initialParams: signMessageParams = {
  chainName: '',
  message: '',
  tokenAddress: '',
  dappDomain:'',
  dappLogo:''
}


const initialAvatar: AvatarItem = {
  avatar: "",
  background: "linear-gradient(153.43deg, #DAD9DA 0%, #ECDBEC 83.33%)"
}




function SignMessage() {
  const [loading, setLoading] = useState(true);
  const [connect, setConnect] = useState(false);
  const [params, setParams] = useState<signMessageParams>(initialParams);
  const { getAssetDetails, getAvatarsByTriaNames } = useTriaUser();
  const [tokenDetails, setTokenDetails] = useState<AssetDetails>();
  const param = useParams();
  const [senderAvatar, setSenderAvatar] = useState<AvatarItem>(initialAvatar);
  const [triaName,setTriaName]=useState<string>("");
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  
  const sendMessageToParent = (data:postMessageResponse) => {
    // Post a message to the parent window
    console.log("event emitted");
    window.parent.postMessage({ type: 'closeIframe', callFrom: 'sign', data: data }, '*');
  };




  console.log(btoa(JSON.stringify({
    chainName: "POLYGON",
    message: "Hello, this is a dummy message!",
    tokenAddress: ""
  })));

  const signMessage = async () => {
    try{

    console.log('Signing message...!!');
    const wallet = new WalletController({
      baseUrl,
      walletType,
    });

    if(!params?.message)
    return;

    await wallet.init();
    const res = await wallet.signMessage(params?.message, params?.chainName);
      setApproveLoading(true);
      setTimeout(() => {
        sendMessageToParent(res);
      }, 2000);
    }
  catch(err){
    console.log(err);
    setError("Error in signing message")
  }
  };

  const setStateParams = async () => {
    try {
      //@ts-ignore
      if (param?.param) {
        //@ts-ignore
        const encodedParams = param.param;
        console.log("encodedParams", typeof (encodedParams));
        // Decode the string
        const jsonString = atob(encodedParams);
        console.log("jsonString", jsonString);
        // Parse the JSON
        const jsonData = JSON.parse(jsonString);
        const triaName = JSON.parse(localStorage.getItem("tria.wallet.store") || "{}")?.triaName;
        setTriaName(triaName);
        if (triaName) {
        const resp = await getAvatarsByTriaNames([triaName]);
        if(Object.keys(resp).length>0){
        const avatar_details = resp[triaName]?.[0];
        setSenderAvatar({ avatar: avatar_details?.avatar, background: avatar_details?.background });
        }
        }
        setParams(jsonData);
        getAsset(jsonData, triaName);
      }
    }
    catch (err) {
      console.log(err);
    } 

  };

  const getAsset = async (asset: any, triaName:string) => {
    try {
      const response = await getAssetDetails(
        asset?.chainName, asset?.tokenAddress, triaName
      );
      setLoading(false);
      setTokenDetails(response);
    }
    catch (err:any) {
      console.log(err);
      setError(err || err?.message); 
    }
  }


  useEffect(() => {
    setStateParams();
  }, []);



  return (
    <div>
      {connect ? <Connect setConnect={setConnect} /> :
        <Sign error={error} approveLoading={approveLoading} senderAvatar={senderAvatar} triaName={triaName} params={params} signMessage={signMessage} tokenDetails={tokenDetails} sendMessageToParent={sendMessageToParent} loading={loading}/>
      }
    </div>

  )
}

export default SignMessage