import React from "react";
import Footer from "../Footer";
import TransactionSuccess from "../UI/TransactionSuccess";

export default function Loader() {
  return (
    <div className="w-full h-screen p-3 flex-col justify-between items-center inline-flex">
      <div className="flex-col justify-start items-center gap-2 flex">
        <div className="w-full justify-between items-start inline-flex">
          <div className="p-2 mix-blend-difference rounded-[39px] flex-col justify-center items-center gap-2 inline-flex">
            <div className="w-6 h-6 relative" />
          </div>
          <div className="p-3 mix-blend-difference rounded-[39px] flex-col justify-center items-center gap-2 inline-flex" />
        </div>
      </div>
      <div className="self-stretch  flex-col justify-center items-center gap-2 flex">
        <div className="w-[100px] md:w-[124px] h-[123px] relative ">
          {<TransactionSuccess />}
        </div>
        <div className="self-stretch  py-0 flex-col justify-center items-start gap-0 flex">
          <div className="flex-col self-stretch justify-center items-center gap-0 inline-flex">
            <div className="text-center text-stone-950 dark:text-text md:text-2xl text-lg font-normal font-['Montserrat'] leading-tight ">Success</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}