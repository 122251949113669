const ToC = () => {
    return (
        <div className="grow shrink basis-0 text-zinc-500 text-[8px] md:text-base font-medium font-['Montserrat']">By checking this, you agree to our
            <a target="_blank" href="https://www.notion.so/triahq/Tria-Beta-Terms-of-Use-1d7dfaf5a58f4038beecd1a67f963425?pvs=4"> Terms of Service </a>
            and
            <a target="_blank" href="https://www.notion.so/triahq/Tria-Beta-Privacy-Policy-359373dd601045b888c207f255634371?pvs=4"> Privacy Policy.</a>
        </div>
    )
}

export default ToC